export default {
  history: '用户反馈历史记录',
  theImageCannotExceed: '图片不能超过 {val1}MB 请重新上传!',
  thereAreTypesOfImagesAvailable: '图片类型有误,请上传正确格式的图片!',
  richTextPureText: '富文本/纯文本切换开关',
  richTextContent: '富文本内容（加粗格式、图片等）',
  discard: '丢弃',
  cutFromRichText: '由富文本切换至纯文本将',
  beCareful: '注意：',
  numberOfRichTexts: '对富文本数据结构进行解析。',
  forTheNumberOfUses:
    '对于使用数据接口拉取 faq 数据的可使用纯文本编辑器，否则需要自行',
  switchToPureText: '切换成纯文本模式保存后将丢弃富文本信息（图片等）',
  reply: '回复：',
  articleNbsp: '条 ：',
  feedback: '反馈',
  replyDetails: '回复详情（',
  nbspAnti: ' 反馈',
  batchReplyAs: '批量回复成功，共回复 ',
  batchSending: '批量发送(',
  failInSend: '发送失败',
  replyPlan: '回复方案：',
  open: '展开',
  batchSelection: '批量选择的反馈',
  replyToRecommendedStudents: '回复推荐生成回复方案',
  pleaseInTheLeftColumn: '请在左侧列表中选择反馈和',
  thereAreCurrentlyNoPlansAvailable: '暂无方案数据',
  replyAdded: '已添加回复方案（',
  addToReply: '添加到回复(',
  addedBack: '已添加到回复',
  adopt: '采纳',
  intelligentReplyPush: '智能回复推荐',
  automaticFilteringHasBeenImplemented: '自动过滤已回复',
  batchSelected: '批量已选',
  aiRecommendationBack: 'AI推荐回复',
  onlySelectable: '仅可选择已有权限的项目，若无项目信息请联系管理员添加权限',
  pleaseSelectProject: '请选择项目',
  changeOfProjectOwnership:
    '项目归属变更后，反馈信息中的智能分类、用户反馈、反馈来源信息将被置空。',
  batchChangeOffice: '批量变更所属应用',
  networkAbnormalityPlease: '网络异常, 请稍后再试',
  operationSuccessful: '操作成功',
  pleaseSelectProject2: '请选择项目!',
  theImageCannotExceed2: '图片不能超过 {val1}MB 请重新上传!',
  pleaseSelectTheOriginalFirst: '请先选择原反馈来源',
  firstLevelClassification: '一级分类',
  pleaseChooseTheOriginalAndReverse: '请选择原反馈来源',
  originalFeedbackSource: '原反馈来源',
  supportFromExistingSources:
    '支持从现有的反馈来源中选择一级分类,\n                  将复制选择的一级分类下面的所有数据到目标来源。',
  pleaseSelectThePurpose: '请选择目的反馈来源',
  objectiveFeedback: '目的反馈来源',
  processingInProgress: '处理中',
  supportFromExistingSources2:
    '支持从现有的反馈来源中选择一级分类, 将复制选择的一级分类下面的所有数据到目标来源',
  successCopy: '拷贝成功',
  sourceFeedbackSource: '源反馈来源和目的反馈来源不能相同',
  pleaseSelectTheSource: '请选择来源id',
  enterMultipleValues: '输入多个值使用逗号隔开',
  pleaseChooseCustom: '请选择自定义字段',
  editorAndProcessor: '编辑处理人',
  pleaseEnterTi: '请输入 {val1}!',
  more: '更多',
  pleaseEnterSearch: '请输入搜索内容(按enter以搜索)',
  uploadingInProgress: '上传中...',
  theImageCannotExceed3: '图片不能超过 {val1}MB 请重新上传!',
  returnToOtherQuestions: '返回其他问题',
  sendOut: '发送',
  pleaseEnterATitle: '请输入标题搜索',
  addTheFaq: '把FAQ的内容回复给用户',
  setAnalysisDimension: '设置分析维度成功',
  setAnalysisDimension2: '设置分析维度',
  stopGenerating: '停止生成',
  noHelp: '无帮助',
  toBeHelpfulTo: '有帮助',
  evaluatedHelpless: '已评价 无帮助',
  reviewedAsHelpful: '已评价 有帮助',
  copy: '复制',
  regenerate: '重新生成',
  terminationSuccessful: '终止成功',
  requestError: '请求错误:',
  helpingMeWrite: '正在帮我写...',
  aiGenerated: 'AI生成的内容仅供参考，您可以根据实际需要进行修正',
  abandoned: '弃用',
  quote: '引用',
  successfullyGenerated: '生成成功',
  connectionInterruption: '连接中断',
  chooseTheRequiredSystem: '选择需要系统为您生成的分析：',
  currentlyNotGenerated: '暂无已生成日志分析',
  stopAnalyzing: '停止分析',
  outlierExtraction: '异常点提炼',
  summarizeTheTimeline: '总结时序',
  logAnalysisConclusion: '日志分析结果',
  analyzeProgress: '分析进度',
  pleaseEnterAsk: "输入你的问题并回车触发提问",
  logChatAskTips: "您可以基于日志文件向模型提问任何问题, 后台会自动传递[反馈内容], [反馈时间]以及过滤后的[日志内容]\n 例如: 请根据反馈内容, 输出跟其相关的日志",
  logFile: '日志文件',
  analyzingLogFile: '解析日志文件中, 请耐心等待...',
  pleaseInputKeyWords: '请输入关键词进行搜索',
  pleaseSelectFirst: '非全局搜索, 需要先选择一个文件',
  confirm: '确认',
  time: '时间',
  loadingInProgress: '加载中',
  logAnalysisCompleted: '----  日志分析完成',
  logAnalysisAccuracy: '----  日志分析准备中, 请等待',
  logAnalyzeTabForAnalysis: '分析',
  logAnalyzeTabForAsk: '提问',
  problemAnalysisSmall: '分析小结',
  aggus: '{val1}人',
  aggco: '{val1}条',
  value: '{val1} 反馈人数占比: {val2} ',
  value2: '{val1} 反馈人数: {val2} ',
  value3: '{val1} 反馈量占比: {val2} ',
  feedbackQuantityAg: '反馈量: {val1} ',
  unknownState: '未知状态',
  waitingForReply: '待回复',
  setUpAListExhibition: '设置列表展示字段与排序',
  nothing: '无',
  sourceLink: '来源链接:',
  feedbackClassificationN: '反馈分类: ',
  feedbackUserLetter: '反馈用户信息',
  state: '状态',
  totalTota: '共 {val1} 条',
  creationTime: '创建时间',
  oncal: 'Oncall单',
  transferred: '已转',
  transferredToOnc: '已转Oncall单',
  transferredToTap: '已转TAPD',
  stay: '待',
  already: '已',
  unrecognized: '未识别',
  toBeFollowedUpOn: '待跟进',
  unknown: '未知',
  viewAll: '查看全部',
  recentlyUsersHaveBeenAgainst: '最近用户反馈(',
  pleaseEnterFloatingPoint: '请输入浮点型数字',
  pleaseEnterPlasticSurgery: '请输入整形数字',
  theNumberOfWordsInTheRemarksIsNot: '备注字数不得超过_400_个字符',
  changeFailed: '更改失败',
  changeSuccessful: '更改成功',
  processingPersonnel: '处理人',
  errorInType: '类型错误',
  jumpLink: '跳转链接',
  pleaseEnterTheField: '请输入字段名称搜索',
  addFilter: '添加筛选',
  exportOption: '导出选项',
  pleaseChooseWhetherOrNot: '请选择是否为空',
  pleaseEnter: '请输入',
  forEmpty: '为空',
  notEmpty: '不为空',
  contain: '包含',
  selectedFieldsTo: '已选字段以及排序 （',
  noFieldsAvailableAtTheMoment: '暂无字段',
  searchFields: '搜索字段',
  exportFieldSelection: '导出字段选择 (最多可导出 10万 条)',
  advancedQuery: '高级查询',
  exportSuccessful: '导出成功!',
  exportFailedPlease: '导出失败,请稍后再试',
  weAreGuidingYou: '正在为您导出,请耐心等待...',
  feedbackDetails: '反馈详情',
  imageContent: '图片内容',
  picturesLinking: '图片链接',
  pleaseChoose: '请选择',
  setListDisplay: '设置列表显示字段',
  aField: '个字段',
  nbHasBeenSelected: '已选择:  ',
  usedForAnalyzingACertain:
    '用于分析某类参数(含自定义字段)的数值分布或环比变化，生效后可在【反馈参数统计】中查看',
  userAccount: '用户账号',
  noRelevantIntelligenceAvailableAtTheMoment: '暂无相关智能分类!',
  searchIntelligenceScore: '搜索智能分类',
  selectedCategoryT: '已选分类: {val1}',
  feedbackClassification: '反馈分类',
  feedbackInfo: '反馈内容',
  pleaseEnterTheType: '请输入类型名称搜索',
  pleaseChooseIntelligent: '请选择智能分类',
  userSelectedScore: '用户自选分类',
  pleaseChooseACategory: '请选择分类',
  pleaseEnterTheField2: '请输入字段值',
  multipleUsagePoints: '多个使用,分隔',
  pleaseChooseARating: '请选择打分选项',
  pleaseSelectAQuestionnaire: '请选择问卷',
  allCategories: '所有分类',
  versionFeedbackVolume: '版本反馈量',
  value4: '{val1} 人',
  value5: '{val1} 条',
  feedbackTypeN: '反馈类型: {val1}<br/> 反馈量: {val2}<br/> 占比: {val3}',
  feedbackTypeN2: '反馈类型: {val1}<br/> 占比: {val2}%',
  feedbackQuantity: '反馈数量',
  statisticalDimension: '统计维度:',
  monthly: '按月',
  byWeek: '按周',
  onADailyBasis: '按日',
  statisticalMethod: '统计方式:',
  notSelectedAtAll: '全不选',
  selectAll: '全选',
  value6: '{value}人',
  value7: '{value}条',
  fileT: '{val1}趋势统计.xlsx',
  intelligentClassificationTrend: '智能分类趋势.xlsx',
  exportExc: '导出Excel',
  close: '关闭',
  dataView: '数据视图',
  intelligentClassificationTrend2: '智能分类趋势图',
  viewTrends: '查看趋势',
  trend: '趋势',
  numberOfFeedbackUsers: '反馈用户数环比',
  feedbackFromThePreviousCycle: '上周期反馈用户数',
  feedbackOnUserProportion: '反馈用户占比',
  numberOfFeedbackUsers2: '反馈用户数',
  feedbackOnAMonthOnMonthBasis: '反馈数环比',
  feedbackFromThePreviousCycle2: '上周期反馈数',
  feedbackQuantity2: '反馈数',
  thePicturesAndVideosHaveBeen: '图片/视频已损坏',
  addPicturesAndVideos: '图片视频加载中...',
  imagePreview: '图片预览',
  videoPreview: '视频预览',
  nbspIntelligence: '  智能分类趋势图',
  pleaseInputAnImage: '请输入图片链接',
  multilingual: '多语言',
  optionLabelName: '（选项/标签）名称',
  pleaseClickOnMultilingual: '请点击多语言按钮输入名称',
  modifyOptionLabel:
    '修改（选项/标签）会影响所有使用该（选项/标签）的问卷, 请谨慎操作',
  modifiedSuccessfully: '修改成功',
  pleaseEnterAName: '请输入名称或者图片链接',
  typel: '{val1}名称',
  pleaseEnterAName2: '请输入名称',
  serverException: '服务器异常, 请稍后再试',
  title: '{val1} - 多语言',
  spanish: '西班牙语',
  portuguese: '葡萄牙语',
  arabic: '阿拉伯语',
  korean: '韩语',
  japanese: '日语',
  malayLanguage: '马来语',
  hindiLanguage: '印地语',
  indonesia: '印度尼西亚语',
  vietnamese: '越南语',
  thai: '泰语',
  traditionalChinese: '繁体中文',
  pleaseEnterSimplifiedChinese: '请输入简体中文',
  simplifiedChinese: '简体中文',
  pleaseEnterEnglish: '请输入英文内容',
  englishFallback: '英文(兜底)',
  uploadLogsSeparately: '上传日志分析',
  journal: '日志',
  recordingFrequency: '录频',
  experimentalData: '实验数据',
  switch: '开关',
  sdkLogs: 'SDK日志',
  chooseJapaneseChronicle: '选择日志文件',
  pleaseSelectALog: '// 请选择日志文件',
  selectTime: '选择时间',
  selectDate: '选择日期',
  onlyLeavesCanBeSelected: '只能选择叶子节点',
  feedbackClassificationSelection: '反馈分类选择',
  pleaseEnterToReply: '请输入要回复的内容（按Enter发送, Ctrl+Enter换行）',
  viewDetails: '查看详情 >',
  comingSoon: '敬请期待',
  feedbackFailed: '反馈失败',
  feedbackSuccessful: '反馈成功',
  resultsOfEnforcement: '执行结果',
  tipsLimit: 'tips: 限制每秒触发插件的反馈数,最大每秒触发500条反馈',
  frequencyLimit: '限频',
  pleaseSelectAPlugin: '请选择插件',
  implement: '执行',
  batchTriggerInsertion: '批量触发插件',
  pluginExecutionLost: '插件执行失败: {val1}',
  see: '查看',
  open2: '打开',
  transferredToOnc2: '已转Oncall',
  addToQuestion: '添加至问题',
  feedbackFollowUpOperation: '反馈跟进操作成功',
  enterEnterpriseMicro: '输入企业微信英文名,用;分隔',
  pleaseCheckAr: '请检查{val1}的名字是否正确',
  currentlySelected: '当前选择的产品为:',
  pleaseSelectAProduct: '请选择产品',
  project: '项目',
  pleaseEnterTheProduct: '请输入产品关键字',
  invalidIssue: '无效问题',
  confirmedIssue: '已确认问题',
  notConfirmed: '未确认',
  pleaseChooseToModify: '请选择修改状态',
  pleaseInputProcessing: '请输入处理人',
  noteMultipleTreatments: '注: 多个处理人以英文分号隔开',
  pleaseInputProcessing2: '请输入处理人!',
  pleaseEnterTheQuestion: '请输入问题描述',
  describe: '描述',
  pleaseEnterADescription: '请输入描述!',
  pleaseEnterTheQuestion2: '请输入问题标题',
  pleaseEnterATitle2: '请输入标题!',
  editingIssues: '编辑问题',
  modifyTheProblemToBeLost: '修改问题失败, 请稍后再试',
  modifyTheProblemTo: '修改问题成功',
  multipleProcessors: '多个处理人以英文分号;隔开',
  synchronizeUpdates: '同步更新',
  questionTitle: '问题标题',
  problemLevel: '问题等级',
  addToExisting: '添加至已有问题(可关联TAPD)',
  newProblem: '新问题',
  articleFeedback: '条反馈',
  selected: '已选择',
  afterAddressingTheIssueItCanBeResolved:
    '转问题后可在【问题跟进】中查看，也可在添加后进行转单操作',
  pleaseClarifyTheMeaningOfTheSentence:
    '请将语意一致的反馈设置为问题后跟进，可在【问题跟进】中查看。',
  thisProjectDoesNotHave: '该项目没有绑定Tapd!',
  followUpOnTransferringIssues: '转问题跟进',
  bindTap: '绑定tapd成功',
  bindTap2: '绑定tapd失败: err: {val1}',
  relatedIssuesLost: '关联问题失败',
  relatedIssuesBecome: '关联问题成功',
  relatedIssuesLost2: "'关联问题失败, 请稍后再试:'{val1}",
  addAsProblem: '添加为问题',
  addAsProblemAndCopy: '添加为问题并复制',
  transferProblemAndCopy: '转问题并复制',
  addAndConvertToO: '添加并转Oncall',
  addAndConvertToT: '添加并转TAPD',
  theTitleCannotBe: '标题不能为空',
  theHandlerCannot: '处理人不能为空',
  withdrawalMessageLost: '撤回消息失败,请刷新页面后再尝试!',
  withdrawTheMessage: '撤回消息成功!',
  replySuccessful: '回复成功',
  turn: '转',
  batchManualReturn: '批量手动回复',
  reply2: '回复',
  pleaseSelectAtLeast: '请选择至少一条记录',
  userInitiated: '已发起用户评分，等待用户响应',
  thisServices: '本次服务的满意度的评价',
  unknownUser: '未知用户',
  withdraw: '撤回',
  continue: '继续',
  whetherToWithdrawI: '是否撤回:【{val1}】, 撤回后用户将不可见该回复',
  withdrawMessage: '撤回消息',
  confirmThisFeedback: '确认该反馈已处理完毕,并向用户发起评分?',
  initiateSatisfaction: '发起满意度评分',
  alreadyInitiated: '已经发起过评分了,请查看用户的评分结果!',
  userInitiated2: '已发起用户评分，等待用户响应!',
  quickReply: '快速回复',
  originalFeedbackReply: '原反馈回复详情',
  noViewOriginal: '暂无查看原反馈详情的权限',
  aiSee: 'AiSee助手为您推荐如下回复方案:',
  uploadFailedPlease: '上传失败, 请稍后再试',
  uploadImage: '上传图片',
  pleaseEnterAReply: '请输入回复内容或者上传图片',
  pictureUploadingInProgress: '图片上传中...',
  pleaseEnterCo: '请输入{val1}，多个使用逗号或空格分割',
  pleaseSelectProcessing: '请选择处理状态',
  multipleOnCs:
    '多个oncall指派人通过分号";"分隔, 优先派单给此处填写的指派人(未填则派单给 oncall 平台配置的值班人员)',
  oncal2: 'oncall指派人',
  configurationMethod: '配置方法',
  pleasePrepareInAdvanceInO: '请提前在oncall平台配置对应规则, 否则建单不成功。',
  oncal3: 'oncall业务分类',
  oncal4: 'oncall运营产品',
  newOnc: '新建ONCALL单',
  oncal5: 'oncall转单失败,请重新输入',
  noOperationalAuthority: '没有操作权限,请申请权限后再操作.',
  oncal6: 'oncall转单失败,请重新转单',
  oncal7: 'oncall转单成功',
  sReco: '{val1}等一条反馈',
  pleaseChooseOn: '请选择oncall运营产品',
  proposal: '建议',
  tips: '提示',
  commonly: '一般',
  serious: '严重',
  deadly: '致命',
  empty: '--空--',
  beOfNoGreatImportance: '无关紧要',
  low: '低',
  in: '中',
  high: '高',
  urgent: '紧急',
  newToTa: '新建转Tapd',
  addComments: '追加评论',
  pleaseChooseOne: '请选择一个处理人',
  configureOtherT: '配置其他tapd项目',
  convertToTapd: '转 Tapd 发生异常,请到 Tapd 空间中查看转单结果!',
  convertToTapd2: '转 Tapd 需求单成功!',
  convertToTapd3: '转 Tapd 缺陷单成功!',
  demand: '需求',
  waitingForTheFeed: '等{val1}条反馈',
  pleaseSelectProcessing2: '请选择处理人',
  pleaseChooseATitle: '请选择标题',
  pleaseSelectType: '请选择类型',
  pleaseSelectNew: '请选择新建到的Tapd空间',
  viewDetails2: '查看详情',
  managementBackendCheck: '>>> 管理后台查看问题详情: ',
  divst:
    '<div style="display: flex">终端日志: <div style="display: inline-block">{val1}</div></div>',
  ahref: '<a href="{val1}" style="cursor: pointer;">日志信息{val2}</a><br/>',
  isItRoo: '是否Root: 否',
  isItRoo2: '是否Root: 是',
  networkFee: '网络: {val1} <br/><br/>',
  modelFee: '型号: {val1} <br/><br/>',
  manufacturerFee: '厂商: {val1} <br/><br/>',
  systemVersionF: '系统版本: {val1} <br/><br/>',
  regionalFee: '地域: {val1} <br/><br/>',
  feedbackTimeF: '反馈时间: {val1}<br/><br/>',
  discoveredVersionF: '发现版本: {val1}<br/><br/>',
  accountFee: '账号: {val1}<br/><br/>',
  defect: '缺陷',
  pleaseEnterTa: '请输入TAPD链接中的长ID',
  pleaseEnterTa2: '请输入TAPD链接中的长ID!',
  defectId: '缺陷ID',
  requirementId: '需求ID',
  pleaseSelectType2: '请选择类型!',
  type: '类型',
  relatedTo: '关联到',
  convertToTapd4: '转 Tapd 发生异常,请重试或联系管理员处理!',
  convertToTapd5: '转 Tapd 问题单成功!',
  waitingForTheFeed2: '等{val1}条反馈',
  associateTap: '关联Tapd单',
  pleaseEnterMa: '请输入{val1}!',
  waitingForTheFeed3: '等{val1}条反馈',
  tipsT5:
    '{val1}<a href="{val2}" target="_blank" style="cursor: pointer;">\n      {val3}</a> {val4}<br/><br/>此次关联如下 {val5} 条反馈<br>\n      {val6}.{val7}`)\n        .join(\'<br>\')}',
  tipsT6:
    '{val1}<a href="{val2}" target="_blank" style="cursor: pointer;">\n      {val3}</a> {val4}<br/><br/>关联反馈<br>\n      {val5}.{val6}`)\n        .join(\'<br>\')}',
  timeIsTheMost: '[{val1} 最新汇总共 {total_count_to_replace} 个反馈]',
  tipsT7:
    '\n    {val1}<a href="{val2}" target="_blank" style="cursor: pointer;">{val3}</a><br/><br/>\n    【问题描述】<br/>\n    <div style="padding-left: 20px;">\n      {val4}<br/>\n    </div>\n  ',
  divst2:
    '<div style="display: flex">后台日志: <div style="display: inline-block">{val1}</div></div><br/>',
  divst3:
    '<div style="display: flex">终端日志: <div style="display: inline-block">{val1}</div></div>',
  ahref2: '<a href="{val1}" style="cursor: pointer;">日志信息{val2}</a><br/>',
  imgal2:
    '<img alt="图片.jpg" style="width: 180px; margin: 5px 10px 0 0" src="{val1}">',
  networkFee2: '网络: {val1} <br/><br/>',
  modelFee2: '型号: {val1} <br/><br/>',
  manufacturerFee2: '厂商: {val1} <br/><br/>',
  systemVersionF2: '系统版本: {val1} <br/><br/>',
  regionalFee2: '地域: {val1} <br/><br/>',
  feedbackTimeF2: '反馈时间: {val1}<br/><br/>',
  discoveredVersionF2: '发现版本: {val1}<br/><br/>',
  accountFee2: '账号: {val1}<br/><br/>',
  fixedUnfolding: '固定展开',
  automaticContraction: '自动收缩',
  navigationDisplay: '导航显示方式',
  chinese: '中文',
  switchLanguage: '切换语言',
  productPermissionApplication: '【产品权限申请】消息提醒设置',
  messageReceptionConfiguration: '消息接收配置',
  updateFailed: '更新失败!',
  updateSuccessful: '更新成功!',
  enterpriseWeChat: '企微邮件',
  logOutAndLogIn: '退出登录',
  messageConfiguration: '消息配置',
  permissionReview: '权限审核',
  feedbackOnOpinions: '意见反馈',
  copyId: '复制ID',
  second: '次',
  feedbackInThePastThreeDays: '近三天反馈',
  inThePastYearThe: '近一年第',
  currentFeedback: '当前反馈',
  goToTheFeedbackList: '去反馈列表查看',
  accumulatedInThePastYear: '近一年累计反馈{val1}次',
  accumulatedReversalsThisMonth: '本月累计反馈 ',
  forTheFirstFeedback: '首次反馈用户',
  theLastTimeOn: '最近一次于',
  copyUserAccount: '复制用户账号',
  accordingToTheCurrentTime: '按当前时间向前推移',
  whole: '全部',
  allFeedbackVersion: '全部反馈版本',
  edition: '版本',
  allSources: '全部来源',
  allFeedbackCategories: '全部反馈类型',
};
