import {
  Box,
  Fade,
  Grow,
  Skeleton,
  Slide,
  Stack,
  SxProps,
  Theme,
  Zoom,
} from '@mui/material';
import React, { useMemo } from 'react';

export const getDefaultSkeleton = (count: number) => {
  return (
    <Stack spacing={1}>
      {new Array(count).fill(0).map((_, index) => (
        <Skeleton
          variant="rectangular"
          width={`${100 - (index + 1) * 20}%`}
          animation="wave"
          height={14}
          sx={{
            borderRadius: '6px',
          }}
          key={index}
        />
      ))}
    </Stack>
  );
};

const MSpinSkeleton = ({
  spinning,
  children,
  transitionType = 'no',
  sx = {},
  skeleton,
  defaultSkeletonCount = 4,
}: {
  spinning: boolean;
  children: React.ReactNode;
  transitionType?: 'no' | 'Zoom' | 'Fade' | 'Grow' | 'Slide';
  sx?: SxProps<Theme>;
  // 自定义骨架显示
  skeleton?: React.ReactNode;
  // 默认骨架显示数量
  defaultSkeletonCount?: 1 | 2 | 3 | 4;
}) => {
  const showContent = useMemo(() => {
    switch (transitionType) {
      case 'Zoom': {
        return <Zoom in={!spinning}>{<Box>{children}</Box>}</Zoom>;
      }
      case 'Fade': {
        return <Fade in={!spinning}>{<Box>{children}</Box>}</Fade>;
      }
      case 'Grow': {
        return <Grow in={!spinning}>{<Box>{children}</Box>}</Grow>;
      }
      case 'Slide': {
        return <Slide in={!spinning}>{<Box>{children}</Box>}</Slide>;
      }
      default: {
        return children;
      }
    }
  }, [transitionType, children]);

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      {spinning ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            bgcolor: 'transparent',
            ...sx,
          }}
        >
          {skeleton ? skeleton : getDefaultSkeleton(defaultSkeletonCount)}
        </Box>
      ) : (
        showContent
      )}
    </Box>
  );
};

export default MSpinSkeleton;
