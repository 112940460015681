import { autorun, makeAutoObservable, observable } from 'mobx';
import React from 'react';

import type { UserInfo } from '@/api';
import { LS_LAST_PRODUCT_KEY } from '@/def/constant';
import { resolvablePromise } from '@/util/emitter';
import ls from '@/util/ls';
import { RoleType } from '@/util/role';

const [USER_READY, resolveUser] = resolvablePromise<string>();
const [USER_INFO_READY, resolveUserInfo] = resolvablePromise<UserInfo>();
const [PRODUCT_TREE_READY, ResolveProductTree] = resolvablePromise<Product[]>();

class Store {
  // 用户信息
  public user = '';
  public userInfo: UserInfo | null = null;
  public dclAppInfo: { [key: string]: any } = {};
  public productId = '';
  public businessId = '';
  @observable public appId = '';
  @observable public selectappIds: string[] = [];
  @observable public publicKey = '';
  public pId = 1;
  /** 是否iframe嵌入 */
  public isIframe = false;
  public modelId = '';
  // 当前微应用,如: setting
  public microApp = '';
  // 待加载的微应用的路由对象
  public hashInfo = {
    hash: '',
    href: '',
  };
  // 产品树
  public productTree: Product[] = [
    { uuid: '', name: 'initialization', business: [] },
  ];
  // 当前加载的 tone 产品
  public selectProduct: SelectProduct = {
    lv1Id: '',
    lv1cname: '',
    lv2Id: '',
    lv2cname: '',
    lv3Id: '',
    lv3cname: '',
  };
  // 用户最后选中的产品
  public userLastProduct: SelectProduct = {
    lv1Id: '',
    lv1cname: '',
    lv2Id: '',
    lv2cname: '',
    lv3Id: '',
    lv3cname: '',
  };
  // 是否用户切换产品 - 区分产品变化事件
  public isUserSwitch: boolean = false;

  // 产品的Tapd列表
  public tapdProjectList: any = [];

  // 最新的产品的Tapd
  public tapdProject: TAPDProject = {
    anrToTapd: 0,
    appId: '',
    bugTemplateId: '',
    bugTemplateName: '',
    crashToTapd: 0,
    defaults: 0,
    feedbackShakeTapd: 0,
    feedbackToTapd: 0,
    id: 0,
    nativeToTapd: 0,
    othersToTapd: 0,
    rdmErrorTapdProjectId: '',
    rdmErrorToTapd: 0,
    tapdAssignTo: '',
    tapdProjectId: '',
    tapdProjectName: '',
  };

  // 加载 loading 标识
  public loadingBaseInfo = false;

  // 智能分类
  @observable public classData: any = [
    {
      title: '所有智能分类',
      children: [],
      ID: '所有用户标签',
    },
    {
      title: '无分类',
      children: [],
      ID: '无标签',
    },
  ];

  /** 所有智能分类（包含禁用）*/
  @observable public allClassData: any = [
    {
      title: '所有智能分类',
      children: [],
      ID: '所有用户标签',
    },
    {
      title: '无分类',
      children: [],
      ID: '无标签',
    },
  ];

  // 平铺的三级产品(一级产品下所有三级产品)
  @observable public flatLv3List: FlatLv3Product[] = [];
  // 平铺的三级产品(二级产品下所有三级产品)
  @observable public lv3List: FlatLv3Product[] = [];
  // 用户分类树
  @observable public userLevelList: any[] = [];
  // 用户标签
  @observable public tagList: any[] = [];
  // 来源字段
  @observable public businessTypes: BusinessType[] = [];
  /** 全局请求的下拉选项 : 版本versionname、反馈来源source、用户标签userTag、国家country */
  @observable public usageSelectOptions: Record<string, any[]>[] = [];
  // 导航栏显示的面包屑
  @observable public breadcrumb: React.ReactNode | null = null;
  // 问卷标签;
  @observable public labelList: LabelOption[] = [];
  // 问卷来源
  @observable public channelList: Option[] = [];
  // 用户身份
  @observable public userAuthInfo: {
    init: boolean;
    roleType: RoleType;
    isOpenBusinessQuery: boolean;
  } = {
    init: true,
    roleType: RoleType.operator,
    isOpenBusinessQuery: false,
  };

  // FAQ列表
  @observable public faqTreeRecord: Record<string, any> = {};

  // 是否处于脱敏版本
  @observable public isDesensitizedVersion: boolean = false;

  // 开通 舆情分析的 appId
  @observable public publicOpinionAppIds: string[] = [];

  public constructor() {
    makeAutoObservable(this);
  }

  @after(resolveUser)
  public setUser(user: string) {
    this.user = user;
  }

  @after(resolveUserInfo)
  public setUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo;
  }

  public setMicroApp(app: string) {
    this.microApp = app;
  }

  public setHashInfo(hash: { hash: string; href: string }) {
    this.hashInfo = hash;
  }

  @after(ResolveProductTree)
  public setProductTree(tree: Product[]) {
    this.productTree = tree;
  }

  public setSelectProduct(product: SelectProduct) {
    this.selectProduct = product;
  }

  public setUserLastProduct(product: SelectProduct) {
    this.userLastProduct = product;
  }

  public setIsUserSwitch(isUserSwitch: boolean) {
    this.isUserSwitch = isUserSwitch;
  }

  @after(ResolveProductTree)
  public setLoadingBaseInfo(showLoading: boolean) {
    this.loadingBaseInfo = showLoading;
  }

  public setDclAppInfo(data): void {
    this.dclAppInfo = data;
  }

  public setProductId(data: string): void {
    this.productId = data;
  }

  public setBusinessId(data: string): void {
    this.businessId = data;
  }

  public setAppId(data: string): void {
    this.appId = data;
  }

  public setSelectappIds(data: string[]): void {
    this.selectappIds = data;
  }

  public setPublicKey(data: string): void {
    this.publicKey = data;
  }

  public setPId(data: number): void {
    this.pId = data;
  }

  public setModelId(data: string): void {
    this.modelId = data;
  }

  public setTapdProjectList(tapdProjectList) {
    this.tapdProjectList = tapdProjectList;
  }

  public setTapdProject(tapdProject) {
    this.tapdProject = tapdProject;
  }

  public setUserLevelList(list: any[]): void {
    this.userLevelList = list;
  }

  public setTagList(tags: any[]): void {
    this.tagList = tags;
  }

  public setClassData(data: any[]): void {
    this.classData = data;
  }

  public setAllClassData(data: any[]): void {
    this.allClassData = data;
  }

  public setBusinessTypes(data: any[]): void {
    this.businessTypes = data;
  }

  public setUsageSelectOptions(data: Record<string, any[]>[]): void {
    this.usageSelectOptions = data;
  }

  public setBreadcrumb(node: React.ReactNode): void {
    this.breadcrumb = node;
  }

  public setLabelList(labels): void {
    this.labelList = labels || [];
  }

  public setChannelList(channels): void {
    this.channelList = channels || [];
  }

  public setFlatLv3List(list): void {
    this.flatLv3List = list;
  }

  public setLv3List(list): void {
    this.lv3List = list;
  }

  public setUserAuthInfo(info): void {
    this.userAuthInfo = info;
  }

  public setFaqTreeRecord(record: Record<string, any>): void {
    this.faqTreeRecord = record;
  }

  public setIsDesensitizedVersion(flag: boolean) {
    this.isDesensitizedVersion = flag;
  }

  public setPublicOpinionAppIds(appIds: string[]) {
    this.publicOpinionAppIds = appIds;
  }

  public setIsIframe(flag: boolean) {
    this.isIframe = flag;
  }
}

// 在类的方法执行完毕后调用指定函数
function after(resolve: (...args: any) => void) {
  return function test(
    _target: unknown,
    _propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>
  ): TypedPropertyDescriptor<any> {
    const fn = descriptor.value;
    descriptor.value = function (...args: any) {
      // eslint-disable-line no-param-reassign
      const ret = fn.call(this, ...args);
      resolve(...args);
      return ret;
    };
    return descriptor;
  };
}

const STORE = new Store();

// 自动同步app和product信息到baseRoute
autorun(() => {});

// 自动保存最后一次选择的产品
autorun(() => {
  STORE?.selectProduct && ls.set(LS_LAST_PRODUCT_KEY, STORE.selectProduct);
});

// 自动把产品信息同步到 url
autorun(() => {});

export { PRODUCT_TREE_READY, USER_INFO_READY, USER_READY };
export type TStore = typeof STORE;
export default STORE;
