import React from 'react';
import { RouteConfig } from 'react-router-config';

import { PageRouteType, routePostProc } from '@/util/route';

const UserVoice = React.lazy(() => import('@/page/app/insight/user-voice'));

const AiseeIssueList = React.lazy(
  () => import('@/page/app/insight/aisee-issue/issue-list')
);

const AiseeIssueDetail = React.lazy(
  () => import('@/page/app/insight/aisee-issue/issue-detail')
);

const AiseeIssueLayout = React.lazy(
  () => import('@/page/app/insight/aisee-issue/issue-layout')
);

const AiseeAlarmList = React.lazy(
  () => import('@/page/app/operate/alarm-list')
);

const AiseeAlarmDetail = React.lazy(
  () => import('@/page/app/insight/aisee-issue/alarm-detail')
);

const AlarmTableDetail = React.lazy(
  () => import('@/page/app/operate/alarm-list/detail')
);

const AiseeStatisticsEntry = React.lazy(
  () => import('@/page/app/insight/aisee-statistics')
);

const StatisticsFaqOverview = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/faq')
);

const StatisticsDistribution = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/distribution')
);

const StatisticUserdistribution = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/userdistribution')
);

const StatisticCessor = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/cessor')
);

const StatisticCustomargs = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/customargs')
);

const StatisticIntelligent = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/intelligent')
);

const StatisticReports = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/reports')
);

const StatisticCreateReport = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/reports/create-report')
);

const StatisticViewReport = React.lazy(
  () => import('@/page/app/insight/aisee-statistics/reports/view-report')
);

const INSIGHT_ROUTE: RouteConfig[] = [
  // 用户声音列表页面
  {
    path: '/userVoice',
    component: UserVoice,
  },
  // 问题跟进列表页面
  {
    path: '/aiseeIssueList',
    component: AiseeIssueList,
  },
  // 问题详情页面
  {
    path: '/aiseeIssue',
    component: AiseeIssueDetail,
  },
  // 问题看板页面
  {
    path: '/aiseeIssueLayout',
    component: AiseeIssueLayout,
  },
  // 告警监控配置列表页面
  {
    path: '/aiseeAlarmList',
    component: AiseeAlarmList,
  },
  {
    path: '/alarmTableDetail',
    component: AlarmTableDetail,
  },

  // 告警详情页面
  {
    path: '/aiseeAlarmDetail',
    component: AiseeAlarmDetail,
  },
  // 统计模块配置
  {
    path: '/aiseeStatistics',
    component: AiseeStatisticsEntry,
  },
  {
    path: '/faqOverview',
    component: StatisticsFaqOverview,
  },
  {
    path: '/distribution',
    component: StatisticsDistribution,
  },
  {
    path: '/userdistribution',
    component: StatisticUserdistribution,
  },
  {
    path: '/cessor',
    component: StatisticCessor,
  },
  {
    path: '/customargs',
    component: StatisticCustomargs,
  },
  {
    path: '/intelligent',
    component: StatisticIntelligent,
  },
  {
    path: '/reports',
    exact: true,
    component: StatisticReports,
  },
  {
    path: '/reports/create',
    exact: true,
    component: StatisticCreateReport,
  },
  {
    path: '/reports/update/:id',
    exact: true,
    component: StatisticCreateReport,
  },
  {
    path: '/reports/view/:id',
    exact: true,
    component: StatisticViewReport,
  },
  {
    path: '/reports/task/:id',
    exact: true,
    component: StatisticViewReport,
  },
];

export { INSIGHT_ROUTE };
export default routePostProc(PageRouteType.INSIGHT, INSIGHT_ROUTE);
