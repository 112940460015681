type Callback = (param: any) => void;
type FuncChain = Set<Callback>;

export class Emitter {
  private chanMap: Map<any, FuncChain>;
  public constructor() {
    this.chanMap = new Map();
  }

  public on(chan: any, handler: Callback): void {
    if (!this.chanMap.has(chan)) {
      this.chanMap.set(chan, new Set());
    }
    (this.chanMap.get(chan) as FuncChain).add(handler);
  }

  public off(chan: any, handler: Callback): boolean {
    if (!this.chanMap.has(chan)) return false;
    return (this.chanMap.get(chan) as FuncChain).delete(handler);
  }

  public clear(chan: any): boolean {
    return this.chanMap.delete(chan);
  }

  public emit(chan: any, msg: any): void {
    if (!this.chanMap.has(chan)) return;
    for (const fn of (this.chanMap.get(chan) as FuncChain).values()) {
      try {
        fn(msg);
      } catch (err) {
        console.error(err);
      }
    }
  }
}

const EMITTER = new Emitter();

// 返回一个promise和它的resolve函数
export function resolvablePromise<T>(): [Promise<T>, (data: T) => void] {
  const channel = Symbol('channel');

  const promise: Promise<T> = new Promise((resovle) => {
    EMITTER.on(channel, resovle);
  });

  const resolve = (data: T) => {
    EMITTER.emit(channel, data);
    EMITTER.clear(channel);
  };

  return [promise, resolve];
}

export default EMITTER;
