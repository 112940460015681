const enum LogLevel {
  Log, // 普通日志
  Warning, // 警告日志
  Error, // 错误日志
}

const Styles = ['color: green;', 'color: orange;', 'color: red;'];

const Methods = ['info', 'warn', 'error'] as const;
class Logger {
  private namespace: string;

  constructor(namespace = '[MFer]') {
    this.namespace = namespace;
  }

  /**
   * 打印输出信息 🐛
   * @param args 任意参数
   */
  public info(...args: unknown[]) {
    this.log(LogLevel.Log, args);
  }

  private log(level: LogLevel, args: unknown[]) {
    console[Methods[level]](`%c${this.namespace}`, Styles[level], ...args);
  }

  /**
   * 打印输出错误信息 ❌
   * @param args 任意参数
   */
  public error(...args: unknown[]) {
    this.log(LogLevel.Error, args);
  }

  /**
   * 创建新的 Logger 实例
   * @param namespace 命名空间
   * @returns Logger
   */
  public create(namespace = '[MFer]') {
    return new Logger(namespace);
  }

  /**
   * 打印输出警告信息 ❕
   * @param args 任意参数
   */
  public warn(...args: unknown[]) {
    this.log(LogLevel.Warning, args);
  }
}

export default new Logger();
