export default {
  "rejected": "Rejected",
  "alreadyPassed": "Already passed",
  "pendingReview": "Pending review",
  "operationsPersonnel": "Operations personnel",
  "developers": "Developers",
  "administrators": "Administrators",
  "pleaseChooseToApply": "Please select the role to apply for",
  "applyForARole": "Apply for a role",
  "pleaseChooseToReview": "Please select the review status",
  "auditStatus": "Audit status",
  "pleaseEnterTheProduct": "Please enter the product ID",
  "productId": "Product ID",
  "totalTota": "A total of {val1} items",
  "confirmToRejectThe": "Are you sure to reject the application?",
  "confirmApprovalForThis": "Are you sure to approve the application?",
  "reject": "Reject",
  "approval": "Approval",
  "applicationRemarks": "Application Remarks",
  "reviewer": "Reviewer",
  "reviewTime": "Review time",
  "applicationTime": "Application time",
  "applicant": "Applicant",
  "applicationNumber": "Application Number",
  "updateTheAuditReport": "Failed to update audit status! Reason for error: {val1}",
  "updateTheAuditReport2": "Successfully updated audit status!",
  "secondaryProducts": "Secondary products",
  "firstLevelProduct": "First level product",
  "other": "Other",
  "digitalAdministration": "Digital Administration",
  "musicGroup": "Music Group",
  "sLine": "S line",
  "intelligentHardware": "Intelligent hardware",
  "carApplications": "Car applications",
  "tvEnd": "TV end",
  "pcClient": "PC client",
  "miniProgram": "Mini program",
  "officialAccount": "Official account",
  "actionAdventure": "Action Adventure",
  "utilities": "Utilities",
  "lifestyleAndEntertainment": "Lifestyle and entertainment",
  "shoppingFashion": "Shopping Fashion",
  "beautifyPhotography": "Beautify Photography",
  "childParentChild": "Child Parent Child",
  "audiovisual": "Audiovisual",
  "travelMap": "Travel map",
  "readingAndLearning": "Reading and Learning",
  "mobilePhoneBeautification": "Mobile phone beautification",
  "systemInput": "System input",
  "chatCommunication": "Chat communication",
  "officeBusiness": "Office Business",
  "recreationEntertainment": "Recreation & Entertainment",
  "puzzleGame": "Puzzle game",
  "networkGame": "Network game",
  "sportsRacing": "Sports Racing",
  "businessStrategy": "Business strategy",
  "cosplay": "Cosplay",
  "flyingShooting": "Flying shooting",
  "clickToOldVersion": "Click to update settings on the old DCL platform",
  "copyKey": "Copy key",
  "secretKey": "Secret key",
  "experienceTheApp": "Experience APPID",
  "feedbackPid": "Feedback PID",
  "applicationId": "Application ID",
  "applicationForm": "Application form",
  "pleaseSelectAnApplication": "Please select the application form",
  "maximumNumberOfUploads": "Upload up to 1 image, size 120 * 120",
  "applicationIcon": "Application icon",
  "belongingBg": "Belonging BG",
  "pleaseSelectYourAffiliation": "Please select the BG to which you belong",
  "applicationDescription": "Application Description",
  "applicationType": "Application type",
  "pleaseEnterTheApplication": "Please enter the application type",
  "copyProductPublic": "Copy product public key",
  "applyPublicKey": "Application of public key for identity verification during feedback data reporting platform",
  "applyName": "Apply name",
  "pleaseEnterTheApplication2": "Please enter the application name",
  "registrationTime": "Registration time",
  "saveFailed": "Save failed",
  "savedSuccessfully": "Saved successfully",
  "tapdConfiguration": "Tapd configuration",
  "forDetailsPleaseReferTo": "For details, please refer to",
  "referenceDocuments": "Reference Documents",
  "forDetailsPleaseReferTo2": "For details, please refer to:",
  "oncal": "Oncall configuration",
  "experienceSettings": "Experience Settings",
  "doYouWantToDeleteThis": "Do you want to delete this oncall configuration?",
  "deleteConfiguration": "Delete configuration",
  "webho": "Webhook link",
  "oncal2": "Oncall operation business name",
  "webho2": "Webhook address",
  "pleaseEnterWe": "Please enter the webhook address",
  "pleaseEnterOn": "Please enter the name of the Oncall operation business",
  "oncal3": "Oncall configuration",
  "infoLost": "{val1} Failed: err:{val2}",
  "infoBecomes": "{val1} Successful",
  "modify": "Modify",
  "doYouWantToDeleteThis2": "Do you want to delete the Tapd configuration?",
  "tapdEmpty": "TAPD Space",
  "tapdEmpty2": "Tapd Space ID",
  "pleaseEnterTa": "Please enter Tapd space ID",
  "infoLost2": "{val1} Failed: err:{val2}",
  "infoBecomes2": "{val1} Successful",
  "doYouWantToDelete": "Do you want to delete:",
  "deleteMember": "Delete member",
  "totalTota2": "Total {val1} items",
  "inviteNewMembers": "Invite new members",
  "allMembers": "All members",
  "role": "Role",
  "updateNoteMissing": "Failed to update notes",
  "updateTheRemarksTo": "Updated notes successfully",
  "updateNicknameLost": "Updating nickname failed: {val1}",
  "updateNicknameTo": "Updated nickname successfully",
  "modifyMemberCorner": "Change member role type: {val1} Failed",
  "modifyMemberCorner2": "Change member role type: {val1} Successful",
  "deleteMemberD": "Delete member: {val1} Failed",
  "deleteMemberD2": "Delete member: {val1} Successful",
  "businessMembers": "Business members",
  "operatingMembers": "Operating members",
  "developmentMembers": "Development members",
  "allCharacters": "All characters",
  "aMember": "A member",
  "addTogether": "Add together",
  "searchForMembers": "Search for member nickname. If no corresponding person is found, press Enter to add",
  "memberNickname": "Member nickname",
  "pleaseChooseAnInvitation": "Please select the invited product members",
  "addProductAs": "Adding product members failed, please try again",
  "remarksInformation": "Remarks information",
  "invitationTime": "Invitation time",
  "businessMembersCan": "Business members \"can access all functional menus except for the\" Member Management \"module, and cannot modify the intelligent classification"
}