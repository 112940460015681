export default {
  invalid: '无效',
  problem: '问题',
  allTypes: '所有类型',
  followingUp: '跟进中',
  transferredEventList: '已转事件单',
  expired: '已过期',
  contactedUser: '已联系用户解决',
  hangUp: '挂起',
  rejected: '已拒绝',
  resolved: '已解决',
  day: '天',
  hour: '小时',
  minute: '分钟',
  pleaseSelectTheSource: '请选择来源',
  pleaseSelectVersion: '请选择版本',
  feedbackVersion: '反馈版本',
  pleaseSelectTheStatus: '请选择状态',
  processingStatus: '处理状态',
  userFeedback: '用户反馈',
  doesNotIncludeRelationships: '不包含关系',
  includeRelationships: '包含关系',
  mailbox: '邮箱',
  enterpriseMicroRobot: '企微机器人',
  weCom: '企业微信',
  customFields: '自定义字段',
  region: '地域',
  highFrequencyKeywords: '高频关键词',
  minute2: '60分钟',
  minute3: '45分钟',
  minute4: '30分钟',
  minute5: '15分钟',
  minute6: '5分钟',
  pleaseEnterTheKey: '请输入关键词',
  pleaseEnterTheNumberOfTimes: '请输入次数',
  pleaseInputTheFluctuation: '请输入波动限制',
  pleaseEnterDetection: '请输入检测时段',
  pleaseChooseOn: '请选择oncall接收地址',
  pleaseEnterAtLeast: '请至少输入一个接收人',
  pleaseEnterTheCompany: '请输入企业微信机器人',
  pleaseSelectAtLeast: '请至少选择一种接收方式',
  pleaseSelectDetection: '请选择检测频率',
  pleaseProvideFeedback: '请输入反馈文本',
  pleaseEnterThePlugin: '请输入插件用途',
  pleaseEnterTheAlarm: '请输入告警名称',
  pleaseEnterTheSubscribe: '请输入主题名称',
  useSpacesForMultipleValues: '多值用空格分隔',
  multipleValuesInEnglish: '多值以英文字符逗号隔开(,)',
  inputBoxAndSingle: '输入框且单个值',
  notBelongingTo: '不属于',
  belongTo: '属于',
  notIncluded: '不包含',
  beEqualTo: '等于',
  prefixMatch: '前缀匹配',
  regularMismatch: '正则不匹配',
  lengthLimit: '长度限制',
  regularMatching: '正则匹配',
  satisfyAnyRequirement: '满足任意条件(或)',
  satisfyAllRequirements: '满足全部条件(且)',
  productRegistration: '产品注册',
  logAnalysisIsTheMost: '日志分析最佳实践',
  contactTheAssistant: '联系小助手',
  guidanceDocument: '指引文档',
  lessThanOrEqualTo: '小于或等于',
  lessThan: '小于',
  greaterThanOrEqualTo: '大于或等于',
  greaterThan: '大于',
  allStates: '所有状态',
  notEqualTo: '不等于',
  lessThanOrEqualTo2: '小于等于',
  greaterThanOrEqualTo2: '大于等于',
  excludeSelected: '排除所选',
  allRules: '所有规则',
  transferredProblemList: '已转问题单',
  newFeedback: '新反馈',
  antiFraudReporting: '反诈上报',
  abuse: '谩骂',
  pornographic: '色情',
  vulgar: '低俗',
  advertisement: '广告',
  neutralEmotion: '中性情感',
  negativeEmotions: '负向情感',
  positiveEmotions: '正向情感',
  noAttributes: '无属性',
  allAttributes: '所有属性',
  modified: '已修改',
  notChanged: '未修改',
  allIntelligentComponents: '所有智能分类修改',
  allCountries: '所有国家',
  allOfThem: '所有',
  allAttachments: '全部附件',
  allNetworks: '所有网络',
  allSources: '所有来源',
  allVersions: '所有版本',
  video: '视频',
  enclosure: '附件',
  no: '否',
  transferredToTap: '已转Tapd',
  repeat: '重复',
  roast: '吐槽',
  consultingService: '咨询',
  positiveReviews: '好评',
  explain: '说明',
  example: '示例',
  inputFormat: '输入格式',
  multipleKeywords: '多个关键字逻辑',
  queryContainsH:
    '查询包含“hello”或“world”关键字的反馈(【不包含】条件下不适用)',
  keywordKey: '【关键字】|【关键字】(英文竖线)',
  keywordKey2: '【关键字】,【关键字】(英文逗号)',
  querySimultaneousPackages: '查询同时包含“hello”和“world”关键字的反馈',
  keywordSpace: '【关键字】【空格】【关键字】',
};
