import history from '@/util/history';
import ls from '@/util/ls';

import { isIntranet } from '../dev-tool';

/**
 * Set user information to storage.
 * @param data The user information data.
 */
export const setUserInfoToStorage = (data: string) => {
  ls.set('dclUserInfo', data);
};

// Get user information from storage.
export const getUserInfoFromStorage = () => ls.get('dclUserInfo');

// Delete user information from storage.
export const removeUserInfoFromStorage = () => {
  ls.remove('dclUserInfo');
};

// Token was expired call back function.
export const onTokenExpired = () => {
  removeUserInfoFromStorage();
  // 防止重复重定向
  if (location.href?.includes('/oaLogin?redirect')) {
    return;
  }
  const redirect = encodeURIComponent(location.href);
  if (isIntranet()) {
    history.push(`/oaLogin?redirect=${redirect}`);
  } else {
    if (!location.href.includes('/login?code=')) {
      history.push(`/login`);
    }
  }
};
