import React from 'react';
import { RouteConfig } from 'react-router-config';

import { PageRouteType, routePostProc } from '@/util/route';

const Overview = React.lazy(() => import('@/page/app/overview'));

// 后台管理一级菜单路由 会自动添加产品ID前缀
const ROOT_ROUTE: RouteConfig[] = [
  {
    path: '/overview',
    component: Overview,
  },
];

export default routePostProc(PageRouteType.ROOT, ROOT_ROUTE);
