export default {
  menu: {
    multiSourceFeedbackCheck: '多源反馈查询',
    productSettings: '产品设置',
    rolePermissions: '角色权限',
    memberManagement: '成员管理',
    systemSettings: '系统设置',
    questionnaireManagement: '问卷管理',
    feedbackSettings: '反馈设置',
    feedbackInquiry: '反馈查询',
    feedbackOperation: '反馈运营',
    personalCenter: '个人中心',
    feedbackStatistics: '反馈统计',
    problemFollowUp: '问题跟进',
    userVoice: '用户声音',
    dataInsight: '数据洞察',
    overview: '概览',
    autoMonitorSetting: '监测规则',
    laboratory: '实验功能',
    publicExploration: '舆情探索',
    loganalysis: '离线日志分析',
    alphaTasklist: '内部体验',
    betaTasklist: '众测任务',
    summaryFeedback: '问题汇总',
    modelManagement: '众测用例管理',
    exchangeProcessing: '兑换处理',
    feedbackLayout: '反馈看板',
  },
};
