import React from 'react';
import { RouteConfig } from 'react-router-config';

import { PageRouteType, routePostProc } from '@/util/route';

const AiseeList = React.lazy(
  () => import('@/page/app/operate/aisee-list/keepAlive')
);

const AiseeListMult = React.lazy(
  () => import('@/page/app/operate/aisee-list-mult')
);
const AiseeDetail = React.lazy(() => import('@/page/app/operate/aisee-detail'));

const AiseeSatisfactionEntry = React.lazy(
  () => import('@/page/app/satisfaction')
);

const SatisfactionLayout = React.lazy(
  () => import('@/page/app/satisfaction/satisfaction-layout')
);

const SatisfactionList = React.lazy(
  () => import('@/page/app/satisfaction/satisfaction-list')
);

const SatisfactionSetting = React.lazy(
  () => import('@/page/app/satisfaction/satisfaction-setting')
);

const AiseeConfigEntry = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry')
);

const CategoryMgrPage = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/category')
);

const IntelClassify = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/intel-classify')
);

const ModelAction = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/model-action')
);

const Plugin = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/plugin')
);

const TagsManage = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/tags-manage')
);

/** ai客服 */
const AiServiceConfig = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/ai-service-config')
);

const ConfigInvalidRules = React.lazy(
  () =>
    import('@/page/app/operate/aisee-config-entry/config/config-invalid-rules')
);

const CustomFieldList = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/custom-field-list')
);

const FaqLayout = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/faq-layout')
);

const CustomH5 = React.lazy(
  () => import('@/page/app/operate/aisee-config-entry/config/custom-h5')
);

const AiseeMine = React.lazy(() => import('@/page/app/operate/aisee-mine'));

const OPERATE_ROUTE: RouteConfig[] = [
  {
    path: '/aiseeList', // 反馈查询列表
    component: AiseeList,
  },
  {
    path: '/aiseeListMult', // 多源反馈查询
    component: AiseeListMult,
  },
  {
    path: '/aiseeDetail', // 反馈详情
    component: AiseeDetail,
  },
  // 问卷相关
  {
    path: '/satisfaction',
    component: AiseeSatisfactionEntry,
  },
  {
    path: '/satisfactionList',
    component: SatisfactionList,
  },
  {
    path: '/satisfactionSetting',
    component: SatisfactionSetting,
  },
  {
    path: '/satisfactionLayOut',
    component: SatisfactionLayout,
  },
  // 运营配置相关
  // 配置入口
  {
    path: '/aiseeConfigEntry',
    component: AiseeConfigEntry,
  },
  // 配置子项
  {
    path: '/faqLayout',
    component: FaqLayout,
  },
  {
    path: '/custom',
    component: CustomH5,
  },
  {
    path: '/categoryMgrPage',
    component: CategoryMgrPage,
  },
  {
    path: '/intelClassify',
    component: IntelClassify,
  },
  {
    path: '/modelAction',
    component: ModelAction,
  },
  {
    path: '/plugin',
    component: Plugin,
  },
  {
    path: '/customFieldList',
    component: CustomFieldList,
  },
  {
    path: '/TagsManage',
    component: TagsManage,
  },
  {
    path: '/aiServiceConfig',
    component: AiServiceConfig,
  },
  {
    path: '/invalidRules',
    component: ConfigInvalidRules,
  },
  {
    path: '/mine',
    component: AiseeMine,
  },
];

export { OPERATE_ROUTE };
export default routePostProc(PageRouteType.OPERATE, OPERATE_ROUTE);
