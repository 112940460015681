import React from 'react';
import { RouteConfig } from 'react-router-config';

import { PageRouteType, routePostProc } from '@/util/route';

const Exploration = React.lazy(
  () => import('@/page/app/experiment/exploration')
);

const LogAnalysis = React.lazy(
  () => import('@/page/app/experiment/loganalysis')
);

const Sandbox = React.lazy(() => import('@/page/app/experiment/toc-sandbox'));

const EXPERIMENT_ROUTE: RouteConfig[] = [
  {
    path: '/exploration',
    component: Exploration,
  },
  {
    path: '/loganalysis',
    component: LogAnalysis,
  },
  {
    path: '/sandbox',
    component: Sandbox,
  },
];

export { EXPERIMENT_ROUTE };
export default routePostProc(PageRouteType.EXPERIMENT, EXPERIMENT_ROUTE);
