import STORE from '@/store';

export enum RoleType {
  all = 0,
  admin = 1,
  developer = 2,
  operator = 3,
  businesser = 4,
}

export enum AuditStatusType {
  all = 0,
  pending = 1,
  passed = 2,
  pefused = 3,
}

export const AUDIT_STATUS_TYPE_OPTIONS = [
  { label: '全部', value: String(AuditStatusType.all) },
  { label: '待审核', value: String(AuditStatusType.pending) },
  { label: '已通过', value: String(AuditStatusType.passed) },
  { label: '已驳回', value: String(AuditStatusType.pefused) },
];

export const ROLE_TYPE_OPTIONS = [
  { label: '全部', value: String(RoleType.all) },
  { label: '管理员', value: String(RoleType.admin) },
  { label: '开发人员', value: String(RoleType.developer) },
  { label: '运营人员', value: String(RoleType.operator) },
  { label: '业务人员', value: String(RoleType.businesser) },
];

export const isAdmin = () => STORE.userAuthInfo.roleType === RoleType.admin;

export const isBusinesser = () =>
  STORE.userAuthInfo.roleType === RoleType.businesser;
