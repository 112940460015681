export default {
  startGenerating: '开始生成',
  cancel: '取消',
  helpMeWriteIt: '帮我写:',
  pleaseSelectLevelOne: '请选择一级分类',
  feedbackFilteringBar: '反馈筛选条件',
  determine: '确定',
  reportSendingLost: '报告发送失败，请稍后重试: {val1}',
  reportSentAs: '报告发送成功',
  theReportContentIsNot: '报告内容不能为空',
  pleaseEnterRequiredField: '请输入必填项',
  pleaseEnterYourEmailAddress:
    '请输入邮箱全名，多个请用英文分号 ; 分割, 最多支持30个邮箱',
  mailRecipient: '邮件收件人（默认发送给自己）',
  pleaseEnterToSend: '请输入发送邮件的标题',
  emailTitle: '邮件标题',
  emailPushReport: '邮件推送报告设置',
  helpMeWriteIt2: '帮我写',
  inputTriggers: '输入 "/" 唤起',
  emailPush: '邮件推送',
  editFeedbackScore: '编辑反馈分析报告',
  hCent:
    '<h1 {val1}>【{val2}】-AI分析报告✍️</h1><p {val3}>(统计周期：{val4} ~ {val5}   创建人: {val6})  </p><a href=\'{val7}?ref=aiReport\' target="_blank">查看AI报告数据源</a> 🙌<br/>',
  store: '{val1} - AI分析报告(统计周期：{val2} ~ {val3})✍️',
  generateReport: '生成报告',
  setOtherScreens: '设置其他筛选条件',
  defaultFilterBar: '默认筛选条件与概览主页相同',
  youHaveSelectedAs: '您已选择如下筛选条件，确认将以此生成分析报告？',
  typicalUserFeedback: '典型用户反馈提取',
  specifyCategoryReverse: '指定类别反馈分析',
  overallFeedbackTrend: '反馈总体趋势',
  feedbackAnalysisSummary: '反馈分析摘要',
  lag: '5. 卡顿',
  whenBindingAnAccount:
    '4.账号绑定时无法接收短信验证码。该问题一直存在，疑似账号存在风险',
  afterPurchasingAtThatTime: '3.当时购买后，没有得到勋章',
  itsAllAdvertisements:
    '2.全是广告，来了会员还是广告，以后不会再用了。太失望了',
  taskLeader:
    '1.做任务领机会抽卡以前从来没出现过这种情况，今天做任务的时候任务就刷不出来了',
  aiAutomatically: 'AI自动将提取≤20条用户反馈原文',
  extractTheMostRange: '提取范围(最多选择两种一级分类)：',
  feedbackQuantityTo:
    '反馈量TOP5问题分布：会员自动续费关闭问题、系统消息通知重复推送、登录首页加载过长、订单数据下载失败、微信绑定时扫码问题\n                  反馈量增幅排行榜：帮助与反馈设置不展示、首页多次自动刷新、账号退出异常',
  specifyTheMostSuitableCategory: '指定类别(最多选择两种一级分类)：',
  generatingResults: '生成结果的范围：用户反馈的环比趋势及变化',
  duplicateAccountLogin: '5. 账号重复登录被封禁 367条(6.3%) 2277.0%↑',
  qqLoginIsBlocked: '4. QQ登录被系统管家拦截 252条(5.9%) 304.0%↑',
  directWithoutNetwork: '3. 无网络时直接白屏无提示 121条(2.8%) 350.0%↑',
  fullScreenClick: '2. 全屏点击下载按钮无反应 130条(3.0%) 398.5%↑',
  pointsAcquisitionAnd: '1. 积分获取和兑换问题 144条(3.2%) 400.1%↑',
  soaringProblem: '【飙升问题】',
  aHistoricalCycle: '个历史周期（含当前）的反馈量整体走势',
  pleaseEnter: '请输入5~20',
  generateCycleRange: '统计周期（N）：',
  generateContentDisplay: '生成内容示例',
  feedbackTime: '反馈时间',
  reportGenerated: '报告生成完毕',
  typicalUserZhen: '典型用户甄选完毕',
  classificationFeedback: '分类别反馈详细分析完毕',
  summaryOfFeedback: '反馈摘要总结生成完毕',
  feedbackTrend: '反馈量趋势变化小结完毕',
  feedbackDataScreening: '反馈数据筛选完毕',
  reportGenerationInProgress: '报告生成中...',
  typicalUserZhen2: '典型用户甄选中...',
  classificationFeedback2: '分类别反馈详细分析中...',
  summaryOfFeedback2: '反馈摘要总结生成中...',
  feedbackTrend2: '反馈量趋势变化小结中...',
  feedbackDataScreening2: '反馈数据筛选中...',
  customInformation: '自定义信息',
  applicationVersion: '应用版本',
  feedbackVersionSelection: '反馈版本选择',
  feedbackSourceSelection: '反馈来源选择',
  feedbackTypeSelection: '反馈类型选择',
  allCategories: '全部分类',
  systemInformation: '系统信息',
  pleaseChooseFi: '请选择{val1}',
  pleaseEnterFi: '请输入{val1}',
  multipleKeywords: '多个关键词检索参见提示',
  multipleKeywords2: '多个关键词用空格或英文逗号区隔',
  feedbackContent: '反馈内容',
  multipleSeparatedBy: '多个用,隔开',
  pleaseEnterTheContent: '请输入内容...',
  picture: '图片',
  alignment: '对齐',
  millionInvestmentRatio: '百万投比',
  feedbackNumber: '反馈人数',
  feedbackQuantity: '反馈量',
  timeFormatError: '时间格式错误',
  forma: '{val1}（第{val2}周）',
  expandInformation: '扩展信息',
  totalProportion: '合计占比',
  doNotDisplayNullValues: '不显示空值',
  urbanDistributionN: '城市分布 TOP10',
  provinceDistributionN: '省份分布 TOP10',
  equipmentModelClassification: '设备型号分布 TOP10',
  equipmentManufacturersAreDividedInto: '设备厂商分布 TOP10',
  feedbackDetails: '反馈详情分析',
  aSource: '个来源',
  feedbackSourcesAreDividedInto: '反馈来源分布',
  defaultSource: '默认来源',
  feedbackAnalysisSmall: '反馈分析小结',
  province: '省份',
  equipmentModel: '设备型号',
  versionInformation: '版本信息',
  feedbackSource: '反馈来源',
  feedbackContent2: '反馈内容(二级分类)',
  strip: '条',
  dailyFeedbackVolume: '每日反馈量最高({val1})',
  empty: '<空>',
  topLi: ' {val1} 占比 ({val2})',
  unitM: '{val1} 合计占比({val2}%)',
  focusedOn: '集中于',
  itemt: '【{val1}】涨幅高达{val2}',
  increaseInPrice: '涨幅',
  individual: '个',
  aProvince: '个省',
  class: '类',
  comparedToThePreviousDayOnAMonthOnMonthBasis: '环比前一天',
  pleaseChooseFi2: '请选择{val1}',
  pleaseEnterFi2: '请输入{val1}',
  feedbackType: '反馈类型',
  intelligentClassification: '智能分类',
  modificationFailedE: '修改失败: {val1}',
  setFeedbackWords: '设置反馈字段成功',
  curTa: '{val1}趋势分析',
  curTa2: '{val1}统计',
  parameterSettings: '参数设置',
  feedbackFieldSystem: '反馈字段统计',
  classification: '分类',
  fileN: '{val1}趋势统计.xlsx',
  date: '日期',
  noDataAvailableAtTheMoment: '暂无数据',
  getFieldOutput: '获取字段出错',
  monthOnMonthFluctuations: '环比波动',
  feedbackProportion: '反馈占比',
  fieldValue: '字段值',
  serialNumber: '序号',
  intel: '{val1} 二级分类反馈量',
  thereAreCurrentlyNoTrendNumbersAvailable: '暂无趋势数据',
  intel2: '{val1} 反馈趋势分析',
  startNow: '立即开启',
  proportionOfFeedbackVolume: '反馈量占比',
  feedbackPercentage: '反馈人数占比',
  descendingOrder: '降序',
  ascendingOrder: '升序',
  feedbackClassificationSystem: '反馈分类统计: 一级分类',
  proportion: '占比',
  title: '标题',
  feedbackTypeClassification: '反馈类型分布',
  correspondingToTheSelectedTime:
    '对应所选时间范围，上一相同周期内的告警数变化',
  numberOfAlarms: '告警数',
  convertToOnca: '转oncall',
  replyQuantityReversed: '(已回复量 / 反馈总量) * 100%',
  responseRate: '回复率',
  replyReceived: '已回复',
  turnedTapd: '转tapd',
  correspondingToTheSelectedTime2: '对应所选时间范围，每天平均反馈人数',
  dailyAverageNumberOfPeople: '日均人数',
  dailyAverageFeedbackVolume: '日均反馈量',
  correspondingToTheSelectedTime3:
    '对应所选时间范围，上一相同周期内的反馈量变化',
  analysis: '分析',
  totalFeedbackAmount: '反馈总量',
  inStatisticalAnalysis:
    '在统计分析/报告配置模块中导入DAU数据后，可更新百万投比数值',
  correspondingToTheSelectedTime4:
    '对应所选时间范围，上一相同周期内的反馈人数变化',
  fileT: '{val1}趋势统计.xlsx',
  problemDetails: '问题详情',
  goToUserVoice: '查看更多',
  getDetailsLost: '获取详情失败',
};
