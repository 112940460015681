export default {
  disposalFeedbackLetter: '处置反馈信息',
  batchRemovalQuestion: '批量移出问题',
  removalSuccessful: '移出成功',
  checkAtLeastOne: '至少勾选一条反馈',
  feedbackPercentage: '反馈人数占比：',
  feedbackQuantityAsAPercentage: '反馈数量占比：',
  statisticalTimeWeek: '统计时间周期：',
  alarmRuleCompilation: '告警规则编号',
  alarmTime: '告警时间',
  alarmName: '告警名称',
  start: '({val1} 至 {val2})',
  daysDay: '{val1}天{val2}小时{val3}分钟',
  dataAcquisitionLost: '获取数据失败',
  alarmDetails: '告警详情',
  alarmMonitoring: '监测规则',
  alarmRulesDetailed: '告警规则详情',
  allStatuses: '全部状态',
  statuses: '状态',
  warningRuleName: '输入告警规则名称',
  warningRuleName2: '告警规则名称',
  subscriobeRuleName: '输入主题名称',
  subscriobeRuleName2: '订阅通知主题',
  youCanConfigure:
    '您可以配置一些规则，用于检测特定条件下反馈波动时的风险，符合条件的反馈会自动通知给相关人员，也会出现在【问题跟进】列表中，并且AiSee助手已默认开启了一些异常监测规则',
  people: '人',
  notifyPersonnel: '通知人员',
  notifyTo: '通知给',
  yearOnYear: '同比',
  heavenlyFluctuations: '天波动 >=',
  recentMonthOnMonthComparison: '环比近',
  allFeedbackSystem: '全部反馈统计量',
  goWithUserId: '用户ID去重后的、',
  articleFilteringRules: '条筛选规则;',
  hitIt: '命中',
  meetTheConditions: '满足条件',
  feedbackFrom: '的反馈',
  monitor: '监测',
  fixedFrequency: '固定频次',
  frequencyAndScope: '频次与范围',
  monitoringObject: '监测对象',
  itemcCreate: '{val1} 创建于 {val2}',
  itemcUpdate: '最近更新 {val1}',
  disabled: '已禁用',
  opened: '已开启',
  disabledSuccessfully: '禁用成功',
  successfullyOpened: '开启成功',
  proportionOfNewlyAddedFeedback: '新增反馈占比',
  newFeedbackCount: '新增反馈数',
  noMoreNumbers: '没有更多数据了',
  alarmMonitoringRegulations: '告警规则{val1}个',
  subscribeRegulations: '反馈订阅规则{val1}个',
  notifyGroups: '通知组{val1}个',
  fbSubscribe: '反馈订阅',
  newAlarmMonitoring: '新建告警规则',
  newSubscribeRule: '新建反馈订阅规则',
  newNotifyGroup: '新建通知组',
  editNotifyGroup: '编辑通知组',
  pleaseEnterExtension: '请输入扩展信息',
  afterTheAlarmIsTriggered:
    '满足条件时, 将推送消息至机器人所在的所有企微群',
  pleaseEnterTheGroupMachine: '请输入群机器人webhook地址',
  enterpriseWeChatMachine: '企业微信机器人',
  pleaseEnterTheAlarm: '请输入接收人',
  recipient: '接收人',
  receivingMethod: '接收方式',
  defaultUseOfO:
    '默认使用oncall平台配置的值班人员，如业务未在oncall平台配置值班组，则可以通过修改上述字段进行问题指派。',
  oncal: 'oncall默认值班人员',
  pleaseChooseOn: '请选择oncall子空间',
  alarmTurnedOn: '告警转oncall会先转为问题然后再转为oncall',
  alarmTurnedOn2: '告警转oncall',
  sameTimeYesterday: '天前同一时间段反馈量，波动',
  sameDayAtTheSameTime: '天同一时间段平均反馈量，波动',
  near: '近',
  monthOnMonth: '环比',
  wave: '波动',
  threshold: '阈值',
  mustAtLeastSelect: '(必须至少选择一个)',
  relationshipBetweenConditions: '（条件间关系：必须所有条件同时满足）',
  triggerCondition: '触发条件',
  applicationVersionTo: '应用版本：以反馈信息的应用版本字段为单位分组统计。',
  regionBasedOnReverse:
    '地域：根据反馈信息的IP字段解析出城市，然后以城市为单位分组统计。',
  highFrequencyKeywords:
    '高频关键词：挖掘本检查时间段内反馈频繁出现的关键词，并将相关的词组进行聚合告警。',
  noStatisticsDirectly:
    '无：不统计，直接检查符合筛选条件的反馈数，是否触发“阈值”、“波动”设置。',
  dimension: '维度',
  thisInspectionPeriod: '本检查时段内同一个用户的所有反馈只计1个',
  goWithUserId2: '用户ID去重',
  statisticalDimension: '统计维度',
  relationshipBetweenConditions2: '条件间关系:',
  filterCriteria: '筛选条件',
  theKeyIssueWithin: '内，该关键事件下，新增反馈数',
  lately: '最近',
  testingPeriod: '检测时段',
  detectionFrequency: '检测频率',
  checkTheAlarmTransferOption:
    '勾选“告警转问题”或“告警转oncall”时,需勾选企业微信作为接收方式。',
  pleaseChooseIntelligent: '请选择智能标签',
  theMinimumValueIs: '最小值为1',
  pleaseInputTheFluctuation: '请输入波动涨幅',
  pleaseInputTheFluctuation2: '请输入波动天数',
  pleaseEnterTheThresholdValue: '请输入阀值数量',
  addAlarmRules: '新增告警规则成功',
  addAlarmRules2: '新增告警规则失败',
  pleaseCheckTheTriggerBox: '请勾选触发条件',
  customFields: '自定义字段异常, 请稍后再试',
  addFilterBar: '添加筛选条件',
  pleaseSelectKnown: '请选择已知项',
  pleaseFillInTheFields: '请填写字段数值',
  filterRelationships: '筛选关系',
  pleaseSelectFilter: '请选择筛选关系',
  feedbackField: '反馈字段',
  pleaseProvideFeedback: '请填写反馈字段',
  alarmRules: '告警规则',
  addAlarmRules3: '新增告警规则',
  editAlarmRules: '编辑告警规则',
  addSubscribeRules: '新增订阅规则',
  editSubscribeRules: '编辑订阅规则',
  newAlarmRule: '新建告警规则',
  generatedProblem: '已生成问题',
  forma: '({val1} 至 {val2})',
  forma2: '({val1} 至 {val2})',
  suspended: '已挂起',
  negotiatedAndResolved: '已协商解决',
  closed: '已关闭',
  potential: '潜在',
  transferredToOnc: '已转oncall',
  transferredTap: '已转tapd',
  intelligentMonitoring: '智能监测',
  manualConversion: '手动转单',
  alarmRuleSetting: '告警规则设置',
  byCreatingRegulations:
    '通过创建规则监控反馈的变化，如针对反馈量的波动或某一问题的激增等场景。',
  fbSubscribeInfo: '若您需要将符合特定条件的反馈推送通知，请设置订阅规则.',
  theSystemWillCommunicateWith: '系统会向通知人发送1条企微消息',
  selectNotifier: '选择通知人',
  pictureAndVideo: '图片/视频',
  clickToUpload: '点击上传',
  upToAttachments: '附件 最多上传五张图片, 图片最大10M',
  pleaseEnterFollowUpInformation: '请输入跟进信息描述',
  problemStatus: '问题状态',
  newQuestionsAnd: '新增问题跟进节点',
  networkAbnormalityPlease: '网络异常, 请稍后再试: {val1}',
  addFollowUpSection: '添加跟进节点成功',
  system: '系统',
  tapdSheet: 'TAPD单已处理',
  problemTransferToTa: '问题转TAPD',
  oncal2: 'Oncall单已处理',
  problemTurnedOn: '问题转Oncall',
  createAProblem: '创建问题',
  problemUpdate: '问题更新',
  followUpStatusUpdates: '跟进状态更新后, 已关联反馈同步更新',
  followUpRecords: '跟进记录(',
  problemFollowUpRecord: '问题跟进记录',
  itemc2: '{val1} 信息修改',
  hopeToWatchTogether:
    '希望一起看功能也可以加入弹幕功能。邀请50人领取1000B，品示领取了可我的福利里面也不品示。',
  mergedItems: '已归并 17 条同规则问题',
  people2: '40人',
  strip: '48条',
  failedToClaim: '领取失败, 领完了没给',
  problemConsolidationRecord: '问题归并记录',
  followUpRecords2: '跟进记录',
  convertToTapd: '转Tapd',
  openTap: '打开Tapd',
  updateInformationTo: '更新信息到Oncall',
  transferToOnca: '转Oncall',
  openOnc: '打开Oncall',
  followUp: '跟进',
  convertedToOnc: '已转ONCALL',
  transferredToTap: '已转TAPD/ONCALL',
  sameRuleIssue: '同规则问题',
  merged: '已归并',
  hitHighFrequencyLevel: '命中高频关键词',
  to: '(2023-09-07 10:40:00 至 2023-09-07 11:40:00)',
  branch: '分',
  statisticalCycle: '统计周期',
  start2: '({val1} 至 {val2})',
  feedbackInformationSystem: '反馈信息统计',
  creationTimeF: '创建时间 {val1}',
  feedbackProcessing: '反馈处理',
  updateOnc: '更新ONCALL信息成功',
  modifyTheProcessor: '修改处理人失败, 请稍后再试',
  modifyTheProcessor2: '修改处理人成功',
  networkAbnormalityPlease2: '网络异常, 请稍后再试!',
  removeProblem: '移除问题',
  inTheFeedbackList: '在反馈列表中查看',
  relation: '关联',
  removeAssociationReverse: '移除关联反馈成功!',
  pleaseSelectAtLeast: '请至少选择一个反馈',
  allQuestionsEtc: '全部问题等级',
  sourceOfProblem: '问题来源',
  allProcessingParties: '全部处理方式',
  problemHandling: '问题处理',
  enterTheQuestionName: '输入问题名称',
  problemName: '问题名称',
  creationTimeF2: '创建时间 {val1}',
  networkAbnormalityPlease3: '网络异常, 请稍后再试: {val1}',
  thisProjectDoesNotHave: '该项目没有绑定Tapd',
  selectedTo: '已筛选{val1}条数据 {val2}',
  theCurrentIssueBelongsTo: '(当前问题属于告警: {val1})',
  replyStatistics: '回复统计',
  processingStatistics: '处理统计',
  exportData: '导出数据',
  organizationalStructureMaintenance: '组织架构维度数据',
  organizationalStructureMaintenance2: '组织架构维度数据({val1}~{val2})',
  userResponseStatistics2: '客服回复后用户响应统计({val1}~{val2})',
  organizationalStructureMaintenance3:
    '组织架构维度数据 \\r\n{val1} \\r\n{val2}',
  userResponseStatistics:
    '客服回复后用户响应统计 \\r\n{val1} \\r\n{val2}',
  dataTimeS: '数据时间: {val1} ~ {val2}',
  operatorSt: '操作人: {val1}',
  numberOfConversations: '对话次数',
  automaticReplyVolume: '自动回复量',
  numberOfManualReplies: '人工回复量',
  responseVolume: '回复量',
  organizationalStructure: '组织架构',
  query: '查询',
  displayAfterActivation: '开启后, 显示所选分类包括子分类',
  theCurrentProductIsNotAvailable:
    '当前产品没有智能分类,请前往系统设置确认是否已绑定智能分类模型!',
  intelligentClassificationDimension: '智能分类维度数据',
  intelligentClassificationDimension2: '智能分类维度数据({val1}~{val2})',
  intelligentClassificationDimension3:
    '智能分类维度数据 \\r\n{val1} \\r\n{val2}',
  dataTimeS2: '数据时间: {val1} ~ {val2}',
  operatorSt2: '操作人: {val1}',
  automaticReplyRate: '自动回复率',
  manualResponseRate: '人工回复率',
  feedbackResponseRate: '反馈回复率',
  smartTags: '智能标签',
  automaticReplyVolume2: '自动回复量/反馈量',
  numberOfManualReplies2: '人工回复量/反馈量',
  feedbackOnReplyVolume: '回复量/反馈量',
  dataOverview: '数据概览',
  calculationFormulaF: '计算公式:{val1}',
  customParameters: '自定义参数统计',
  userDataSystem: '用户数据统计',
  faqStatistics: 'FAQ统计',
  total: '合计',
  intelligentClassificationName: '智能分类名称',
  export: '导出',
  feedbackClassificationSystem: '反馈分类统计',
  exportFailedPlease: '导出失败,请检查网络是否正常!',
  selectedCategory: '已选分类:',
  accordingToAiS:
    '根据AiSee所提供的统计图表组件，可设置自定义分析报告，并实现邮件的定期推送',
  reportConfiguration: '报告配置',
  statisticsOfVariousApplications: '统计各应用版本反馈量的分布与趋势变化',
  versionTrendSystem: '版本趋势统计',
  forFeedbackPurposes:
    '针对反馈用户的特征分析，含系统版本、机型、地域、网络4个统计维度',
  canViewDifferent:
    '可查看不同反馈分类对应的回复情况，以及各运营人员的回复量指标',
  statisticalFaq: '统计FAQ的赞踩数据指标',
  selectTheOneYouWantToClose:
    '选择您所关注的自定义参数，查看所有参数值的反馈量和变化趋势',
  viewAll: '可查看所有层级反馈分类的数量统计，及各分类的变化趋势',
  createABlankReport: '创建空白报告',
  selectReportTemplate: '选择报告模版',
  productScreening: '产品筛选',
  numberOfDataEntries: '数据条数',
  customParameters2: '自定义参数',
  statisticalMethod: '统计维度',
  expandSubcategories: '展开子分类',
  dynamicTime: '动态时间',
  staticTime: '静态时间',
  cycleTime: '时间周期',
  theSelectedTimeIs: '已选时间为:{val1} - {val2}',
  productFullPath: '产品全路径: {val1}',
  chartTrendModel: '图表趋势模版',
  deleteInvalidReverse: '删除无效反馈',
  statisticalDetails: '统计详情',
  afterOpeningItWillDisplay: '开启后, 则显示叶子结点的详情数据',
  andTheDecrease: '且降幅 ≥',
  feedbackQuantity: '反馈量 ≥',
  fallingFast: '下降快',
  andTheIncrease: '且增幅 ≥',
  fastGrowth: '增长快',
  allTheSounds: '%的所有声音',
  sumOfFeedbackQuantities: '反馈量之和 ≥',
  manyFeedbacks: '反馈多',
  highlightProblemScreening: '突出问题筛选',
  userClassification: '用户分类',
  selectClassificationSystem: '选择分类统计方式',
  productFullPath2: '产品全路径: {val1}',
  theSelectedTimeIs2: '已选时间为:{val1} - {val2}',
  textTemplate: '文本模版',
  pleaseEnterTheGroupMachine2:
    '请输入群机器人webhook地址列表, 多个请用英文分号 ; 分割',
  enterpriseWeChatMachine2: '企业微信机器人:',
  openEnterpriseMicro: '开通企业微信通知后, 发送邮件时会同步发送企业微信通知',
  enterpriseWeChatNotification: '企微通知',
  sendEmail: '发送邮箱',
  manual: '手动',
  timing: '定时',
  sendingFrequency: '发送频率',
  pleaseEnterEmail: '请输入邮件标题',
  inTheConfigurationReport: '配置报告内容',
  sunday: '周日',
  saturday: '周六',
  friday: '周五',
  thursday: '周四',
  wednesday: '周三',
  tuesday: '周二',
  monday: '周一',
  day: '日',
  week: '周',
  iScore: '{val1}分',
  iScore2: '0{val1}分',
  atITime: '{val1}时',
  atITime2: '0{val1}时',
  optionalStatisticsGroup: '可选统计组件',
  responseRateTrend: '回复率趋势表',
  parameterTrendChart: '参数趋势图',
  parameterMonthOnMonthTrend: '参数环比趋势表',
  millionInvestmentRatioTrend: '百万投比趋势图',
  millionInvestmentRatioTable: '百万投比表格',
  intelligentClassificationAccountsFor: '智能分类占比分布图',
  intelligentClassificationAndIntegration: '智能分类整体趋势表',
  intelligentLabelTrend: '智能标签趋势表',
  feedbackQuantityTrend: '反馈数量趋势图',
  feedbackOnMonthOnMonthTrend: '反馈环比趋势表',
  textContent: '文本内容',
  numberOfClassifiedFeedback: '分类反馈数据表格',
  addComponentsAs: '添加组件成功',
  selectedComponentColumn:
    '已选组件列表,先点击“编辑”配置数据,然后点击“预览报告”查看真实数据',
  saveReport: '保存报告',
  manuallySend: '手动发送',
  previewReport: '预览报告',
  return: '返回',
  previousStep: '上一步',
  createReport: '创建报告',
  pleaseSelectAtLeast2: '请至少选择一个统计组件',
  pleaseFillInTheEmail: '请填写邮件发送邮箱',
  pleaseFillInTheEmail2: '请填写邮件标题',
  serverException: '服务器异常, 请稍后再试!',
  createdSuccessfully: '创建成功',
  manuallySendMail: '手动发送邮件失败!',
  manuallySendingReports: '手动发送报告成功!',
  serverException2: '服务器异常, 请联系管理员',
  monthI: '{val1}月',
  excel: 'Excel数据',
  thereIsDataAvailable: '有数据',
  edit: '编辑',
  confirmCancellationOfEditing: '确定取消编辑吗?',
  preservation: '保存',
  operation: '操作',
  dataValue: '数据值',
  analysisResults: '解析结果',
  exhibition: '展示',
  putItAway: '收起',
  downloadTemplate: '下载模版',
  maximumSingleSupport: '单次最大支持导入 5000 条数据',
  supportCommonlyUsedX: '支持常用xlsx/xls/cvg格式',
  dragAndDropToThisArea: '/拖拽到此区域',
  reUpload: '重新上传',
  pleaseUploadTheCorrespondingFile: '请上传对应的Excel文件',
  pleaseChooseEx: '请选择Excel对应的智能分类',
  uploadExc: '上传Excel数据',
  tableDateCount: '表格日期数据不规范, 请修改后上传',
  thereAreBlankLinesPresent: '存在空白行, 或行格式错误',
  tableDataError: '表格数据错误, 请上传正确的数据',
  uploadSuccessful: '上传成功',
  pleaseUploadTheFile: '请上传文件',
  dataDisplay: '数据展示',
  dataUpload: '数据上传',
  newReport: '新建报告',
  pleaseEnterTheReport: '请输入报告名',
  reportName: '报告名',
  viewReport: '查看报告',
  myReport: '我的报告',
  allReports: '全部报告',
  deleteReport: '删除报告',
  deleteFailed: '删除失败!',
  deleteSuccessfully: '删除成功!',
  areYouSureYouWantToDeleteIt: '你确定要删除{val1}',
  copyFailedPlease: '复制失败，请稍后重试~',
  copySuccessful: '复制成功！',
  dataExceptionPlease: '数据异常, 请联系管理员',
  networkAnomalyCutting: '网络异常: 切换推送开关失败,请刷新页面重试!',
  switchPushOn: '切换推送开关成功!',
  delete: '删除',
  confirmToClosePush: '确定关闭推送?',
  confirmToStartPushing: '确定开启推送?',
  pushSwitch: '推送开关',
  whenSendingNextTime: '下次发送时间',
  lastTimeItWasSent: '上次发送时间',
  modifiedBy: '修改人',
  creator: '创建人',
  mail: '邮件',
  sendingChannel: '发送渠道:',
  sentClick: '] 已发送, 点击可参看详情',
  historicalRecords: '历史记录',
  sendingFrequency2: '发送频率:',
  reportTitle: '报告标题:',
  reportDetails: '报告详情',
  obtainExecutionLetter: '获取执行信息失败, 请稍后再试',
  thereAreCurrentlyNoChartNumbersAvailable: '暂无图表数据, 请选择筛选条件',
  intelligentClassificationReverse: '智能分类反馈占比',
  feedbackVolumeComparedToThePreviousPeriod: '反馈量环比',
  dailyAverageOfThePreviousCycle: '上周期日均反馈量',
  feedbackFromThePreviousCycle: '上周期反馈量占比',
  feedbackFromThePreviousCycle2: '上周期反馈人',
  feedbackFromThePreviousCycle3: '上周期反馈量',
  feedbackPerson: '反馈人',
  monthOnMonthData: '环比数据',
  lastCycle: '上周期',
  thisCycle: '本周期',
  underFilteringConditions: '筛选条件: 下降快 (反馈量 ≥',
  filterCriteriaIncrease: '筛选条件: 增长快 (反馈量 ≥',
  allTheSounds2: '%的所有声音)',
  filterConditionReverse: '筛选条件: 反馈多 (反馈量之和 ≥',
  product: '产品:',
  responseRate: '回应率',
  responseQuantity: '已回应数量',
  feedbackFromThePreviousPeriod: '上期反馈数量',
  classificationName: '分类名称',
  feedbackPersonFromThePreviousIssue: '上期反馈人数',
  noDataAvailablePlease: '暂无数据, 请选择筛选条件',
  millionInvestmentRatio: '百万投比环比',
  value: '{val1} 次',
  value2: '{value} 次',
  responseRateStatistics: '回复率统计表',
  iAmATextCharacter:
    '我是文本字段的解释内容，我是文本字段解释内容，我是文本字段的解释内容，我是文本字段解释内容我是文本字段的解释内容，我是文本字段解释内容',
  textTitle: '文本标题',
  whole: '整体',
  dailyAverageOfThePreviousCycle2: '上周期日均反馈人',
  dailyFeedbackPerson: '日均反馈人',
  product2: '产品',
  editReport: '编辑报告',
  reportPreview: '报告预览',
  classificationChart: '分类图表',
  lastS: '{val1} (定时)',
  sendingTime: '发送时间:',
  keyWord: '关键词',
  aiSceneMining: 'AI场景挖掘',
  userVoiceColumn: '用户声音列表',
  userVoiceDetails: '用户声音详情',
  byHeat: '按热度',
  accordingToTheSoaringTrend: '按飙升',
  pleaseChooseAnAction: '请选择操作符',
  numberOfFeedbacks: '反馈条数',
  aiSee:
    'AiSee助手根据反馈内容自动归纳总结用户声音，您可以查看用户声音的变化趋势，也可以转化为问题做跟进处理 (如反馈量突增、针对改版特性等一些用户声音)',
  correspondingTimeSheet:
    '对应时间单位内匹配该主题的反馈量/该单位时间内的反馈总量*100%',
  correspondingTimeSheet2: '对应时间单位内反馈内容的语义匹配该主题的{val1}',
  typen2: '{val1}变化趋势',
  articleData: '条数据',
  selected: '已筛选',
  fixed: '已修复',
  pending: '后续优化',
  notReproduce: '无法复现保持观察',
};
