export default {
  "history": "History",
  "theImageCannotExceed": "Image cannot exceed {val1}MB, please re-upload!",
  "thereAreTypesOfImagesAvailable": "Invalid image type, please upload a correct format!",
  "richTextPureText": "Rich text/plain text toggle",
  "richTextContent": "Rich text content (bold, images, etc.)",
  "discard": "Discard",
  "cutFromRichText": "Switching from rich text to plain text will",
  "beCareful": "Note:",
  "numberOfRichTexts": "Parsing rich text data structure.",
  "forTheNumberOfUses": "Use a plain text editor for FAQ data; otherwise, you need to",
  "switchToPureText": "Switching to plain text mode will discard rich text info (images, etc.)",
  "reply": "Reply:",
  "articleNbsp": "Entries:",
  "feedback": "Feedback",
  "replyDetails": "Reply details (",
  "nbspAnti": " Feedback",
  "batchReplyAs": "Batch reply successful, replied to ",
  "batchSending": "Batch sending (",
  "failInSend": "Send failed",
  "replyPlan": "Reply plan:",
  "open": "Expand",
  "batchSelection": "Selected batch feedback",
  "replyToRecommendedStudents": "Reply to recommended generated reply plans",
  "pleaseInTheLeftColumn": "Please select feedback on the left list and",
  "thereAreCurrentlyNoPlansAvailable": "No available plans",
  "replyAdded": "Reply plan added (",
  "addToReply": "Add to reply (",
  "addedBack": "Added back to reply",
  "adopt": "Adopt",
  "intelligentReplyPush": "Intelligent reply recommendations",
  "automaticFilteringHasBeenImplemented": "Auto filter replied",
  "batchSelected": "Selected",
  "aiRecommendationBack": "AI recommended reply",
  "onlySelectable": "Only selectable items with permissions; contact admin for project info.",
  "pleaseSelectProject": "Select a project.",
  "changeOfProjectOwnership": "Changing ownership clears smart classification, user feedback, and source info.",
  "batchChangeOffice": "Batch change application ownership.",
  "networkAbnormalityPlease": "Network error, try again later.",
  "operationSuccessful": "Operation successful.",
  "pleaseSelectProject2": "Select a project!",
  "theImageCannotExceed2": "Image must not exceed {val1}MB; please re-upload!",
  "pleaseSelectTheOriginalFirst": "Select the original feedback source first.",
  "firstLevelClassification": "First-level classification.",
  "pleaseChooseTheOriginalAndReverse": "Select the original feedback source.",
  "originalFeedbackSource": "Original feedback source.",
  "supportFromExistingSources": "Select first-level classification from existing sources; copy all data to the target source.",
  "pleaseSelectThePurpose": "Select the purpose feedback source.",
  "objectiveFeedback": "Purpose feedback source.",
  "processingInProgress": "Processing.",
  "supportFromExistingSources2": "Select first-level classification from existing sources; copy all data to the target source.",
  "successCopy": "Copy successful.",
  "sourceFeedbackSource": "Source and purpose feedback sources cannot be the same.",
  "pleaseSelectTheSource": "Select source ID.",
  "enterMultipleValues": "Enter multiple values separated by commas.",
  "pleaseChooseCustom": "Please select custom fields",
  "editorAndProcessor": "Editor and processor",
  "pleaseEnterTi": "Please enter {val1}!",
  "more": "More",
  "pleaseEnterSearch": "Please enter search content (press enter to search)",
  "uploadingInProgress": "Uploading...",
  "theImageCannotExceed3": "Image cannot exceed {val1}MB, please re-upload!",
  "returnToOtherQuestions": "Return to other questions",
  "sendOut": "Send",
  "pleaseEnterATitle": "Please enter title for search",
  "addTheFaq": "Reply FAQ content to user",
  "setAnalysisDimension": "Set analysis dimension successfully",
  "setAnalysisDimension2": "Set analysis dimension",
  "stopGenerating": "Stop generating",
  "noHelp": "No help",
  "toBeHelpfulTo": "Helpful",
  "evaluatedHelpless": "Evaluated: No help",
  "reviewedAsHelpful": "Evaluated: Helpful",
  "copy": "Copy",
  "regenerate": "Regenerate",
  "terminationSuccessful": "Termination successful",
  "requestError": "Request error:",
  "helpingMeWrite": "Helping me write...",
  "aiGenerated": "AI-generated content is for reference only; you can modify as needed.",
  "abandoned": "Abandoned",
  "quote": "Quote",
  "successfullyGenerated": "Successfully generated",
  "connectionInterruption": "Connection interrupted",
  "chooseTheRequiredSystem": "Choose the system for analysis generation:",
  "currentlyNotGenerated": "No generated log analysis available",
  "stopAnalyzing": "Stop analyzing",
  "outlierExtraction": "Outlier extraction",
  "summarizeTheTimeline": "Summarize timeline",
  "logAnalysisConclusion": "Log analysis results",
  "analyzeProgress": "Analysis progress",
  "pleaseEnterAsk": "Enter your question and press Enter to trigger the query.",
  "logChatAskTips": "You can ask any questions to the model based on log files. The backend will automatically transmit [Feedback Content], [Feedback Time], and filtered [Log Content].\nFor example: Please output logs related to the feedback content." ,
  "logFile": "Log file",
  "analyzingLogFile": "Analyzing log file, please wait...",
  "pleaseInputKeyWords": "keyword search requires ",
  "pleaseSelectFirst": "Non global search requires selecting a file first",
  "confirm": "Confirm",
  "time": "Time",
  "loadingInProgress": "Loading",
  "logAnalysisCompleted": "---- Log analysis completed",
  "logAnalysisAccuracy": "---- Log analysis preparing, please wait",
  "problemAnalysisSmall": "Analysis summary",
  "logAnalyzeTabForAnalysis": "analysis",
  "logAnalyzeTabForAsk": 'ask me',
  "aggus": "{val1} people",
  "aggco": "{val1} entries",
  "value": "{val1} feedback users proportion: {val2}",
  "value2": "{val1} feedback users: {val2}",
  "value3": "{val1} feedback volume proportion: {val2}",
  "feedbackQuantityAg": "Feedback volume: {val1}",
  "unknownState": "Unknown state",
  "waitingForReply": "Awaiting reply",
  "setUpAListExhibition": "Set up list display fields and sorting",
  "nothing": "None",
  "sourceLink": "Source link:",
  "feedbackClassificationN": "Intel classification: ",
  "feedbackUserLetter": "Feedback user info",
  "state": "State",
  "totalTota": "Total: {val1} entries",
  "creationTime": "Creation time",
  "oncal": "Oncall ticket",
  "transferred": "Transferred",
  "transferredToOnc": "Transferred to Oncall ticket",
  "transferredToTap": "Transferred to TAPD",
  "stay": "Pending",
  "already": "Already",
  "unrecognized": "Unrecognized",
  "toBeFollowedUpOn": "To be followed up",
  "unknown": "Unknown",
  "viewAll": "View all",
  "recentlyUsersHaveBeenAgainst": "Recent user feedback (",
  "pleaseEnterFloatingPoint": "Please enter a floating point number",
  "pleaseEnterPlasticSurgery": "Please enter a surgery number",
  "theNumberOfWordsInTheRemarksIsNot": "Remarks must not exceed 400 characters",
  "changeFailed": "Change failed",
  "changeSuccessful": "Change successful",
  "processingPersonnel": "Processing personnel",
  "errorInType": "Type error",
  "jumpLink": "Jump link",
  "pleaseEnterTheField": "Please enter field name for search",
  "addFilter": "Add filter",
  "exportOption": "Export options",
  "pleaseChooseWhetherOrNot": "Please select whether empty",
  "pleaseEnter": "Please enter",
  "forEmpty": "Empty",
  "notEmpty": "Not empty",
  "contain": "Contains",
  "selectedFieldsTo": "Selected fields and sorting (",
  "noFieldsAvailableAtTheMoment": "No fields available at the moment",
  "searchFields": "Search fields",
  "exportFieldSelection": "Export field selection (up to 100,000 entries)",
  "advancedQuery": "Advanced query",
  "exportSuccessful": "Export successful!",
  "exportFailedPlease": "Export failed, please try again later",
  "weAreGuidingYou": "Exporting for you, please wait...",
  "feedbackDetails": "Feedback details",
  "imageContent": "Image content",
  "picturesLinking": "Image links",
  "pleaseChoose": "Please select",
  "setListDisplay": "Set list display fields",
  "aField": "Field",
  "nbHasBeenSelected": "Selected: ",
  "usedForAnalyzingACertain": "Used for analyzing certain parameters (including custom fields) value distribution or month-on-month change; effective after viewing in [Feedback Parameter Statistics]",
  "userAccount": "User account",
  "noRelevantIntelligenceAvailableAtTheMoment": "No relevant intelligent classification available!",
  "searchIntelligenceScore": "Search intelligent classification",
  "selectedCategoryT": "Selected category: {val1}",
  "feedbackClassification": "Intel classification",
  "feedbackInfo": "Feedback Info",
  "pleaseEnterTheType": "Please enter type name for search",
  "pleaseChooseIntelligent": "Please select intelligent classification",
  "userSelectedScore": "User-selected classification",
  "pleaseChooseACategory": "Please select a category",
  "pleaseEnterTheField2": "Please enter field value",
  "multipleUsagePoints": "Multiple uses, separated",
  "pleaseChooseARating": "Please select rating option",
  "pleaseSelectAQuestionnaire": "Please select a questionnaire",
  "allCategories": "All",
  "versionFeedbackVolume": "Version feedback volume",
  "value4": "{val1} people",
  "value5": "{val1} entries",
  "feedbackTypeN": "Feedback type: {val1}<br/> Feedback volume: {val2}<br/> Proportion: {val3}",
  "feedbackTypeN2": "Feedback type: {val1}<br/> Proportion: {val2}%",
  "feedbackQuantity": "Feedback quantity",
  "statisticalDimension": "Statistical dimension:",
  "monthly": "Mo.",
  "byWeek": "We.",
  "onADailyBasis": "Da.",
  "statisticalMethod": "Statistical method:",
  "notSelectedAtAll": "None selected",
  "selectAll": "Select all",
  "value6": "{value} people",
  "value7": "{value} entries",
  "fileT": "{val1} trend statistics.xlsx",
  "intelligentClassificationTrend": "Intelligent classification trend.xlsx",
  "exportExc": "Export Excel",
  "close": "Close",
  "dataView": "Data view",
  "intelligentClassificationTrend2": "Intelligent classification trend chart",
  "viewTrends": "View trends",
  "trend": "Trend",
  "numberOfFeedbackUsers": "User feedback count month-on-month",
  "feedbackFromThePreviousCycle": "Previous cycle feedback user count",
  "feedbackOnUserProportion": "User Proportion",
  "numberOfFeedbackUsers2": "Feedback user count",
  "feedbackOnAMonthOnMonthBasis": "Feedback count month-on-month",
  "feedbackFromThePreviousCycle2": "Previous cycle feedback count",
  "feedbackQuantity2": "Feedback count",
  "thePicturesAndVideosHaveBeen": "Images/videos are corrupted",
  "addPicturesAndVideos": "Loading images/videos...",
  "imagePreview": "Image preview",
  "videoPreview": "Video preview",
  "nbspIntelligence": " Intelligent classification trend chart",
  "pleaseInputAnImage": "Please enter image link",
  "multilingual": "Multilingual",
  "optionLabelName": "(Option/Label) Name",
  "pleaseClickOnMultilingual": "Please click the multilingual button to enter name",
  "modifyOptionLabel": "Modifying (Option/Label) will affect all questionnaires using this (Option/Label), please proceed with caution",
  "modifiedSuccessfully": "Modified successfully",
  "pleaseEnterAName": "Please enter a name or image link",
  "typel": "{val1} name",
  "pleaseEnterAName2": "Please enter a name",
  "serverException": "Server exception, please try again later",
  "title": "{val1} - Multilingual",
  "spanish": "Spanish",
  "portuguese": "Portuguese",
  "arabic": "Arabic",
  "korean": "Korean",
  "japanese": "Japanese",
  "malayLanguage": "Malay",
  "hindiLanguage": "Hindi",
  "indonesia": "Indonesian",
  "vietnamese": "Vietnamese",
  "thai": "Thai",
  "traditionalChinese": "Traditional Chinese",
  "pleaseEnterSimplifiedChinese": "Please enter simplified Chinese",
  "simplifiedChinese": "Simplified Chinese",
  "pleaseEnterEnglish": "Please enter English content",
  "englishFallback": "English (fallback)",
  "uploadLogsSeparately": "Upload log analysis",
  "journal": "Log",
  "recordingFrequency": "Recording frequency",
  "experimentalData": "Experimental data",
  "switch": "Switch",
  "sdkLogs": "SDK logs",
  "chooseJapaneseChronicle": "Select log file",
  "pleaseSelectALog": "// Please select a log file",
  "selectTime": "Select time",
  "selectDate": "Select date",
  "onlyLeavesCanBeSelected": "Only leaf nodes can be selected",
  "feedbackClassificationSelection": "Intel classification selection",
  "pleaseEnterToReply": "Please enter reply content (press Enter to send, Ctrl+Enter for new line)",
  "viewDetails": "View details >",
  "comingSoon": "Coming soon",
  "feedbackFailed": "Feedback failed",
  "feedbackSuccessful": "Feedback successful",
  "resultsOfEnforcement": "Results of enforcement",
  "tipsLimit": "Tips: Limit feedback trigger count per second, max 500 feedbacks per second",
  "frequencyLimit": "Frequency limit",
  "pleaseSelectAPlugin": "Please select a plugin",
  "implement": "Implement",
  "batchTriggerInsertion": "Batch trigger plugin",
  "pluginExecutionLost": "Plugin execution failed: {val1}",
  "see": "See",
  "open2": "Open",
  "transferredToOnc2": "Transferred to Oncall",
  "addToQuestion": "Add to question",
  "feedbackFollowUpOperation": "Feedback follow-up operation successful",
  "enterEnterpriseMicro": "Enter enterprise WeChat English name, separated by semicolon",
  "pleaseCheckAr": "Please check if {val1} name is correct",
  "currentlySelected": "Currently selected product:",
  "pleaseSelectAProduct": "Please select a product",
  "project": "Pro",
  "pleaseEnterTheProduct": "Please enter product keyword",
  "invalidIssue": "Invalid issue",
  "confirmedIssue": "Confirmed issue",
  "notConfirmed": "Not confirmed",
  "pleaseChooseToModify": "Please select modification status",
  "pleaseInputProcessing": "Please enter processor",
  "noteMultipleTreatments": "Note: Multiple processors separated by English semicolon",
  "pleaseInputProcessing2": "Please enter processor!",
  "pleaseEnterTheQuestion": "Please enter issue description",
  "describe": "Description",
  "pleaseEnterADescription": "Please enter description!",
  "pleaseEnterTheQuestion2": "Please enter issue title",
  "pleaseEnterATitle2": "Please enter title!",
  "editingIssues": "Editing issues",
  "modifyTheProblemToBeLost": "Failed to modify issue, please try again later",
  "modifyTheProblemTo": "Issue modified successfully",
  "multipleProcessors": "Multiple processors separated by English semicolon",
  "synchronizeUpdates": "Synchronize updates",
  "questionTitle": "Issue title",
  "problemLevel": "Issue level",
  "addToExisting": "Add to existing issue (can link to TAPD)",
  "newProblem": "New issue",
  "articleFeedback": "Entries of feedback",
  "selected": "Selected",
  "afterAddressingTheIssueItCanBeResolved": "After addressing, view in [Issue Follow-up] and transfer if added.",
  "pleaseClarifyTheMeaningOfTheSentence": "Categorize consistent feedback as issues for follow-up in [Issue Follow-up].",
  "thisProjectDoesNotHave": "This project is not bound to TAPD!",
  "followUpOnTransferringIssues": "Transfer issue follow-up",
  "bindTap": "Binding TAPD successful",
  "bindTap2": "Binding TAPD failed: err: {val1}",
  "relatedIssuesLost": "Failed to associate issue",
  "relatedIssuesBecome": "Successfully associated issue",
  "relatedIssuesLost2": "Failed to associate issue, please try again later: {val1}",
  "addAsProblem": "Add as issue",
  "addAsProblemAndCopy": "Add as issue and copy",
  "transferProblemAndCopy": "transfer as issue and copy",
  "addAndConvertToO": "Add & Oncall",
  "addAndConvertToT": "Add & TAPD",
  "theTitleCannotBe": "Title cannot be empty",
  "theHandlerCannot": "Processor cannot be empty",
  "withdrawalMessageLost": "Withdraw message failed, please refresh the page and try again!",
  "withdrawTheMessage": "Withdraw message successful!",
  "replySuccessful": "Reply successful",
  "turn": "Transfer",
  "batchManualReturn": "Batch manual reply",
  "reply2": "Reply",
  "pleaseSelectAtLeast": "Please select at least one record",
  "userInitiated": "User rating initiated, awaiting user response",
  "thisServices": "Satisfaction evaluation of this service",
  "unknownUser": "Unknown user",
  "withdraw": "Withdraw",
  "continue": "Continue",
  "whetherToWithdrawI": "Withdraw: 【{val1}】, user will not see this reply after withdrawal",
  "withdrawMessage": "Withdraw message",
  "confirmThisFeedback": "Confirm this feedback has been processed and initiate user rating?",
  "initiateSatisfaction": "Initiate satisfaction rating",
  "alreadyInitiated": "Rating has already been initiated, please check user rating results!",
  "userInitiated2": "User rating initiated, awaiting user response!",
  "quickReply": "Quick reply",
  "originalFeedbackReply": "Original feedback reply details",
  "noViewOriginal": "No permission to view original",
  "aiSee": "AiSee recommends these reply options:",
  "uploadFailedPlease": "Upload failed, please try again later",
  "uploadImage": "Upload image",
  "pleaseEnterAReply": "Please enter reply content or upload an image",
  "pictureUploadingInProgress": "Image uploading...",
  "pleaseEnterCo": "Please enter {val1}, separate multiple values with commas or spaces",
  "pleaseSelectProcessing": "Please select processing status",
  "multipleOnCs": "Multiple oncall assignees separated by semicolon ';', priority given to the assignees listed here (if empty, assign to oncall platform's configured personnel)",
  "oncal2": "Oncall assignees",
  "configurationMethod": "Configuration method",
  "pleasePrepareInAdvanceInO": "Please configure the corresponding rules in the oncall platform in advance; otherwise, the ticket creation will fail.",
  "oncal3": "Oncall business category",
  "oncal4": "Oncall operational product",
  "newOnc": "Create new ONCALL ticket",
  "oncal5": "Oncall transfer failed, please re-enter",
  "noOperationalAuthority": "No operational authority, please apply for permission before proceeding.",
  "oncal6": "Oncall transfer failed, please retry the transfer",
  "oncal7": "Oncall transfer successful",
  "sReco": "{val1} feedback pending",
  "pleaseChooseOn": "Please select oncall operational product",
  "proposal": "Suggestion",
  "tips": "Tips",
  "commonly": "Common",
  "serious": "Serious",
  "deadly": "Critical",
  "empty": "--Empty--",
  "beOfNoGreatImportance": "Minor importance",
  "low": "Low",
  "in": "Medium",
  "high": "High",
  "urgent": "Urgent",
  "newToTa": "Create transfer to Tapd",
  "addComments": "Add comments",
  "pleaseChooseOne": "Please select a handler",
  "configureOtherT": "Configure other Tapd projects",
  "convertToTapd": "Transfer to Tapd encountered an exception, please check the transfer results in the Tapd space!",
  "convertToTapd2": "Transfer to Tapd demand ticket successful!",
  "convertToTapd3": "Transfer to Tapd defect ticket successful!",
  "demand": "Demand",
  "waitingForTheFeed": "Waiting for {val1} feedback",
  "pleaseSelectProcessing2": "Please select handler",
  "pleaseChooseATitle": "Please select a title",
  "pleaseSelectType": "Please select a type",
  "pleaseSelectNew": "Please select the new Tapd space to create in",
  "viewDetails2": "View details",
  "managementBackendCheck": ">>> Check issue details in the management backend: ",
  "divst": "<div style=\"display: flex\">Terminal log: <div style=\"display: inline-block\">{val1}</div></div>",
  "ahref": "<a href=\"{val1}\" style=\"cursor: pointer;\">Log info {val2}</a><br/>",
  "isItRoo": "Is it Root: No",
  "isItRoo2": "Is it Root: Yes",
  "networkFee": "Network: {val1} <br/><br/>",
  "modelFee": "Model: {val1} <br/><br/>",
  "manufacturerFee": "Manufacturer: {val1} <br/><br/>",
  "systemVersionF": "System version: {val1} <br/><br/>",
  "regionalFee": "Region: {val1} <br/><br/>",
  "feedbackTimeF": "Feedback time: {val1}<br/><br/>",
  "discoveredVersionF": "Discovered version: {val1}<br/><br/>",
  "accountFee": "Account: {val1}<br/><br/>",
  "defect": "Defect",
  "pleaseEnterTa": "Please enter the long ID from the TAPD link",
  "pleaseEnterTa2": "Please enter the long ID from the TAPD link!",
  "defectId": "Defect ID",
  "requirementId": "Requirement ID",
  "pleaseSelectType2": "Please select a type!",
  "type": "Type",
  "relatedTo": "Related to",
  "convertToTapd4": "Transfer to Tapd encountered an exception, please retry or contact the administrator!",
  "convertToTapd5": "Transfer to Tapd issue ticket successful!",
  "waitingForTheFeed2": "Waiting for {val1} feedback",
  "associateTap": "Associate Tapd ticket",
  "pleaseEnterMa": "Please enter {val1}!",
  "waitingForTheFeed3": "Waiting for {val1} feedback",
  "tipsT5": "{val1}<a href=\"{val2}\" target=\"_blank\" style=\"cursor: pointer;\">{val3}</a> {val4}<br/><br/>This association includes {val5} feedback items<br>{val6}.{val7}`)\n        .join('<br>')}",
  "tipsT6": "{val1}<a href=\"{val2}\" target=\"_blank\" style=\"cursor: pointer;\">{val3}</a> {val4}<br/><br/>Associated feedback<br>{val5}.{val6}`)\n        .join('<br>')}",
  "timeIsTheMost": "[{val1} Latest summary of {total_count_to_replace} feedback]",
  "tipsT7": "\n    {val1}<a href=\"{val2}\" target=\"_blank\" style=\"cursor: pointer;\">{val3}</a><br/><br/>\n    【Issue description】<br/>\n    <div style=\"padding-left: 20px;\">\n      {val4}<br/>\n    </div>\n  ",
  "divst2": "<div style=\"display: flex\">Backend log: <div style=\"display: inline-block\">{val1}</div></div><br/>",
  "divst3": "<div style=\"display: flex\">Terminal log: <div style=\"display: inline-block\">{val1}</div></div>",
  "ahref2": "<a href=\"{val1}\" style=\"cursor: pointer;\">Log info {val2}</a><br/>",
  "imgal2": "<img alt=\"image.jpg\" style=\"width: 180px; margin: 5px 10px 0 0\" src=\"{val1}\">",
  "networkFee2": "Network: {val1} <br/><br/>",
  "modelFee2": "Model: {val1} <br/><br/>",
  "manufacturerFee2": "Manufacturer: {val1} <br/><br/>",
  "systemVersionF2": "System version: {val1} <br/><br/>",
  "regionalFee2": "Region: {val1} <br/><br/>",
  "feedbackTimeF2": "Feedback time: {val1}<br/><br/>",
  "discoveredVersionF2": "Discovered version: {val1}<br/><br/>",
  "accountFee2": "Account: {val1}<br/><br/>",
  "fixedUnfolding": "toFixed",
  "automaticContraction": "Auto Collapse",
  "navigationDisplay": "Nav. Mode",
  "chinese": "Chinese",
  "switchLanguage": "Switch language",
  "productPermissionApplication": "[Product Permission Application] Message reminder settings",
  "messageReceptionConfiguration": "Message reception configuration",
  "updateFailed": "Update failed!",
  "updateSuccessful": "Update successful!",
  "enterpriseWeChat": "Enterprise WeChat email",
  "logOutAndLogIn": "Log out",
  "messageConfiguration": "Message configuration",
  "permissionReview": "Permission review",
  "feedbackOnOpinions": "Feedback on opinions",
  "copyId": "Copy ID",
  "second": "Times",
  "feedbackInThePastThreeDays": "Feedback in the past three days",
  "inThePastYearThe": "In the past year",
  "currentFeedback": "Current feedback",
  "goToTheFeedbackList": "Go to feedback list",
  "accumulatedInThePastYear": "Accumulated feedback in the past year: {val1} times",
  "accumulatedReversalsThisMonth": "Accumulated feedback this month ",
  "forTheFirstFeedback": "First-time feedback user",
  "theLastTimeOn": "Last seen on",
  "copyUserAccount": "Copy user account",
  "accordingToTheCurrentTime": "Move back according to current time",
  "whole": "Whole",
  "allFeedbackVersion": "All feedback versions",
  "edition": "Edition",
  "allSources": "All sources",
  "allFeedbackCategories": "All types of feedback"
}