import React from 'react';
import { RouteConfig } from 'react-router-config';

import { isIntranet } from '@/util/dev-tool';
import { PageRouteType, routePostProc } from '@/util/route';

const Product = React.lazy(() => import('@/page/app/system/product'));

const SystemUser = React.lazy(() => import('@/page/app/system/system-user'));

const Audit = React.lazy(() => import('@/page/app/system/audit'));

const SYSTEM_ROUTE: RouteConfig[] = [
  {
    path: '/systemUser',
    component: SystemUser,
  },
  {
    path: '/product',
    component: Product,
  },
];

if (isIntranet()) {
  SYSTEM_ROUTE.push({
    path: '/audit',
    component: Audit,
  });
}

export { SYSTEM_ROUTE };
export default routePostProc(PageRouteType.SYSTEM, SYSTEM_ROUTE);
