import { useEffect, useRef } from 'react';

type GuardHandler = (path: string, prevPath: string) => void;

class Guard {
  private handlers = new Set<GuardHandler>();
  private path = '';

  // 完整的url路径， path + search
  private urlQueue: string[] = [];
  private queueMaxLen = 10;

  public notify(path: string, prevPath: string) {
    this.path = path;
    for (const handle of this.handlers) {
      handle(path, prevPath);
    }
  }

  public listen(handler: GuardHandler): () => boolean {
    this.handlers.add(handler);
    return () => this.handlers.delete(handler);
  }

  public getPath(): string {
    return this.path;
  }

  public getUrlQueue(): string[] {
    return this.urlQueue;
  }

  public storeUrl(url: string) {
    this.urlQueue.push(url);
    while (this.urlQueue.length > this.queueMaxLen) {
      this.urlQueue.shift();
    }
  }
}

const GUARD = new Guard();

export function guardRoot({ path, search }: { path: string; search: string }) {
  const prevPathRef = useRef('');
  useEffect(() => {
    GUARD.notify(path, prevPathRef.current);
    prevPathRef.current = path;
  }, [path]);

  useEffect(() => {
    GUARD.storeUrl(`${path}${search}`);
  }, [path, search]);

  return null;
}

export default GUARD;
