export default {
  disable: '禁用',
  setAs: '设为',
  tagTy: '{val1}标签',
  myWorkbench: '我的工作台',
  notActivated: '[未开启]-',
  aiSee: 'AISee爱反馈iwiki空间',
  aiSee2: 'AISee爱反馈码客圈子',
  aiSee3: 'AISee爱反馈KM吧',
  consultationAndFeedback: '咨询与反馈',
  sdkIntroduction: 'SDK介绍',
  blueShieldPlugin: '蓝盾插件',
  relatedResources: '相关资源',
  dcfData: 'DCF数据厨房',
  blueShield: '蓝盾',
  cooperativePartner: '合作伙伴',
  userAlert: '用户告警',
  productExperience: '产品体验',
  instructionsForUse: '使用指引',
  blueShieldAssemblyLine:
    '蓝盾流水线添加无入侵插件,注入DCL SDK后重签名即可体验摇一摇',
  noIntrusionAccess: '无入侵接入',
  byCustomizing: '通过自定义规则和高频关键词挖掘算法监控用户反馈，实时发现故障',
  intelligentAlarm: '智能告警',
  provideBasic:
    '提供基础的反馈能力,支持结合远程调试组件进行前置反馈调试能力,在反馈时携带更辅助分析信息',
  thePlatformProvidesMultipleOptions: '平台提供多种能力满足不同场景',
  functionIntroduction: '功能简介',
  includingRecordingPurposes:
    '包含录制用户操作、拉取指定目录及文件、进行远程断点调试能力,辅助产品更好定位问题',
  remotePositioning: '远程定位',
  provideInternalAndExternalServices:
    '提供内、外部产品体验、众测,支持从流水线到用户获取体验包全流程无人工干预实现,自动分发。',
  tence: 'Tencent. All\n              Rights Reserved.腾讯公司 版权所有',
  provideFeedback:
    '提供反馈去重、智能分类、自定义处理插件、无效规则过滤、自动转单、智能告警、辅助定位等提效工具，辅助研发问题处理和问题验证。',
  productFeedback:
    '产品的反馈能力,是产品团队与用户沟通的桥梁.在对平台升级的同时,我们也对用户反馈进行了优化,通过“摇一摇”的方式,解决了反馈路径复杂的问题,\n                        提升了用户反馈效率,并支持自动录屏、语音输入、反馈分享等多项功能,提供完善的反馈信息,赋能业务开发,及时快速地发现更多问题并辅助问题定位.',
  shakeFeedback: '摇一摇反馈',
  productIntroduction: '产品介绍',
  location: '定位',
  experience: '体验',
  user: '用户',
  accessDocument: '接入文档',
  connectNow: '立即接入',
  simplerAndMoreConvenient: '更简单便捷',
  assistYouInCreating: '协助您打造一站式体验&amp;反馈平台，体验',
  aiSee4: 'AISee 爱反馈',
  myProduct: '我的产品',
  homePage: '首页',
  qqQuickLogin: 'QQ 快捷登录',
  clickHereToJump: '点击这里跳转至内网地址',
  yourCurrentVisit: '您当前访问的是Aisee平台外网地址, 腾讯员工可以',
  permissionVerificationLost: '权限验证失败',
  completeRegistration: '完成注册',
  returnToThePreviousStep: '返回上一步',
  formal: '正式',
  grayscale: '灰度',
  productVersion: '产品版本:',
  onlyNumbersCanBeEntered: '只能输入数字或者字母',
  productId: '产品ID:',
  productType: '产品类型:',
  registrationFailedPlease: '注册失败，请联系管理员',
  completeRegistration2: '完成注册!',
  pleaseFillInTheProduct: '请填写产品描述',
  pleaseSelectBusiness: '请选择业务类型',
  businessType: '业务类型',
  pleaseSelectATerminal: '请选择终端形态',
  terminalForm: '终端形态',
  pleaseEnterTheProduct: '请输入产品模块',
  productModule: '产品模块',
  pleaseEnterYourAffiliation: '请输入所属产品',
  registerANewProduct: '注册新产品',
  pleaseSelectYourAffiliation: '请选择所属产品',
  belongingProducts: '所属产品',
  pleaseEnterYourAffiliation2: '请输入所属团队',
  pleaseSelectYourAffiliation2: '请选择所属团队',
  belongingTeam: '所属团队',
  tencentExternal: '腾讯外部',
  tencentsInternal: '腾讯内部',
  businessSource: '业务来源',
  maximumNumberOfUploads: '最多上传1张',
  imageSizeX: '图片尺寸120x120',
  pleaseUploadTheIcon: '请上传图标!',
  productIcon: '产品图标',
  feedbackOnProductNotes: '反馈产品注册',
  pleaseSelectARole: '请选择角色',
  applyForPermission: '申请权限',
  managers: '管理员列表:',
  noPermissionAtTheMomentPlease: '暂无权限,请联系产品管理员添加权限',
  applicationPermissionLost: '申请权限失败, 失败原因: {val1}',
  applyForPermission2: '申请权限成功，请等待管理员审批',
  unableToParseApplication: '无法解析申请信息: app_id或user_id非法',
};
