export default {
  "disable": "Disable",
  "setAs": "Set as",
  "tagTy": "{val1} tag",
  "myWorkbench": "My workbench",
  "notActivated": "[Not turned on]-",
  "aiSee": "AISee loves feedback on iwiki space",
  "aiSee2": "AISee Love Feedback Code Customer Circle",
  "aiSee3": "AISee loves to give feedback to KM, right",
  "consultationAndFeedback": "Consultation and Feedback",
  "sdkIntroduction": "SDK Introduction",
  "blueShieldPlugin": "Blue Shield Plugin",
  "relatedResources": "Related resources",
  "dcfData": "DCF Data Kitchen",
  "blueShield": "Blue Shield",
  "cooperativePartner": "Cooperative partner",
  "userAlert": "User Alert",
  "productExperience": "Product Experience",
  "instructionsForUse": "Instructions for use",
  "blueShieldAssemblyLine": "Add non-invasive plugins to the Blue Shield pipeline, inject DCL SDK and re sign to experience shaking",
  "noIntrusionAccess": "No intrusion access",
  "byCustomizing": "Monitor user feedback through custom rules and high-frequency keyword mining algorithms to detect faults in real time",
  "intelligentAlarm": "Intelligent Alarm",
  "provideBasic": "Basic feedback capability with pre-feedback debugging and enhanced analysis.",
  "thePlatformProvidesMultipleOptions": "Multiple capabilities for diverse scenarios.",
  "functionIntroduction": "Function Overview",
  "includingRecordingPurposes": "Records user actions, retrieves specified files, and supports remote debugging.",
  "remotePositioning": "Remote Positioning",
  "provideInternalAndExternalServices": "Delivers product experiences and crowd testing from assembly to user acquisition, fully automated.",
  "tence": "Tencent. All Rights Reserved. Copyright © Tencent Inc.",
  "provideFeedback": "Feedback deduplication, intelligent classification, custom plugins, and more to enhance R&D efficiency.",
  "productFeedback": "Product feedback is the bridge between teams and users. The platform upgrade optimizes feedback paths, improving efficiency with features like automatic recording and voice input.",
  "shakeFeedback": "Shake Feedback",
  "productIntroduction": "Product Introduction",
  "location": "Location",
  "experience": "Experience",
  "user": "User",
  "accessDocument": "Document",
  "connectNow": "Connect Now",
  "simplerAndMoreConvenient": "Simpler and more convenient",
  "assistYouInCreating": "Assist you in creating a one-stop experience; Feedback platform, experience",
  "aiSee4": "AISee 爱反馈",
  "myProduct": "My product",
  "homePage": "Home page",
  "qqQuickLogin": "QQ Quick Login",
  "clickHereToJump": "Click here to jump to the internal network address",
  "yourCurrentVisit": "You are currently accessing the Aisee platform's external address. Tencent employees can",
  "permissionVerificationLost": "Permission verification failed",
  "completeRegistration": "Complete registration",
  "returnToThePreviousStep": "Return to the previous step",
  "formal": "Formal",
  "grayscale": "Grayscale",
  "productVersion": "Product version:",
  "onlyNumbersCanBeEntered": "Only numbers or letters can be entered",
  "productId": "Product ID:",
  "productType": "Product type:",
  "registrationFailedPlease": "Registration failed, please contact the administrator",
  "completeRegistration2": "Complete registration!",
  "pleaseFillInTheProduct": "Please fill in the product description",
  "pleaseSelectBusiness": "Please select the business type",
  "businessType": "Business type",
  "pleaseSelectATerminal": "Please select the terminal form",
  "terminalForm": "Terminal form",
  "pleaseEnterTheProduct": "Please enter the product module",
  "productModule": "Product module",
  "pleaseEnterYourAffiliation": "Please enter the product you belong to",
  "registerANewProduct": "Register a new product",
  "pleaseSelectYourAffiliation": "Please select the corresponding product",
  "belongingProducts": "Belonging products",
  "pleaseEnterYourAffiliation2": "Please enter your team affiliation",
  "pleaseSelectYourAffiliation2": "Please select the team to which you belong",
  "belongingTeam": "Belonging team",
  "tencentExternal": "Tencent External",
  "tencentsInternal": "Tencent's internal",
  "businessSource": "Business source",
  "maximumNumberOfUploads": "Upload up to 1 image",
  "imageSizeX": "Picture size 120x120",
  "pleaseUploadTheIcon": "Please upload the icon!",
  "productIcon": "Product icon",
  "feedbackOnProductNotes": "Feedback on product registration",
  "pleaseSelectARole": "Please select a role",
  "applyForPermission": "Apply for permission",
  "managers": "Administrator List:",
  "noPermissionAtTheMomentPlease": "No permission currently available, please contact the product administrator to add permission",
  "applicationPermissionLost": "Permission application failed, reason for failure: {val1}",
  "applyForPermission2": "Permission application successful, please wait for administrator approval",
  "unableToParseApplication": "Unable to parse application information: App_id or user_id is illegal"
}