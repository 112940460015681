import { DEPLOY_CLOUD, DEPLOY_ENV } from '@/constant/constant';
import { DCL_EXTRANET, DCL_INTRANET } from '@/def/domain';
import ls from '@/util/ls';

const lsDevConfKey = 'test-one-dev-conf';

interface DevConf {
  enable: boolean;
  ruleMap?: DevRuleMap;
}

export interface DevRuleMap {
  [app: string]: DevRule | undefined;
}

export interface DevRule {
  name: string;
  entry: string;
  entryType: 'js' | 'html';
  withProduct: boolean;
}

function getDevConf(): DevConf {
  let conf: DevConf = ls.get(lsDevConfKey);

  if (!conf) {
    conf = {
      enable: false,
      ruleMap: {},
    };
  }

  return conf;
}

export function isDevMode(): boolean {
  return getDevConf().enable;
}

/**
 * 是否为测试环境
 * @returns {boolean}
 */
export const isTestEnv = (): boolean => {
  const { origin } = window.location;
  // 当前为内网正式环境
  if (origin.includes(DCL_INTRANET.production)) {
    return false;
  }
  // 当前为内网正式环境(https)
  if (origin.includes(DCL_INTRANET.productionHttps)) {
    return false;
  }
  // 当前为外网正式环境
  if (origin.includes(DCL_EXTRANET.production)) {
    return false;
  }
  return true;
};

/**
 * 是否为内网
 * @returns
 */
export const isIntranet = (): boolean => {
  const { origin } = window.location;
  // 外网测试环境域名 -> 外网
  if (origin.includes(DCL_EXTRANET.development)) {
    return false;
  }
  // 外网正式环境域名 -> 外网
  if (origin.includes(DCL_EXTRANET.production)) {
    return false;
  }
  // 云上部署环境，视为外网
  if (DEPLOY_ENV === DEPLOY_CLOUD) {
    return false;
  }
  return true;
};

export const USER_IMG_EXTRANET = 'https://dayu.woa.com';

/**
 * 是否打开后台调试
 * @returns {boolean}
 */
export const isDebugBackend = (): boolean => {
  const { origin } = window.location;
  // 只有 http://test.aisee.woa.com 和 本地 才打开后台调试
  if (
    origin.includes(DCL_INTRANET.development) ||
    origin.includes('localhost')
  ) {
    return true;
  }
  return false;
};
