export default {
  "invalid": "Invalid",
  "problem": "Problem",
  "allTypes": "All types",
  "followingUp": "Following up",
  "transferredEventList": "Transferred event list",
  "expired": "Expired ",
  "contactedUser": "Contacted the user to resolve the issue",
  "hangUp": "Hang up",
  "rejected": "Rejected",
  "resolved": "Resolved",
  "day": "Day",
  "hour": "Hour",
  "minute": "Minute",
  "pleaseSelectTheSource": "Please select the source",
  "pleaseSelectVersion": "Please select version",
  "feedbackVersion": "Feedback version",
  "pleaseSelectTheStatus": "Please select the status",
  "processingStatus": "Processing status",
  "userFeedback": "User feedback",
  "doesNotIncludeRelationships": "Does not include relationships",
  "includeRelationships": "Include relationships",
  "mailbox": "Mailbox",
  "enterpriseMicroRobot": "Enterprise Micro Robot",
  "weCom": "WeCom",
  "customFields": "Custom Fields",
  "region": "Region",
  "highFrequencyKeywords": "High frequency keywords",
  "minute2": "60 minutes",
  "minute3": "45 minutes",
  "minute4": "30 Minutes",
  "minute5": "15 minutes",
  "minute6": "5 minutes",
  "pleaseEnterTheKey": "Please enter keywords",
  "pleaseEnterTheNumberOfTimes": "Please enter the number of times",
  "pleaseInputTheFluctuation": "Please enter fluctuation limit",
  "pleaseEnterDetection": "Please enter the testing period",
  "pleaseChooseOn": "Please select the oncall receiving address",
  "pleaseEnterAtLeast": "Please enter at least one recipient",
  "pleaseEnterTheCompany": "Please enter the enterprise WeChat robot",
  "pleaseSelectAtLeast": "Please select at least one receiving method",
  "pleaseSelectDetection": "Please select the detection frequency",
  "pleaseProvideFeedback": "Please enter feedback text",
  "pleaseEnterThePlugin": "Please enter the purpose of the plugin",
  "pleaseEnterTheAlarm": "Please enter the name",
  "pleaseEnterTheSubscribe": "Please enter the name",
  "useSpacesForMultipleValues": "Multiple values separated by spaces",
  "multipleValuesInEnglish": "Multiple values separated by commas in English characters (,)",
  "inputBoxAndSingle": "Input box with a single value",
  "notBelongingTo": "Not belonging to",
  "belongTo": "Belong to",
  "notIncluded": "Not included",
  "beEqualTo": "Be equal to",
  "prefixMatch": "Prefix match ",
  "regularMismatch": "Regular mismatch",
  "lengthLimit": "Length limit",
  "regularMatching": "Regular matching",
  "satisfyAnyRequirement": "Satisfy any condition (or)",
  "satisfyAllRequirements": "Meet all conditions (and)",
  "productRegistration": "Product Registration",
  "logAnalysisIsTheMost": "Best Practices for Log Analysis",
  "contactTheAssistant": "Contact the assistant",
  "guidanceDocument": "Guidance document",
  "lessThanOrEqualTo": "Less than or equal to",
  "lessThan": "Less than",
  "greaterThanOrEqualTo": "Greater than or equal to",
  "greaterThan": "Greater than",
  "allStates": "All states",
  "notEqualTo": "Not equal to",
  "lessThanOrEqualTo2": "Less than or equal to",
  "greaterThanOrEqualTo2": "Greater than or equal to",
  "excludeSelected": "Exclude Selected",
  "allRules": "All rules",
  "transferredProblemList": "Transferred problem list",
  "newFeedback": "New",
  "antiFraudReporting": "Anti fraud reporting",
  "abuse": "Abuse",
  "pornographic": "Pornographic",
  "vulgar": "Vulgar",
  "advertisement": "Advertisement",
  "neutralEmotion": "Neutral emotion",
  "negativeEmotions": "Negative emotions",
  "positiveEmotions": "Positive emotions",
  "noAttributes": "No attributes",
  "allAttributes": "All attributes",
  "modified": "Modified",
  "notChanged": "Not changed",
  "allIntelligentComponents": "All intelligent classification modifications",
  "allCountries": "All countries",
  "allOfThem": "All of them",
  "allAttachments": "All attachments",
  "allNetworks": "All networks",
  "allSources": "All sources",
  "allVersions": "All versions",
  "video": "Video",
  "enclosure": "Enclosure",
  "no": "No",
  "transferredToTap": "Transferred to Tapd",
  "repeat": "Repeat",
  "roast": "Roast",
  "consultingService": "Consulting service",
  "positiveReviews": "Positive reviews",
  "explain": "Explain",
  "example": "Example",
  "inputFormat": "Input format",
  "multipleKeywords": "Multiple keyword logic",
  "queryContainsH": "Query feedback containing keywords \"hello\" or \"world\" (not applicable under the condition of \"not included\")",
  "keywordKey": "【 Keywords 】 | 【 Keywords 】 (English vertical line)",
  "keywordKey2": "[Keyword], [Keyword] (comma in English)",
  "querySimultaneousPackages": "Search for feedback containing both the keywords' hello 'and' world 'simultaneously",
  "keywordSpace": "[Keywords] [Spaces] [Keywords]"
}