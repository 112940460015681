import common from './common';
import other from './other';
import system from './system';
import satisfaction from './satisfaction';
import operate from './operate';
import time from './time';
import issue_detail from './issue_detail';
import field from './field';
import insight from './insight';
import constant from './constant';
import component from './component';
import footer from './footer';
import navbar from './navbar';
import overview from './overview';

export default Object.assign({}, {
  common,
  footer,
  navbar,
  overview,
  component,
  constant,
  insight,
  field,
  issue_detail,
  time,
  operate,
  satisfaction,
  system,
  other,
});