export default {
  "surveyQuestionnaire": "Survey questionnaire",
  "satisfactionQuestionnaire": "Satisfaction questionnaire",
  "questionnaireDashboardMaster": "The questionnaire dashboard mainly presents user satisfaction indicators and trends, and monitors the periodic changes in satisfaction based on the questionnaire results.",
  "questionnaireDashboard": "Questionnaire Dashboard",
  "theCEndUserHas": "The questionnaires filled out by C-end users are uniformly recorded in the question list, and you can view the corresponding response records for all questionnaires.",
  "questionnaireList": "Questionnaire List",
  "regardingSatisfaction": "For satisfaction survey scenarios, AiSee supports questionnaire configuration in the form of single or multiple choice tags, helping you quickly cite on the app.",
  "questionnaireSettings": "Questionnaire settings",
  "variousContentTypes": "Comparison of various content types {val1}",
  "contrast": "Contrast",
  "satisfactionRate": "Satisfaction rate",
  "satisfactionScore": "Satisfaction score",
  "zhencaiAudiovisual": "Zhencai Audiovisual in Various Content Types",
  "eachNetworkNe": "Comparison of various networks {val1}",
  "zhencaiAudiovisual2": "Zhencai Audiovisual in various online environments",
  "gearType": "Gear type",
  "variousGearModels": "Comparison of different gear models {val1}",
  "zhencaiAudiovisual3": "Zhencai Audiovisual in various models",
  "satisfactionRateTrend": "Satisfaction rate trend chart",
  "satisfactionScore2": "Satisfaction score trend chart",
  "zhencaiAudiovisualWorks": "What are the advantages and disadvantages of Zhencai Audiovisual as the top standard of Tencent Video Audiovisual?",
  "interfaceRequestExceeded": "Interface request timeout",
  "positive": "Positive",
  "zhencaiAudiovisual4": "Zhencai Audiovisual",
  "nameb": "{val1}:<br/>Zhencai Audiovisual: {val2}%<br/>All: {val3}%",
  "nameb2": "{val1}:<br/>Zhencai Audiovisual: {val2}<br/>All: {val3}",
  "zhencai": "Zhencai",
  "risingFalling": "Rising-falling",
  "documentary": "Documentary",
  "aJuvenile": "A juvenile",
  "comic": "Comic",
  "film": "Film",
  "variety": "Variety",
  "tvPlay": "TV play",
  "emptyValue": "Empty value",
  "resetSuccessful": "Reset successful",
  "contentCategory": "Content category",
  "enterThePremiereYear": "Enter the premiere year to search",
  "enterCid": "Enter cid title to search",
  "cidFeedback": "CID feedback frequency, satisfaction score, and satisfaction data table (default display of top 30 feedback frequency data)",
  "cidFeedback2": "CID feedback frequency, satisfaction score, and satisfaction data table. xlsx",
  "premiereYear": "Premiere year",
  "cidFeedback3": "CID feedback frequency",
  "cidTitle": "CID Title",
  "membershipType": "Membership type",
  "selectQuestionnaire": "Select questionnaire",
  "surve": "{val1}- {val2}{val3}",
  "naturalMonth": "Natural Month",
  "naturalWeek": "Natural Week",
  "noMemberInformationAvailable": "No membership information reported to users",
  "nonMemberUsers": "Non member users",
  "memberUsers": "Member users",
  "allUsers": "All users",
  "empty": "Empty",
  "clearingTimeInto": "Clearing time successfully",
  "selectedTime": "Selected time:",
  "timeFrame": "Time frame",
  "customTime": "Custom Time",
  "goCreateANewQuestionnaire": "Go create a new questionnaire",
  "currentlyUnavailable": "There are currently no available questionnaires!",
  "generalLabel": "General label",
  "percentageCalculation": "Percentage calculation logic: feedback quantity of a certain tag under the \"general\" option/total feedback quantity of tags under the \"general\" option",
  "notVeryGoodLabel": "Not very good label",
  "percentageCalculation2": "Percentage calculation logic: feedback quantity of a certain tag under the \"not very good\" option/total feedback quantity of tags under the \"not very good\" option",
  "justSoSo": "Just so so",
  "veryGoodLabel": "Very good label",
  "percentageCalculation3": "Percentage calculation logic: feedback quantity of a certain tag under the \"Very Good\" option/total feedback quantity of tags under the \"Very Good\" option",
  "veryGood": "Very good",
  "problemFeedbackLabel": "The problem? (Feedback Label Statistics Table)",
  "userFeedback": "Users have provided feedback on the main issues",
  "percentage": "Percentage",
  "quantity": "Quantity",
  "label": "Label",
  "secondaryLabelSystem": "Secondary Label Statistics Table",
  "correspondingToTheSelectedTime": "Corresponding to the selected time range, the change in satisfaction score within the same period in the previous cycle",
  "theScoringOptionsAreVeryGood": "The number of people with the rating option \"very good\" accounts for% of the total number of people, while the number of people with the rating option \"not very good\" accounts for% of the total number of people",
  "correspondingToTheSelectedTime2": "Corresponding to the selected time range, the change in satisfaction rate within the same cycle in the previous period",
  "theScoringOptionsAreVeryGood2": "Scoring option \"Very good\" Number of people/Total number of people * 100%",
  "correspondingToTheSelectedTime3": "Corresponding to the selected time range, changes in the number of respondents in the previous same period",
  "numberOfRespondents": "Number of respondents",
  "questionnaireSubmission": "The questionnaire received a satisfactory vote.",
  "theQuestionnaireIncludes": "A questionnaire, including",
  "thisCycleHasATotalOf": "A total of were collected in this cycle",
  "aFeedbackQuestionnaire": "A feedback questionnaire.",
  "thisQuestionnaireWasCreated": "Since the creation of this questionnaire, a total of responses have been received",
  "since": "Since",
  "howManyUsersAreThere": "How many users cast a satisfied vote?",
  "numberOfSatisfiedIndividuals": "Distribution proportion of satisfied individuals. xlsx",
  "numberOfPeople": "Number of people",
  "percentageCalculation4": "Percentage calculation logic: number of feedback options/total feedback quantity",
  "theFeedbackQuestionnaire": "The feedback questionnaire.",
  "targetingTencent": "Regarding Tencent Video",
  "sinceThenThereHaveBeenATotalOfResponses": "Since then, a total of have been recycled",
  "whatHaveYouExperienced": "What kind of changes have you experienced?",
  "trend": "{val1}.xlsx",
  "playerFunction": "Player function experience",
  "soundAndPictureQualityExperience": "Sound and picture quality experience",
  "tencentVideo": "Overall user experience of Tencent Video",
  "forma": "{val1} million",
  "dissatisfied": "😡  dissatisfied",
  "veryDissatisfied": "Very dissatisfied",
  "dissatisfied2": "Dissatisfied",
  "commonly": "😑  commonly",
  "generallySatisfied": "Generally satisfied",
  "satisfied": "😁  satisfied",
  "verySatisfied": "Very satisfied",
  "satisfied2": "Satisfied",
  "enterVid": "Enter vid title to search",
  "vidFeedback": "Vid feedback frequency, satisfaction score, and satisfaction data table (default display of top 30 feedback frequency data)",
  "vidFeedback2": "Vid feedback frequency, satisfaction score, and satisfaction data table. xlsx",
  "vidFeedback3": "Vid feedback frequency",
  "vidTitle": "Vid Title",
  "scoringOptions": "Scoring options",
  "belongingQuestionnaire": "Belonging questionnaire",
  "channelName": "Channel Name",
  "dataExportInProgress": "Data export in progress",
  "allQuestionnaires": "All questionnaires",
  "surve2": "{val1}- {val2}{val3}",
  "exportCurrentFilter": "Export the currently filtered data",
  "confirmExportIsFull": "Are you sure to export satisfaction data? (Up to 40000 pieces of data can be exported at a time)",
  "popUpNotification": "Pop-up notification",
  "pleaseChooseAChannel": "Please choose a channel",
  "questionnaireChannel": "Questionnaire channel",
  "questionnaireTitle": "Questionnaire Title",
  "channelDescription": "Channel description",
  "pleaseEnterTheChannel": "Please enter the channel name",
  "pleaseFillInTheChannel": "Please fill in the channel name",
  "newChannel": "New channel",
  "creationFailedPlease": "Creation failed, please try again later",
  "modificationFailedPlease": "Modification failed, please try again later",
  "deleteFailedPlease": "Delete failed, please try again later",
  "areYouSureYouWantToDelete": "Are you sure you want to delete this channel? (Cannot be restored after deletion)",
  "updatedBy": "Updated by",
  "channelId": "Channel ID",
  "createANewQuestionnaire": "Create a new questionnaire",
  "editQuestionnaire": "Edit questionnaire",
  "pleaseFillInTheI": "Please fill in the scene value of the {val1} th distribution scenario",
  "pleaseFillInTheI2": "Please fill in the name of the {val1} th distribution scenario",
  "pleaseSelectAtLeast": "Please select at least two question options",
  "pleaseFillInTheI3": "Please fill in the title of the {val1} th scoring option",
  "pleaseSelectAQuestionnaire": "Please select the type of questionnaire",
  "pleaseFillOutTheQuestionnaire": "Please fill in the questionnaire title",
  "pleaseSelectAQuestionnaire2": "Please select the questionnaire channel",
  "pleaseChooseNext": "Please select the next questionnaire",
  "nextQuestionnaire": "Next questionnaire",
  "multipleChoice": "Multiple Choice",
  "tagType": "Tag type",
  "addTags": "Add tags",
  "addedSuccessfully": "Added successfully",
  "pleaseSelectALabel": "Please select a label",
  "tagContent": "Tag content",
  "labelIssue": "Label issue",
  "pleaseEnterTheQuestion": "Please enter the question",
  "pleaseClickOnMultilingual": "Please click on the multilingual button to input option questions",
  "optionsIcon": "Options icon",
  "optionName": "Option Name",
  "pleaseEnterOptions": "Please enter option name",
  "pleaseClickOnMultilingual2": "Please click the multilingual button to enter the option name",
  "editTags": "Edit tags",
  "icon": "Icon",
  "textIcon": "Text+icon",
  "writtenWords": "Written words",
  "optionType": "Option type",
  "option": "Option",
  "singleChoiceQuestion": "Single Choice Question",
  "multipleChoiceQuestions": "Multiple Choice Questions",
  "subject": "Subject",
  "pleaseEnterTheQuestion2": "Please enter the question",
  "pleaseClickOnMultilingual3": "Please click the multilingual button to enter the question",
  "titleInd": "Title {val1}",
  "secondaryLabel": "Secondary label",
  "firstLevelLabel": "First level label",
  "chooseToFillInTheSecondLevelBid": "Optional, title of secondary tag group",
  "optionalConfiguration2": "Optional configuration, the secondary tag is a sub tag configured under the questionnaire tag",
  "addDistributionField": "Add distribution scenario",
  "enterDistributionField": "Enter the distribution scenario and press Enter to confirm",
  "sceneValue": "Scene value",
  "sceneNameOnly": "Scene names only support English",
  "pleaseEnterAndDistribute": "Please enter the scene name of the distribution scenario",
  "sceneName": "Scene name",
  "optionalConfiguration": "Optional configuration, the questionnaire will only be issued when the requested scenario exists in this configuration. (Keep at least one piece of data)",
  "distributionScenario": "Distribution scenario",
  "newTopic": "New Topic",
  "sortItemI": "Scoring option {val1}",
  "titleInd2": "Title {val1}",
  "reduceTheScoreSystemMeeting": "Shrinking the scale will result in the loss of data from excess scales. Are you sure?",
  "questionnaireType": "Questionnaire type",
  "pleaseEnterTheQuestionnaire": "Please enter the questionnaire title",
  "pleaseClickOnMultilingual4": "Please click the multilingual button to enter the title",
  "tagDescription": "Tag description",
  "enableMultipleLanguages": "After enabling multiple languages, the language issued under the background is English",
  "questionnaireId": "Questionnaire ID",
  "open": "Open",
  "fivePointSystem": "Five point system",
  "threePointSystem": "Three point system",
  "binarySystem": "Binary system",
  "optionTypesAnd": "The option type and option do not correspond, please select again",
  "changeStatusLost": "Failed to modify status, please try again later",
  "changeTheStatusTo": "Successfully modified status",
  "confirmToCloseTheQuestion": "Are you sure to close the questionnaire?",
  "confirmToOpenTheQuestion": "Are you sure to open the questionnaire?",
  "openStatus": "Open status",
  "channelSettings": "Channel Settings"
}