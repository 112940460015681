export default {
  surveyQuestionnaire: '调研问卷',
  satisfactionQuestionnaire: '满意度问卷',
  questionnaireDashboardMaster:
    '问卷看板主要呈现用户满意度指标及趋势，基于问卷结果监控满意度的周期性变化情况。',
  questionnaireDashboard: '问卷看板',
  theCEndUserHas:
    'C端用户已填写的问卷统一记录到问题列表，您可以查看所有问卷对应的回收记录。',
  questionnaireList: '问卷列表',
  regardingSatisfaction:
    '针对满意度调研场景，AiSee支持单选或多选标签形式的问卷配置，帮助您在APP上快捷的引用。',
  questionnaireSettings: '问卷设置',
  variousContentTypes: '各内容类型{val1}对比',
  contrast: '对比',
  satisfactionRate: '满意率',
  satisfactionScore: '满意度得分',
  zhencaiAudiovisual: '臻彩视听在各内容类型的',
  eachNetworkNe: '各网络{val1}对比',
  zhencaiAudiovisual2: '臻彩视听在各网络环境的',
  gearType: '档位机型',
  variousGearModels: '各档位机型{val1}对比',
  zhencaiAudiovisual3: '臻彩视听在各机型的',
  satisfactionRateTrend: '满意率趋势图',
  satisfactionScore2: '满意度得分趋势图',
  zhencaiAudiovisualWorks:
    '臻彩视听作为腾讯视频视听的顶级标准, 存在哪些优势和不足?',
  interfaceRequestExceeded: '接口请求超时',
  positive: '正片',
  zhencaiAudiovisual4: '臻彩视听',
  nameb: '{val1}:<br /> 臻彩视听:{val2}% <br /> 全部:{val3}%',
  nameb2: '{val1}:<br /> 臻彩视听:{val2} <br /> 全部:{val3}',
  zhencai: '臻彩',
  risingFalling: '中高',
  documentary: '纪录片',
  aJuvenile: '少儿',
  comic: '动漫',
  film: '电影',
  variety: '综艺',
  tvPlay: '电视剧',
  emptyValue: '空值',
  resetSuccessful: '重置成功',
  contentCategory: '内容品类',
  enterThePremiereYear: '输入首播年份可搜索',
  enterCid: '输入cid标题可搜索',
  cidFeedback:
    'cid反馈频次、满意度得分及满意度数据表格(默认显示反馈频次前30的数据)',
  cidFeedback2: 'cid反馈频次、满意度得分及满意度数据表格.xlsx',
  premiereYear: '首播年份',
  cidFeedback3: 'cid反馈频次',
  cidTitle: 'cid标题',
  membershipType: '会员类型',
  selectQuestionnaire: '选择问卷',
  surve: '{val1}-{val2}{val3}',
  naturalMonth: '自然月',
  naturalWeek: '自然周',
  noMemberInformationAvailable: '无会员信息上报用户',
  nonMemberUsers: '非会员用户',
  memberUsers: '会员用户',
  allUsers: '全体用户',
  empty: '清空',
  clearingTimeInto: '清空时间成功',
  selectedTime: '已选时间:',
  timeFrame: '时间范围',
  customTime: '自定义时间',
  goCreateANewQuestionnaire: '去新建问卷',
  currentlyUnavailable: '暂时无可用问卷!',
  generalLabel: '一般标签',
  percentageCalculation:
    '百分比计算逻辑: “一般“选项下某标签反馈数量/“一般“选项下反馈了标签的总反馈数量',
  notVeryGoodLabel: '不太好标签',
  percentageCalculation2:
    '百分比计算逻辑: “不太好“选项下某标签反馈数量/“不太好“选项下反馈了标签的总反馈数量',
  justSoSo: '不太好',
  veryGoodLabel: '很不错标签',
  percentageCalculation3:
    '百分比计算逻辑: “很不错“选项下某标签反馈数量/“很不错“选项下反馈了标签的总反馈数量',
  veryGood: '很不错',
  problemFeedbackLabel: '问题?(反馈标签统计表)',
  userFeedback: '用户反馈了那些主要的',
  percentage: '百分比',
  quantity: '数量',
  label: '标签',
  secondaryLabelSystem: '二级标签统计表',
  correspondingToTheSelectedTime:
    '对应所选时间范围，上一相同周期内的满意度得分变化',
  theScoringOptionsAreVeryGood:
    '打分选项“很不错”人数占总人数的%-打分选项“不太好”人数占总人数%',
  correspondingToTheSelectedTime2:
    '对应所选时间范围，上一相同周期内的满意率变化',
  theScoringOptionsAreVeryGood2: '打分选项“很不错”人数/总人数*100%',
  correspondingToTheSelectedTime3:
    '对应所选时间范围，上一相同周期内的作答人数变化',
  numberOfRespondents: '作答人数',
  questionnaireSubmission: '份)的问卷投出了满意的一票。',
  theQuestionnaireIncludes: '份问卷， 其中',
  thisCycleHasATotalOf: '本周期一共回收了',
  aFeedbackQuestionnaire: '份反馈问卷。',
  thisQuestionnaireWasCreated: '本问卷创建以来, 一共回收了',
  since: '自',
  howManyUsersAreThere: '有多少用户投出满意的一票?',
  numberOfSatisfiedIndividuals: '满意度人数分布占比.xlsx',
  numberOfPeople: '人数',
  percentageCalculation4: '百分比计算逻辑: 选项反馈数量/总反馈数量',
  theFeedbackQuestionnaire: '的反馈问卷。',
  targetingTencent: '份针对腾讯视频的',
  sinceThenThereHaveBeenATotalOfResponses: '以来, 一共回收了',
  whatHaveYouExperienced: '经历了怎么样的变化?',
  trend: '{val1}.xlsx',
  playerFunction: '播放器功能体验',
  soundAndPictureQualityExperience: '音画质体验',
  tencentVideo: '腾讯视频整体使用体验',
  forma: '{val1}万',
  dissatisfied: '😡 不满意',
  veryDissatisfied: '很不满意',
  dissatisfied2: '不满意',
  commonly: '😑 一般',
  generallySatisfied: '一般满意',
  satisfied: '😁 满意',
  verySatisfied: '很满意',
  satisfied2: '满意',
  enterVid: '输入vid标题可搜索',
  vidFeedback:
    'vid反馈频次、满意度得分及满意度数据表格(默认显示反馈频次前30的数据)',
  vidFeedback2: 'vid反馈频次、满意度得分及满意度数据表格.xlsx',
  vidFeedback3: 'vid反馈频次',
  vidTitle: 'vid标题',
  scoringOptions: '打分选项',
  belongingQuestionnaire: '所属问卷',
  channelName: '渠道名称',
  dataExportInProgress: '数据导出中',
  allQuestionnaires: '全部问卷',
  surve2: '{val1}-{val2}{val3}',
  exportCurrentFilter: '导出当前筛选的数据',
  confirmExportIsFull: '确认导出满意度数据吗？(单次最多导出40w条数据)',
  popUpNotification: '弹窗',
  pleaseChooseAChannel: '请选择渠道',
  questionnaireChannel: '问卷渠道',
  questionnaireTitle: '问卷标题',
  channelDescription: '渠道描述',
  pleaseEnterTheChannel: '请输入渠道名称',
  pleaseFillInTheChannel: '请填写渠道名称',
  newChannel: '新建渠道',
  creationFailedPlease: '创建失败, 请稍后再试',
  modificationFailedPlease: '修改失败, 请稍后再试',
  deleteFailedPlease: '删除失败, 请稍后再试',
  areYouSureYouWantToDelete: '您确定要删除该渠道吗？(删除后不能恢复)',
  updatedBy: '更新者',
  channelId: '渠道ID',
  createANewQuestionnaire: '新建问卷',
  editQuestionnaire: '编辑问卷',
  pleaseFillInTheI: '请填写第{val1}个下发场景的场景值',
  pleaseFillInTheI2: '请填写第{val1}个下发场景的名称',
  pleaseSelectAtLeast: '请至少选择两个题目选项',
  pleaseFillInTheI3: '请填写第{val1}个打分选项的标题',
  pleaseSelectAQuestionnaire: '请选择问卷类型',
  pleaseFillOutTheQuestionnaire: '请填写问卷标题',
  pleaseSelectAQuestionnaire2: '请选择问卷渠道',
  pleaseChooseNext: '请选择下一个问卷',
  nextQuestionnaire: '下一个问卷',
  multipleChoice: '多选',
  tagType: '标签类型',
  addTags: '添加标签',
  addedSuccessfully: '添加成功',
  pleaseSelectALabel: '请选择标签',
  tagContent: '标签内容',
  labelIssue: '标签问题',
  pleaseEnterTheQuestion: '请输入问题',
  pleaseClickOnMultilingual: '请点击多语言按钮输入选项问题',
  optionsIcon: '选项图标',
  optionName: '选项名称',
  pleaseEnterOptions: '请输入选项名称',
  pleaseClickOnMultilingual2: '请点击多语言按钮输入选项名称',
  editTags: '编辑标签',
  icon: '图标',
  textIcon: '文字+图标',
  writtenWords: '文字',
  optionType: '选项类型',
  option: '选项',
  singleChoiceQuestion: '单选题',
  multipleChoiceQuestions: '多选题',
  subject: '题目',
  pleaseEnterTheQuestion2: '请输入题目',
  pleaseClickOnMultilingual3: '请点击多语言按钮输入题目',
  titleInd: '题目{val1}',
  secondaryLabel: '二级标签',
  firstLevelLabel: '一级标签',
  chooseToFillInTheSecondLevelBid: '选填，二级标签组的标题',
  optionalConfiguration2: '可选配置，二级标签是问卷标签下配置的子标签',
  addDistributionField: '添加下发场景',
  enterDistributionField: '输入下发场景按回车确认',
  sceneValue: '场景值',
  sceneNameOnly: '场景名称仅支持英文',
  pleaseEnterAndDistribute: '请输入下发场景的场景名称',
  sceneName: '场景名称',
  optionalConfiguration:
    '可选配置，配置后只有请求的场景存在于此配置中时才下发问卷。(至少保留一条数据)',
  distributionScenario: '下发场景',
  newTopic: '新建题目',
  sortItemI: '打分选项{val1}',
  titleInd2: '题目{val1}',
  reduceTheScoreSystemMeeting: '缩小分制, 会丢失多余分制的数据数据, 是否确认?',
  questionnaireType: '问卷类型',
  pleaseEnterTheQuestionnaire: '请输入问卷标题',
  pleaseClickOnMultilingual4: '请点击多语言按钮输入标题',
  tagDescription: '标签描述',
  enableMultipleLanguages: '开启多语言后，托底下发的语言是英语',
  questionnaireId: '问卷ID',
  open: '开启',
  fivePointSystem: '五分制',
  threePointSystem: '三分制',
  binarySystem: '二分制',
  optionTypesAnd: '选项类型和选项不对应, 请重新选择',
  changeStatusLost: '修改状态失败, 请稍后再试',
  changeTheStatusTo: '修改状态成功',
  confirmToCloseTheQuestion: '确定关闭问卷?',
  confirmToOpenTheQuestion: '确定开启问卷?',
  openStatus: '开启状态',
  channelSettings: '渠道设置',
};
