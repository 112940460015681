const RAWLS = {
  set(key: string, value: string): void {
    return window.localStorage.setItem(key, value);
  },
  get(key: string): string | null {
    return window.localStorage.getItem(key);
  },
  remove(key: string): void {
    return window.localStorage.removeItem(key);
  },
};

export default RAWLS;
