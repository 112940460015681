import kiwiIntl, { I18NAPI } from 'kiwi-intl';

import enUsLangs from '../../.kiwi/en-US/';
import zhCNLangs from '../../.kiwi/zh-CN/';
// import zhTWLangs from '../../.kiwi/zh-TW/';

export enum LangEnum {
  'zh-CN' = 'zh-CN',
  'en-US' = 'en-US',
  // 'zh-TW' = 'zh-TW'
}

/** cookie上携带的多语言 */
export const allLangCookieMap = {
  'zh-CN':'ZH-CN',
  'en-US':'EN',
  // 'ID',
  // 'TH',
  // 'MS',
  // 'VI',
  // 'ES',
  // 'PT',
  // 'HI',
  // 'KO',
  // 'JA',
  // 'AR',
};
interface NEWI18NAPI extends I18NAPI {
    init(lang: string, metas: object, defaultKey?: 'zh-CN'): I18NAPI; 
    setLang(lang: string): void;
    template(str: string, args: object): string; 
    get(name: string, args?: object): string;
  }

/**
 * 获取当前语言的Cookie
 */
export function getCurrentLang(): LangEnum {
  const urlLang = new URL(window.location.href).searchParams.get('lang');
  const cookieLang = (document.cookie.match(/kiwi-locale=([^;$]+)/) || [null, 'zh-CN'])[1];
  const lang = (cookieLang as string).split(' ')[0];
  if (Object.keys(LangEnum).includes(urlLang as string)) {
    return urlLang as LangEnum;
  }
  return lang as LangEnum;
}

/** 是否中文环境 */
export const isCn = ()=>{
  const lang = getCurrentLang()
  return lang===LangEnum['zh-CN']||lang===LangEnum['zh-TW']
}

const langs = {
  'en-US': enUsLangs,
  'zh-CN': zhCNLangs,
  // 'zh-TW': zhTWLangs
};
// 从 Cookie 中取语言值, 默认为 zh-CN
const defaultLang = getCurrentLang();

let curLang;
if (Object.keys(langs).indexOf(defaultLang) > -1) {
  curLang = defaultLang;
} else {
  // 如果没有对应的语言文件, 置为中文
  curLang = 'zh-CN';
}

const I18N = kiwiIntl.init(curLang, langs);

export default I18N as typeof I18N & NEWI18NAPI;