import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';

import { type UserInfo } from '@/api';
import { TStore, withStore } from '@/component';
import routes from '@/router';
import { guardRoot as GuardRoot } from '@/router/guard';
import { getUserInfoFromStorage, onTokenExpired } from '@/util/authority';
import { USER_IMG_EXTRANET } from '@/util/dev-tool';
import history from '@/util/history';
import Logger from '@/util/logger';

import { getDefaultSkeleton } from './component/m-common/m-spin/skeleton';

const logger = Logger.create('[App]');

declare global {
  interface Window {
    __AISEE_INITAL_STATES__: {
      nickname?: string;
    };
  }
}

const Setup = withStore(({ store }: { store: TStore }) => {
  // The login redirect Uri
  const { hash, href } = location;
  store.setHashInfo({ hash, href });
  useEffect(() => {
    let userInfo: UserInfo | null = null;
    // Analyze user infromation from store and storage
    if (store?.userInfo) {
      logger.info({ storeUserInfo: store.userInfo });
      userInfo = store.userInfo;
    } else {
      const userInfoData = getUserInfoFromStorage();
      if (userInfoData) {
        try {
          userInfo = JSON.parse(userInfoData);
          logger.info({ storageUserInfo: userInfo });
        } catch (error) {
          logger.error(`Parse user info from storage error: ${error}`);
        }
      }
    }
    // Can not analyze user information
    if (!userInfo) {
      logger.info('Can not found user info from store and storage');
      onTokenExpired();
    } else {
      if (userInfo?.user_info?.avatar) {
        userInfo.user_info.avatar = userInfo.user_info.avatar.replace(
          USER_IMG_EXTRANET,
          ''
        );
      }
      store.setUserInfo(userInfo);
      store.setUser(userInfo?.user_info?.nickname);
    }
    window.__AISEE_INITAL_STATES__.nickname = userInfo?.user_info?.nickname;
  }, []);
  return null;
});

function app() {
  return (
    <Router history={history}>
      <React.Suspense
        fallback={
          <Box sx={{ mt: '40px', ml: '40px', width: '40%' }}>
            {getDefaultSkeleton(4)}
          </Box>
        }
      >
        {renderRoutes(routes)}
      </React.Suspense>
      <Setup />
      <GuardRoot path={location.pathname} search={location.search} />
    </Router>
  );
}

export default app;
