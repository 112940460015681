/* eslint-disable operator-linebreak */
// user 插件相关
import I18N from '@/locales/I18N';
export const USRS_VAR = '_arrusers';
export const USERS_LINK = '//aisee.woa.com/user/users.js';

// 页面名称
export const PAGE_NAME = {
  fbs: 'feedback-list-page',
  mfcs: 'my-focus-page',
  fdetail: 'feedback-detail-page',
  issues: 'issue-list-page',
  idetail: 'issue-detail-page',
};

// 事件池
export const EVENT_POOL = {
  // ----- 列表页操作事件 ----- //
  clickSearch: 'click-search',
  fbRefresh: 'refresh-feedbacks-event',
  tableReset: 'reset-table-select-event',
  transfer: 'transfer-order-event',
  // ----- 转 TAPD 操作事件 ----- //
  popUpTapdModal: 'pop-up-tapd-modal-event',
  // ----- 智能分类操作事件 ----- //
  clearClass: 'clear-select-class-event',
  clearCheckBox: 'clear-check-box-event',
  changeCheckValue: 'change-check-value-event',
  searchClass: 'search-class-changed-event',
  dealClassTitle: 'deal-class-title-event',
  dealClass: 'deal-feedback-class-event',
  // ----- 详情页操作事件 ----- //
  fbHistories: 'event-detail-item-getHistories',
  refreshFbDetail: 'refresh-feedback-detail-event',
  // ----- 问题列表操作事件 ----- //
  refreshIssueRelates: 'issue-detail-item-getTable',
  refreshIssueDetail: 'issue-detail-item-getIssue',
};

// 事件名称
export const EVENT_NAME = {
  tapd: 'TAPD',
  oncall: 'ONCALL',
};

// 配置项
export const USER_SETTING = {
  issueFilterFields: 'aiseeIssueFilterFields',
  overviewFilterFields: 'aiseeOverviewFilterFields',
  userFilterFields: 'aiseeUserFilterFields',
  userColumns: (projectId) => `aiseeUserColumns_${projectId}`,
  userIssueColumns: (projectId) => `aiseeUserIssueColumns_${projectId}`,
  userColumnsMult: (projectId) => `aiseeUserColumnsMult_${projectId}`,
  userExportFields: (appId) => `aiseeUserExportFields_${appId}`,
  userExportFieldsMult: (appId) => `aiseeUserExportFieldsMult_${appId}`,
  userUvFields: (projectId) => `aiseeUserUvFields_${projectId}`,
  userIssueFields: (projectId) => `aiseeUserIssueFields_${projectId}`,
  userExtendTab: 'aiseeUserExtendTab',
};

// 帮助框的数据
export const HELP_DATA = [
  {
    key: '1',
    type: I18N.operate.and,
    name: I18N.constant.keywordSpace,
    exp: 'hello world',
    msg: I18N.constant.querySimultaneousPackages,
  },
  {
    key: '2',
    name: I18N.constant.keywordKey2,
    exp: 'hello,world',
  },
  {
    key: '3',
    type: I18N.operate.or,
    name: I18N.constant.keywordKey,
    exp: 'hello|world',
    msg: I18N.constant.queryContainsH,
  },
];
export const HELP_COLUMN = [
  {
    title: I18N.constant.multipleKeywords,
    dataIndex: 'type',
    key: 'type',
    width: '20%',
    onCell: (_, index) => {
      // 第一行占两格
      if (index === 0) {
        return { rowSpan: 2 };
      }
      // 第二行不占格，实现单元格合并
      if (index === 1) {
        return { rowSpan: 0 };
      }
      return {};
    },
    align: 'center',
  },
  {
    title: I18N.constant.inputFormat,
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: '30%',
  },
  {
    title: I18N.constant.example,
    dataIndex: 'exp',
    key: 'exp',
    align: 'left',
    width: '20%',
  },
  {
    title: I18N.constant.explain,
    dataIndex: 'msg',
    key: 'msg',
    width: '30%',
    onCell: (_, index) => {
      if (index === 0) {
        return { rowSpan: 2 };
      }
      if (index === 1) {
        return { rowSpan: 0 };
      }
      return {};
    },
    align: 'left',
  },
];

// 支持上传的图片类型
export const IMAGE_TYPE = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif'];
export const MAX_IMAGE_SIZE = 1024 * 1024 * 8; // 最大可上传的图片大小
// 公告/图文/教程/攻略最大条数
export const MAX_CUSTOMS = 10;
// 存储公告/图文/教程/攻略的 attr 字符串最大长度
export const MAX_ATTR_LENGTH = 2000;

export const FAQ_TEMPLE =
  'https://common-75336.gzc.vod.tencent-cloud.com/aisee/faq_template.xlsx';

// 部署环境，区分123内网与云上
export const DEPLOY_ENV = import.meta.env.VITE_DEPLOY_ENV || '';

// 云上部署版本
export const DEPLOY_CLOUD = 'cloud';
