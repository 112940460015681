import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// 默认样式参考: https://mui.com/material-ui/customization/default-theme/
const theme = createTheme({
  palette: {
    // 预定义主要的颜色
    primary: {
      main: '#1C1957',
      light: '#585CEE',
      dark: '#7CA1D2',
      contrastText: '#EBEEFE',
    },
    text: {
      primary: '#343152',
      secondary: '#7A7986',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: red.A400,
    },
    divider: '#E9EBF3',
  },
});

export default theme;
