export default {
  "mathf": "{val1} days",
  "mathf2": "{val1} hours",
  "alarm": "{val1} minutes",
  "month": "Month",
  "pastTimes": "Past",
  "thisMonth": "This month",
  "thisWeek": "This week",
  "inThePastMonth": "Last 3 months",
  "inThePastMonth2": "Last month",
  "inRecentWeeks": "Last 2 weeks",
  "inRecentWeeks2": "Last week",
  "today": "Today",
  "yyyyYear": "YYYY-MM"
}