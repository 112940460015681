import React from 'react';
import { RouteConfig } from 'react-router-config';

import routesConfig from './pages';

const Home = React.lazy(() => import('@/page/home'));

const Layout = React.lazy(() => import('@/page/layout/index'));

const OaLogin = React.lazy(() => import('@/page/oa-login'));

const Login = React.lazy(() => import('@/page/login'));

const WithoutAuth = React.lazy(() => import('@/page/withoutAuth'));

const Registered = React.lazy(() => import('@/page/registered'));

const ROUTES: RouteConfig[] = [
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/oaLogin',
    exact: true,
    component: OaLogin,
  },
  {
    path: '/withoutAuth',
    exact: true,
    component: WithoutAuth,
  },
  {
    path: '/withoutAuth/:lv3product',
    exact: true,
    component: WithoutAuth,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/registered',
    exact: true,
    component: Registered,
  },
  {
    path: ['/', '/admin'],
    component: Layout,
    routes: routesConfig,
  },
];

export default ROUTES;
