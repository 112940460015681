import React from 'react';
import { RouteConfig } from 'react-router-config';

import I18N from '@/locales/I18N';
import { PageRouteType, routePostProc } from '@/util/route';
/**
 * ExperienceEntry: 嵌入 DCL体验工程的入口
 */
const ExperienceEntry = React.lazy(() => import('@/page/app/experience'));

/**
 * FeedbackLayout: 反馈看板
 */
const FeedbackLayout = React.lazy(() => import('@/page/app/experience/layout'));

enum ExperienceRouterEnum {
  /** 内部体验 */
  AlphaTasklist = 'alpha-tasklist',
  /** 众测任务 */
  BetaTasklist = 'beta-tasklist',
  /** 问题汇总 */
  SummaryFeedback = 'summaryFeedback',
  /** 众测用例管理 */
  ModelManagement = 'modelManagement',
  /** 兑换处理 */
  ExchangeProcessing = 'exchangeProcessing',
}

const ExperienceRouterNameRecord: Record<ExperienceRouterEnum, any> = {
  [ExperienceRouterEnum.AlphaTasklist]: I18N.navbar.menu.alphaTasklist,
  [ExperienceRouterEnum.BetaTasklist]: I18N.navbar.menu.betaTasklist,
  [ExperienceRouterEnum.SummaryFeedback]: I18N.navbar.menu.summaryFeedback,
  [ExperienceRouterEnum.ModelManagement]: I18N.navbar.menu.modelManagement,
  [ExperienceRouterEnum.ExchangeProcessing]:
    I18N.navbar.menu.exchangeProcessing,
};

const addPrefix = (path: ExperienceRouterEnum) => `/${path}`;

const EXPERIENCE_ROUTE: RouteConfig[] = [
  ...Object.values(ExperienceRouterEnum).map((value) => ({
    path: addPrefix(value),
    render: () => <ExperienceEntry router={value} />,
  })),
  {
    path: '/feedbackLayout',
    component: FeedbackLayout,
  },
];

export { EXPERIENCE_ROUTE, ExperienceRouterEnum, ExperienceRouterNameRecord };
export default routePostProc(PageRouteType.EXPERIMENT, EXPERIENCE_ROUTE);
