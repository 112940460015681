import { observer } from 'mobx-react-lite';
import React from 'react';

import store, { TStore } from '@/store';

/**
 * 利用 observer 封装 withStore 把子组件封装成响应式的并把 store 传递给子组件
 * 如 store 的值发生变化,组件中使用到的 store 值均会自动刷新刷新
 * 示例: const home = withStore(({ store }: { store: TStore }) => { 组件的内容 });
 * @param Component React 实现的页面组件
 * @param stores 其他数据,默认值为一个空对象
 * @returns Wrapper 响应式的子组件
 */
export function withStore(Component: any, stores = {}) {
  const obserVable = { Observer: observer(Component) };

  const Wrapper = (props: any) => (
    <obserVable.Observer {...props} store={store} {...stores} />
  );

  return Wrapper;
}
export type { TStore };
