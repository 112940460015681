import rawLS from './raw-ls';

const LS = {
  set(key: string, value: any, expire?: number): void {
    const time = expire ? Date.now() + expire * 1000 : 0;
    const raw = JSON.stringify({
      value,
      time,
    });
    return rawLS.set(key, raw);
  },
  get(key: string): any {
    const raw = rawLS.get(key);

    if (!raw) {
      return null;
    }
    let result;
    try {
      const { value, time } = JSON.parse(raw);
      if (!time || time > Date.now()) {
        result = value;
      } else {
        LS.remove(key);
        result = null;
      }
    } catch (e) {
      result = null;
    }
    return result;
  },
  remove(key: string): void {
    return rawLS.remove(key);
  },
};

export default LS;
