import { cloneDeep } from 'lodash';
import { RouteConfig } from 'react-router-config';

import STORE from '@/store';

const ADMIN_ROUTE_PREFIX: string = '/admin/:lv1product/:lv2product/:lv3product';
const APP_ROUTE_PREFIX: string = '/:app';
type RecordPageRouteHandler = (c: RouteConfig) => RouteConfig;

enum PageRouteType {
  ROOT = 'root',
  OPERATE = 'operate',
  SATISFACTION = 'satisfaction',
  STATISTICS = 'statistics',
  CONFIG = 'config',
  SYSTEM = 'system',
  FAQ_MANAGEMENT = 'faq-layout',
  INSIGHT = 'insight',
  EXPERIMENT = 'experiment',
  EXPERIENCE = 'experience',
}

const addRoutePrefix = (path: string) =>
  `/admin/${STORE.productId}/${STORE.businessId}/${STORE.appId}/${path}`;

const routeHandlerRecord: Record<PageRouteType, RecordPageRouteHandler> = {
  [PageRouteType.ROOT]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.OPERATE]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.SATISFACTION]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.STATISTICS]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.CONFIG]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.SYSTEM]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.FAQ_MANAGEMENT]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.INSIGHT]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.EXPERIMENT]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
  [PageRouteType.EXPERIENCE]: (config) => {
    config.path = `${ADMIN_ROUTE_PREFIX}${APP_ROUTE_PREFIX}${config.path}`;
    return config;
  },
};

const routePostProc = (type: PageRouteType, routes: RouteConfig[]) => {
  const list = cloneDeep(routes);
  const routeHandler = routeHandlerRecord[type];
  if (routeHandler) {
    return list.map((route) => routeHandler(route));
  }
  return list;
};

export { addRoutePrefix, PageRouteType, routePostProc };
