export default {
  "menu": {
    "multiSourceFeedbackCheck": "Multi source feedback query",
    "productSettings": "Settings",
    "rolePermissions": "Permissions",
    "memberManagement": "Member",
    "systemSettings": "System",
    "questionnaireManagement": "Questionnaire",
    "feedbackSettings": "Settings",
    "feedbackInquiry": "Inquiry",
    "feedbackOperation": "Feedback",
    "personalCenter": "Personal Center",
    "feedbackStatistics": "Statistics",
    "problemFollowUp": "Problem",
    "userVoice": "User Voice",
    "dataInsight": "Insight",
    "overview": "Home",
    "autoMonitorSetting": "autoMonitor",
    "laboratory": "laboratory",
    "publicExploration": "publicExploration",
    "loganalysis": "loganalysis",
    "alphaTasklist": "alphaTasklist",
    "betaTasklist": "betaTasklist",
    "summaryFeedback": "summaryFeedback",
    "modelManagement": "modelManagement",
    "exchangeProcessing": "exchangeProcessing",
    "feedbackLayout": "feedbackLayout",
  }
}