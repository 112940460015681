export default {
  "creator": "Creator",
  "pleaseEnterADescription": "Please enter a description",
  "englishFields": "English Fields",
  "name": "Name",
  "sourceId": "Source ID",
  "sourceAttribute": "Source Attribute",
  "pleaseEnterRequiredField": "Please enter the required field!",
  "feedbackSource": "Feedback Source:",
  "importClassification": "Import Classification",
  "exportClassification": "Export Classification",
  "takeEffect": "Take effect.",
  "updateToTerminal": "Update to Terminal",
  "inTheManagementView": "After operating on classifications in the management view, remember to click",
  "updateToTerminal2": "Update to Terminal",
  "confirmUpdateTo": "Are you sure you want to update to Terminal?",
  "createANewFirstLevelScore": "Create New First-Level Classification",
  "copyData": "Copy Data",
  "newSource": "New Source",
  "sourceManagement": "Source Management",
  "userView": "User View",
  "managementView": "Management View",
  "exportFailed": "Export Failed:",
  "exportSuccessful": "Export Successful",
  "deleteCategory": "Delete Category",
  "doYouWantToDeleteThis": "Do you want to delete this category?",
  "optionalCustomization": "Optional Custom Fields",
  "feedbackFillingPage": "Feedback filling page, additional required custom fields after users select this category",
  "feedbackRequiredField": "Required Feedback Fields",
  "statusIsReturned": "Status is \"Replied\"",
  "doNotMakeAnyChanges": "Do not make any changes",
  "automaticReplyLetter": "Automatic Reply Status Change",
  "whenConfiguringSelf": "When configured for automatic replies, users will receive the automatic reply text for this category after submitting feedback.",
  "automaticReply": "Automatic Reply",
  "pleaseSelectFeedback": "Please select a feedback source",
  "pleaseInputProcessing": "Please enter the processor, separate multiple with ;",
  "inTermsOfFeedbackTypes": "Visible in feedback types",
  "invisible": "Invisible",
  "so": "Visible",
  "visibleToUsers": "Visible to Users",
  "pleaseEnterThePrompt": "Please enter the prompt (up to 250 characters)",
  "classificationId": "Classification ID",
  "suggestionsForUsers": "It is recommended to configure in the lowest visible category; users will see prompt content in the input box.",
  "classificationTips": "Classification Tips",
  "tips": "Tip:",
  "suggestionsForUsers2": "It is recommended to configure in the highest visible category; users will see description content under the category name when selecting.",
  "classificationDescription": "Classification Description",
  "describe": "Description:",
  "classificationAttribute": "Classification Attribute",
  "pleaseEnterCorrectly": "Please enter the correct processor, separate multiple with ;!",
  "groupLocation": "Group Location",
  "number": "Number",
  "thereIsCurrentlyNoAssociatedF": "No associated FAQ information available, click to associate FAQ",
  "clickToAdd": "Click to Add",
  "usedAfterConfiguration": "After configuration, corresponding FAQs will display on the feedback page once users select the category",
  "guessWhatYouWantToAsk": "Guess what you want to ask",
  "lenovoFaq": "Lenovo FAQ",
  "confirmToDelete": "Are you sure you want to delete this association?",
  "searchFaq": "Search FAQ",
  "youHaveAdded": "You have added",
  "addQuestionsTo": "Add questions to category:",
  "addFaq": "Add FAQ",
  "maximumNumberOfAssociations": "A maximum of 5 FAQs can be associated, please adjust and retry",
  "adjustSortingTo": "Sorting adjusted successfully, please click update to Terminal",
  "atDifferentLevels": "Cannot drag and change order between different levels",
  "iteml": "{val1} Subcategory",
  "pleaseFillInOnTheRightSide": "Please fill in the category details on the right side",
  "pleaseSelectWhatYouNeed": "Please select the feedback source to import into",
  "uploadCategoryE": "Upload Category Excel Data",
  "importFailedToSave": "Import failed, the following errors exist:",
  "theIThCategory": "Line {val1} has no parent category",
  "theIThLineExists": "Line {val1} contains \"Unclassified\", category name cannot include \"Unclassified\"",
  "unclassified": "Unclassified",
  "uploadFailedE": "Upload failed: {val1}",
  "faile": "{val1} data import failed, the following errors exist:",
  "count": "{val1} data imported successfully",
  "theUserViewIs": "User view is a preview of the directory tree seen by users, detailed view is not available",
  "thereAreCurrentlyNoClassificationLettersAvailable": "No classification information available, please click the classification tree to see details",
  "classificationManagement": "Classification Management",
  "helpAndFeedback": "Help and Feedback Optional Classification",
  "deleteSourceMissing": "Failed to delete source!",
  "deleteSource": "Source deleted successfully!",
  "deleteCategoryLost": "Failed to delete category!",
  "deleteClassificationAs": "Classification deleted successfully!",
  "updateToTerminal3": "Failed to update to Terminal!",
  "updateToTerminal4": "Successfully updated to Terminal!",
  "firstLevelClassificationName": "First-Level Classification Name",
  "relatedFaQs": "Failed to associate FAQs!",
  "relatedFaQs2": "FAQs associated successfully!",
  "newSourceLost": "Failed to create new source!",
  "busin": "{val1} source created successfully!",
  "level": "{val1} classification failed!",
  "level2": "{val1} classification successful, please click update to Terminal",
  "pleaseEnterANote": "Please enter a note",
  "remarks": "Remarks",
  "enableRules": "Enable Rules",
  "relationshipBetweenConditions": "Relationship Between Conditions",
  "addTo": "Add To",
  "lengthRestrictionGrid": "Length restriction format in JSON: {\"max\":xxx, \"min\": xxx}",
  "pleaseSelectFilter": "Please select filter conditions",
  "inMultipleProjects": "Effective in sources with the same name across multiple projects",
  "applicableAfterOpening": "Once enabled, applicable to all projects under the corresponding secondary product, rules will sync and display in the corresponding projects",
  "multiProjectApplication": "Multi-Project Application",
  "pleaseEnterInvalid": "Please enter an invalid rule name",
  "pleaseEnterTheRules": "Please enter the rule name",
  "ruleName": "Rule Name",
  "invalidRuleCreation": "Invalid rule created successfully",
  "invalidRulesAreMore": "Invalid rule updated successfully",
  "newInvalidRule": "New Invalid Rule",
  "newlyBuild": "New Build",
  "invalidRule": "Invalid Rule",
  "or": "Or",
  "and": "And",
  "totalTota": "Total {val1} items",
  "areYouSureYouWantToDelete": "Are you sure you want to delete this invalid rule? (Cannot be restored after deletion)",
  "operator": "Operator",
  "updateTime": "Update Time",
  "enable": "Enable",
  "yes": "Yes",
  "invalidRuleName": "Invalid Rule Name",
  "deleteInvalidRules": "Successfully deleted invalid rules",
  "statu": "{val1} invalid rules processed successfully",
  "onTheUserPage": "Prompt text when inputting values on the user page",
  "inputPromptText": "Input prompt text",
  "doWeNeedASchool": "Is verification needed?",
  "canBeReversedOnThePlatform": "Can be modified in the platform feedback list",
  "canItBeModified": "Is it modifiable?",
  "inUserSubmission": "Should this field be displayed on the user feedback submission page?",
  "canTheUser": "Is it visible to users?",
  "isItMandatory": "Is it mandatory?",
  "pleaseEnterSorting": "Please enter sorting order",
  "sortNumericalValues": "Sorting numerical values",
  "sort": "Sort",
  "theFieldValueIsNot": "Field value cannot be empty",
  "addOptions": "Add Options",
  "fieldValueOptions": "Field Value Options",
  "maximumNumberOfCharacters": "Maximum character limit",
  "pleaseSelectAField": "Please select field type",
  "fieldType": "Field Type",
  "lettersOrNumbers": "Letters or numbers format, for interface parameter transfer",
  "fieldId": "Field ID",
  "whenReportingFeedback": "Field name filled when reporting feedback",
  "userEndOrFlat": "Information displayed on user or platform page (uniqueness)",
  "fieldName": "Field Name",
  "feedbackPage": "Field content displayed in order on the feedback information page",
  "title": "{val1} Field",
  "pleaseCompleteTheForm": "Please complete the form",
  "customFields": "Custom fields modified successfully",
  "customFields2": "Custom fields added successfully",
  "pleaseAddFields": "Please add field value options",
  "currentNam": "Current {val1} already exists, please re-enter",
  "pleaseEnterNa": "Please enter {val1}",
  "defaultRemarks": "Default Remarks",
  "defaultName": "Default Name",
  "newlyAdded": "Newly Added",
  "link": "Link",
  "jsonWord": "JSON String",
  "name2": "Name",
  "check": "Checkbox",
  "singleChoice": "Single Choice",
  "floatingPointNumbers": "Floating Point Numbers",
  "integerNumber": "Integer Numbers",
  "text": "Text",
  "doYouWantToDelete": "Do you want to delete this field?",
  "deleteCustom": "Delete Custom Field",
  "pleaseEnterTheField": "Please enter field name",
  "newFieldsAdded": "New fields added",
  "customFields3": "Failed to delete custom fields",
  "customFields4": "Successfully deleted custom fields",
  "isItMandatory2": "Is it required?",
  "fieldRemarks": "Field Remarks",
  "maximumNumberOfCharacters2": "Maximum Characters",
  "totalTota2": "Total {val1} items",
  "confirmToDelete2": "Confirm deletion",
  "confirmCancellation": "Confirm cancellation?",
  "confirmRelease": "Confirm release?",
  "announcementLink": "Announcement Link",
  "announcementContent": "Announcement Content",
  "pleaseSelectAnnouncement": "Please select announcement effective time!",
  "entryIntoForceTime": "Effective Time",
  "pleaseEnterTheAnnouncement": "Please enter the announcement link!",
  "pleaseEnterTheAnnouncement2": "Please enter the announcement content!",
  "editAnnouncement": "Edit Announcement",
  "newAnnouncement": "Create New Announcement",
  "announcementServiceReleased": "Announcement service error: {val1}",
  "announcementDataHasBeenReleased": "Announcement data is too long, please delete unnecessary announcements first",
  "noNeedForRepair": "No announcements need modification!",
  "noNeedToDelete": "No announcements need deletion!",
  "announcementHasExceeded": "Announcement limit exceeded {val1}, cannot create new announcements!",
  "announcementServiceDifferences": "Announcement service error: {val1}",
  "announcementList": "Announcement List",
  "announcementServiceDifferences2": "Announcement service error!",
  "graphicTutorialAttack": "Graphic/Text/Tutorial",
  "announcementBoard": "Announcement Board",
  "announcementGraphicAndText": "Graphic Announcement",
  "createANewImageAndText": "Create New Graphic Announcement",
  "imageAndTextServicesAreAvailable": "Graphic service error: {val1}",
  "theGraphicAndTextualDataHasBeenProcessed": "Graphic data is too long, please delete unnecessary graphic data first",
  "noNeedForRepair2": "No graphic modifications needed!",
  "noNeedToDelete2": "No graphic deletions needed!",
  "theGraphicsAndTextHaveExceededTheLimit": "Graphics have exceeded limit {val1}, cannot create new graphics!",
  "differentGraphicAndTextualServices": "Graphic service error: {val1}",
  "differentGraphicAndTextualServices2": "Graphic service error!",
  "graphicAndTextualLinks": "Graphic Links",
  "imageAndTextTitle": "Graphic Title",
  "graphicCover": "Graphic Cover",
  "pleaseUploadPicturesAndText": "Please upload graphic cover!",
  "pleaseSelectTextAndImage": "Please select graphic sorting position!",
  "textAndImageSorting": "Graphic Sorting",
  "pleaseInputTextAndImages": "Please enter graphic links!",
  "pleaseInputTextAndImages2": "Please enter graphic title!",
  "uploadCover": "Upload Cover",
  "cannotExceedM": "Cannot exceed {val1}M.",
  "doesNotSupportFi": "Does not support {val1} type upload",
  "unableToObtainIt": "Unable to obtain uploaded image.",
  "editImagesAndText": "Edit Graphic/Text",
  "hotspotSorting": "Hotspot Sorting",
  "groupSorting": "Group Sorting",
  "grouping": "Grouping",
  "answer": "Answer",
  "newQuestion": "Create",
  "editQuestionS": "Edit Question ({val1})",
  "theProblemCannotBe": "Question cannot be empty or over 200 characters!",
  "defaultGrouping": "Default group",
  "nextPicture": "Next",
  "ifYouOnlyWantToStop": "Use 'Disable' to stop displaying to users.",
  "afterDeletionThereWillBeNo": "Deletion is permanent, please proceed carefully.",
  "deleteFaq": "Delete FAQ",
  "strin": "{val1} Hotspot",
  "showMore": "Show More",
  "hotspot": "Hotspot",
  "stopUsing": "Deactivate",
  "copyFailed": "Copy Failed",
  "copySuccessful": "Copy Successful",
  "addQuestions": "Add Questions",
  "quicklyAddYours": "Quickly add your first question!",
  "noProblemAtTheMoment": "No questions available at the moment",
  "page": "Page",
  "the": "The",
  "articleRecord": "Records",
  "common": "Total",
  "answer2": "Answer",
  "totalTota3": "Total {val1} Questions",
  "cancelSelection": "Cancel Selection",
  "term": "Item",
  "downloadTemplate": "Download template",
  "batchImport": "Batch Import",
  "uploadFailed": "Upload Failed",
  "theIThItemInTheDocument": "Error in line {val1} of the file, message: {val2}",
  "statu2": "{val1} failed!",
  "statu3": "{val1} succeeded!",
  "thisFaqHasBeenSent": "This FAQ is already associated with group \"{val1}\" - please unassociate it and try again!",
  "isHot": "{val1} hotspot failed!",
  "isHot2": "{val1} hotspot succeeded!",
  "tipsF": "{val1} FAQ question succeeded.",
  "tipsF2": "{val1} FAQ question failed - content duplicate.",
  "tipsF3": "{val1} FAQ question failed - interface request error.",
  "groupIcon": "Group Icon",
  "groupName": "Group Name",
  "newGroup": "Create New Group",
  "editGroup": "Edit Group",
  "defaultGrouping2": "FAQs in the default group will not appear to users unless set as hotspots or moved to other groups.",
  "deleteGroup": "Delete Group",
  "youCanAddressTheIssue": "You can set the question as a hotspot in the content management!",
  "noGroupingAvailableAtTheMoment": "No groups available at the moment",
  "deleteSuccessfully": "Deletion successful",
  "group": "{val1} group information succeeded!",
  "group2": "{val1} group information failed!",
  "aHotTopicIssue": "hot topic issues",
  "share": "Total",
  "cancelHotspotLoss": "Cancel hotspot failed!",
  "cancelHotspot": "Cancel hotspot succeeded!",
  "adjustHotspotF": "Adjusting hotspot FAQ order failed!",
  "adjustHotspotF2": "Adjusting hotspot FAQ order succeeded!",
  "cancelHotspot2": "Cancel hotspot",
  "returnToGroup": "Return to Group",
  "aQuestion": "question(s)",
  "importProblem": "Import Problem",
  "title2": "{val1} FAQ success",
  "title3": "{val1} FAQ failed, duplicate content",
  "title4": "{val1} FAQ failed, API request error",
  "removeTheProblem": "Problem removed successfully",
  "removeFromGroup": "Remove from Group",
  "moreOperationNotes": "More operation records",
  "operationHistory": "Operation History",
  "hotContentManagement": "Hot Content",
  "groupManagement": "Group Management",
  "problemContentManagement": "Problem Content",
  "faqManagement": "FAQ Management",
  "enabled": "Enabled",
  "modelInf": "Model: {val1}",
  "newModel": "Create New Model",
  "copyModel": "Copy Model",
  "viewModel": "View Model",
  "changeTime": "Change Time",
  "modelDescription": "Model Description",
  "current": "Current",
  "modelName": "Model Name",
  "totalTota4": "Total {val1} items",
  "modelReplicationLost": "Model replication failed: {val1}",
  "copyTheModelInto": "Model copied successfully",
  "modelCopyingInProgress": "Model copying in progress, please wait",
  "doYouWantToCopyIt": "Do you want to copy the selected model?",
  "exportModelLost": "Export model failed: {val1}",
  "modelDownloadLocation": "Model download location is empty",
  "modelDeletionLost": "Model deletion failed: {val1}",
  "modelDeletedAs": "Model deleted successfully",
  "doYouWantToDeleteIt": "Do you want to delete the selected model?",
  "startTheModel": "Failed to start the model: {val1}",
  "enableThisModel": "Failed to enable this model",
  "successfullyEnabledThis": "Successfully enabled this model",
  "areYouSureToStart": "Are you sure you want to enable the selected model?",
  "modificationFailedPlease": "Modification failed: Please confirm the modification information and retry!",
  "theParentNodeCannot": "Parent node cannot be moved to child node, current category has been reset",
  "sameNameExists": "A category with the same name exists, current category has been reset",
  "addIte": "Add {val1} subcategory",
  "addAFirstLevelScore": "Add First-Level Category",
  "addSel": "Add {val1} subcategory",
  "pleaseReferToTheTableBelow": "Please fill in the relevant information in the form below and save to complete category addition",
  "pleaseUploadTheFile": "Please upload the file to generate the model",
  "displayDisabled": "Display Disabled",
  "draggingSucceeded": "Drag and drop priority updated successfully",
  "hideDisabledPoints": "Hide disabled categories",
  "displayDisabledPoints": "Display disabled categories",
  "aisee": "Aisee Assistant",
  "needToActivateSelf": "Need to enable auto-reply, please contact",
  "whenConfiguringSelf2": "When auto-reply content is configured, users will receive the auto-reply text for this category after smart classification matches.",
  "automaticReplyConfiguration": "Automatic Reply Configuration",
  "existsUnderTheCategory": "Subcategories exist under this category, please confirm if you want to continue deleting?",
  "areYouSureToDelete": "Are you sure you want to delete?",
  "historicalData": "Historical Data:",
  "priority": "Priority:",
  "senior": "Senior",
  "batchAdd": "Batch Add",
  "addBlockingWords": "Add Blocking Words",
  "thereAreCurrentlyNoBlockedWordsAvailable": "No blocking words available",
  "blockWords": "Blocking Words:",
  "ifSelectedAfterConfiguration": "If the selected custom fields have values after configuration, it will hit the smart classification",
  "customFields5": "Custom Fields:",
  "addErrorCode": "Add Error Code",
  "errorCode": "Error Code",
  "thereAreCurrentlyNoErrorCodesAvailable": "No error codes available",
  "errorCode2": "Error Code:",
  "learnNewWords": "Learn New Words",
  "addNewWordFrequency": "Add New Word Frequency",
  "wordFrequency": "Word Frequency",
  "noWordFrequencyAvailableAtTheMoment": "No word frequency available at the moment",
  "wordFrequency2": "Word Frequency:",
  "addImageClose": "Add Image Keywords",
  "noPicturesAvailableAtTheMoment": "No image keywords available at the moment",
  "imageKeywords": "Image Keywords:",
  "addNewKeywords": "Add New Keywords",
  "noKeywordsAvailableAtTheMoment": "No keywords available at the moment",
  "keyWord": "Keyword:",
  "businessScenarios": "Business Scenarios",
  "afterConfigurationTheCommandWillBeActivated": "After configuration, when hitting this category, the feedback type will change to the selected value",
  "feedbackType": "Feedback Type:",
  "pleaseFillInTheTitle": "Please fill in the title",
  "title5": "Title:",
  "basicConfiguration": "Basic Configuration",
  "batchAddM": "Batch Add {val1}",
  "successfullyUpdatedWord": "Successfully updated word frequency",
  "updateWordFrequency": "Updating word frequency",
  "categoryDeletedAs": "Category deleted successfully",
  "categoryAddedAs": "Category added successfully",
  "updateFailedPlease": "Update failed: Please check if the title is duplicated!",
  "updateSuccessful": "Update successful",
  "imageKeywords2": "Image Keywords",
  "blockWords2": "Blocking Words",
  "reasoningProcess": "Reasoning Process:",
  "predictiveClassification": "Predictive Classification:",
  "pleaseEnterText": "Please enter text",
  "enterText": "Enter Text:",
  "saveModel": "Save Model",
  "uploadGeneratedModel": "Upload Generated Model",
  "unsupervisedDownload": "Download Unsupervised Template",
  "downloadWithSupervision": "Download Supervised Template",
  "pleaseEnterTheModel": "Please enter model description",
  "thereIsCurrentlyNoModelAvailable": "No model description available",
  "modelDescription2": "Model Description:",
  "pleaseEnterTheModel2": "Please enter model name",
  "modelName2": "Model Name:",
  "model": "Model",
  "intelligentClassification": "Intelligent Classification-",
  "uploadFormatIsIncorrect": "Upload format is incorrect, please upload xls or xlsx format",
  "noPredictionsAvailableAtTheMoment": "No prediction data available at the moment",
  "successfulPrediction": "Successful Prediction",
  "modelUpdatedTo": "Model updated successfully",
  "modelCreation": "Model created successfully",
  "pleaseFillInTheModel": "Please fill in the model name",
  "usedForIntelligentClassification": "Intelligent Classification (use / to separate, e.g., growth/cash withdrawal)",
  "pleaseEnterCo": "Please enter {val1}, separate multiple with commas or spaces",
  "noResultsAvailablePlease": "No results available, please enter feedback ID for detection",
  "detectionResult": "Detection Result",
  "reset": "Reset",
  "pleaseEnterTheReverseFirst": "Please enter feedback ID first",
  "feedbackId": "Feedback ID",
  "testing": "Testing",
  "checkFailed": "Check failed",
  "pleaseProvideFeedback": "Please enter feedback ID",
  "addPlugin": "Add Plugin",
  "noteListOnTheLeft": "Note: The drag button on the left can adjust the trigger order",
  "historicalTriggerRecord": "Historical Trigger Records",
  "manuallyTriggeredPress": "Manually triggered sorted by enabled order on feedback detail page",
  "manuallyTriggered": "Manually Triggered",
  "automaticTriggerPress": "Automatically triggered executed in enabled order",
  "automaticTriggering": "Automatic Triggering",
  "processingPlugins": "Processing Plugins",
  "pluginManagement": "Plugin Management",
  "pleaseEnterAResponse": "Please enter response structure path",
  "responseStructurePath": "Response Structure Path",
  "requestHeader": "Request Header",
  "parameter": "Parameter",
  "interfaceTimeoutInSeconds": "Interface Timeout (seconds)",
  "pleaseEnterAp": "Please enter API",
  "pleaseSelectTrigger": "Please select trigger method",
  "triggerMethod": "Trigger Method",
  "pluginUsage": "Plugin Usage",
  "pleaseEnterThePlugin": "Please enter plugin name",
  "pluginName": "Plugin Name",
  "editPlugin": "Edit Plugin",
  "pleaseEnterVa": "Please enter value {val1}",
  "pleaseEnterKe": "Please enter key {val1}",
  "updatePluginMissing": "Failed to update plugin: ",
  "updatePluginsTo": "Plugin updated successfully",
  "newPluginLost": "Failed to create new plugin:",
  "createANewPlugin": "New plugin created successfully",
  "pleaseEnterCorrectly2": "Please enter the correct API link",
  "pleaseEnterThePlugin2": "Please enter the plugin name",
  "field": "Field",
  "fieldName2": "Field Name",
  "triggerOrderDrag": "Trigger order drag failed",
  "triggerOrderDrag2": "Trigger order drag succeeded",
  "startupSuccessful": "Startup successful",
  "isItEnabled": "Is it enabled?",
  "purpose": "Purpose",
  "triggerType": "Trigger Type:",
  "fail": "Fail",
  "success": "Success",
  "executionStatus": "Execution Status:",
  "pluginName2": "Plugin Name:",
  "feedbackId2": "Feedback ID:",
  "coCoun": "Total {val1} items",
  "triggerPerson": "Trigger Person",
  "triggerTime": "Trigger Time",
  "returnResult": "Return Result",
  "passInParameters": "Pass In Parameters",
  "executionStatus2": "Execution Status",
  "pluginName3": "Plugin Name",
  "pleaseEnterTheLabel": "Please enter label name or keyword",
  "automaticallyLabel": "Automatically Tag",
  "pleaseUseATemplate": "Please use a template for import",
  "newLabel": "Create New Label",
  "businessTags": "Business Tags",
  "focusOnFailure": "Focus on Failures",
  "followSuccess": "Focus on Success",
  "modificationFailed": "Modification Failed",
  "theIThItemInTheDocument2": "Error in line {val1} of the file, tip: {val2}",
  "tagListAcquisition": "Failed to acquire tag list: {val1}",
  "follow": "Follow",
  "cancelFollow": "Unfollow",
  "systemLabel": "System Label",
  "priority2": "Priority",
  "tagName": "Tag Name",
  "totalTota5": "Total {val1} items",
  "pleasePrioritize": "Please set priority (value must be greater than or equal to 0)",
  "pleaseEnterCorrectly3": "Please enter a correct numerical format",
  "multipleKeywords": "Multiple keywords separated by spaces",
  "pleaseEnterTheLabel2": "Please enter label name",
  "pleaseEnterCorrectly4": "Please enter label name correctly",
  "tagName2": "Tag Name",
  "deleteThisTag": "Deleting this tag will prevent corresponding feedback from being searched by this tag; deletion cannot be restored.",
  "confirmToDeleteTheLabel": "Are you sure you want to delete the label:",
  "intTa": "{val1} label",
  "deleteFailed": "Delete failed",
  "newCreationFailed": "Creation failed",
  "newCreatedSuccessfully": "Creation successful",
  "ifYouWishTo": "If you wish to publish important announcements on the user feedback submission page, you can directly set references in AiSee, which will help save some development costs.",
  "ifNeededForSome": "If specific tags are needed for some feedback data, AiSee supports tag setting and application.",
  "basedOnTheAlreadyGenerated": "Based on the generated user feedback, when you need to link to the business system for more comprehensive information, it can be achieved through plugins.",
  "ifNecessary": "If you need to filter out certain invalid feedback data, you can set rules according to your needs, including but not limited to specific field values, regular expressions, etc.",
  "theUserIsSubmitting": "When users submit feedback and wish to select a question category, AiSee can help you preset the corresponding classifications.",
  "inUserFeedback": "In addition to the system information reported in user feedback, if you need to know more content or specific business information, please set your custom fields in the custom information.",
  "youCanTarget": "You can set FAQs for common issues to automatically reply to users under the corresponding categories, or support the operation team to choose suitable content to reply manually.",
  "youCanBaseItOn": "You can set your classification set based on the business, and when user feedback occurs, the platform will help you achieve automatic classification.",
  "replyStatus": "Reply Status",
  "clickToExpandEmptySpace": "Click to expand empty fields",
  "clickToCollapse": "Click to collapse",
  "operationHistoryRecord": "Operation History Record",
  "systemFields": "System Fields",
  "openAiDay": "Open AI",
  "clickToViewTheMost": "Click to view best practices",
  "byAnalyzingAndUsing": "Don't rush when encountering problems, DeepSeek is here to help you! Click on 'One click Analysis' to quickly locate the root cause of anomalies",
  "logAnalysis": "Log Analysis",
  "unbindTap": "Unbind Tapd",
  "copyLinkSplit": "Copy link to share",
  "screenshot": "Screenshot (",
  "feedbackInThePastYear": "Feedback in the past year",
  "to": "to",
  "feedbackContentT": "Feedback Content: {val1}\n{val2}",
  "releaseTap": "Successfully unbound TAPD!",
  "opera": "{val1} failed",
  "opera2": "{val1} successful",
  "logInformation": "Log Information",
  "issue": "issueId is empty",
  "noChangeHistoryAvailableAtTheMoment": "No change history available at the moment",
  "remarks2": "Remarks:",
  "informationEditing": "Info Editing",
  "processingRecords": "Processing Logs",
  "city": "City",
  "country": "Country",
  "network": "Network",
  "userIp": "User IP",
  "isItRoo": "Is it Root?",
  "systemVersion": "System Version",
  "model2": "Model",
  "manufacturer": "Manufacturer",
  "equipmentAndRegion": "Equipment and Region",
  "userTags": "User Tags",
  "clickToGoToEach": "Click to send a notification to each handler via WeChat",
  "clickToSend": "Click to send notification",
  "businessInformation": "Business Information",
  "sourceLink": "Source Link",
  "receivingTime": "Receiving Time",
  "chooseManualTouch": "Select a manually triggered plugin to execute; field update results will be recorded in change history",
  "executePlugin": "Execute Plugin",
  "essentialInformation": "Essential Information",
  "notifyRec": "Notification: {val1} encountered an error, please retry",
  "notifyRe": "Notified: {val1}",
  "pleaseAddProcessing": "Please add a handler before sending notification",
  "enterCategoryName": "Enter category name to search",
  "searchFeedbackWithin": "Search feedback content",
  "itemt": "{val1} (No category)",
  "content": "Content",
  "whenThePlatformReceives": "Platform receiving time",
  "pleaseChooseFi": "Please select {val1}",
  "pleaseEnterFi": "Please enter {val1}",
  "tapdFinished": "TAPD finished",
  "transferToTapd": "Transfer to TAPD",
  "oncal": "Oncall completion",
  "feedbackProcessingRecord": "Feedback Processing Record (",
  "contactInformation": "Contact Information",
  "feedbackClassification": "Intel Classification:",
  "noPermissionToRepairTemporarily": "No permission to modify classification temporarily",
  "transferredToPar": "Transferred to {val1}",
  "feedbackProcessingStatus": "Feedback Processing Status",
  "whenSubmittingFeedback": "Feedback submission time",
  "clickToViewForUse": "Click to view user [{val1}] feedback history for the past year",
  "modifyIntelligentScore": "Intelligent classification modified successfully!",
  "opera3": "{val1} failed",
  "opera4": "{val1} succeeded",
  "exportAllNumbers": "Export all data",
  "feedbackInput": "Feedback Entry",
  "moreOperations": "More Operations",
  "batchEditing": "Batch Edit",
  "batchReply": "Batch Reply",
  "batchAiPush": "Batch AI Recommended Reply",
  "batchExc": "Batch Excel Import",
  "singleFeedbackRecord": "Single Feedback Entry",
  "batchCancelClose": "Batch Unfollow",
  "batchFollow": "Batch Follow",
  "batchDownload": "Batch Download",
  "opera5": "{val1} failed",
  "opera6": "{val1} succeeded",
  "clickOnDescendingOrder": "Click for descending order",
  "cancelSorting": "Cancel Sorting",
  "clickOnAscendingOrder": "Click for ascending order",
  "pleaseSelectAttributes": "Please select attribute tags",
  "attributeTags": "Attribute Tags",
  "ifThereIsNoneAfterInput": "If no corresponding tag exists after input, press enter to add",
  "pleaseSelectFeedback2": "Please select feedback type",
  "pleaseSelectAUser": "Please select user classification",
  "informationHasBeenEntered": "Entered information replaces current feedback; unentered information remains unchanged.",
  "additionFailed": "Addition failed",
  "newSuccessfullyAdded": "Successfully added",
  "customFields6": "Custom Field Editing",
  "systemFieldCoding": "System Field Editing",
  "pleaseSelectBusiness": "Please select business scenario",
  "afterSubmittingFeedback": "After feedback submission, automatically push 1 WeChat notification to all handlers",
  "searchForMembers": "Search for member nicknames and select",
  "upToFiveUploadsAllowed": "Up to five images can be uploaded, maximum size 10MB",
  "feedbackAttachment": "Feedback Attachment",
  "userFeedback": "Original link of user feedback; can click to jump in the feedback list after adding",
  "pleaseEnterTheLink": "Please enter the link",
  "whenReceivingUserFeedback": "User feedback time",
  "pleaseSelectFeedback3": "Please select feedback type!",
  "pleaseProvideFeedback2": "Please enter feedback source!",
  "pleaseEnterTheUser": "Please enter user ID or account",
  "theInputContentIsNot": "Input content cannot exceed 200 characters",
  "pleaseEnterTheUser2": "Please enter user information (user ID or account)!",
  "pleaseEnterTheUser3": "Please enter user feedback content",
  "pleaseEnterTheUser4": "Please enter user feedback content!",
  "reasonForFailure": "Reason for failure:",
  "singleMaximumLead": "Maximum 2,000 entries per import",
  "clickToUploadE": "Click to upload Excel file",
  "clickToDelete": "Click to delete",
  "pleaseProvideFeedback3": "Please enter feedback data!",
  "downloadImportModel": "Download Import Template",
  "https": "https://mf-dcl-1258344701.cos.ap-guangzhou.myqcloud.com/Feedback_Batch_Import_Template-AiSee-v2.xlsx",
  "uploadFeedbackCount": "Upload feedback data",
  "userFeedbackRecord": "User Feedback Entry",
  "usedForBatchImport": "Batch import user feedback",
  "singleMaximumLead2": "Maximum 2,000 entries per import",
  "feedbackOnLineI": "Line {val1}, feedback type is empty",
  "feedbackOnLineI2": "Line {val1}, feedback user is empty",
  "feedbackOnLineI3": "Line {val1}, feedback content is empty",
  "tableDataError": "Table data error, please upload correct data",
  "inputFailedE": "Entry failed: {val1}",
  "inputSuccessful": "Entry successful",
  "importFailedE": "Import failed: {val1}",
  "importSuccessful": "Import successful",
  "pleaseUploadTheDocumentFirst": "Please upload the document first",
  "timeSpanIsNot": "Time span cannot exceed one year, please reselect!",
  "intelligentProcessing": "Intelligent Processing",
  "userInformation": "User Information",
  "pleaseSelectATime": "Please select a time",
  "search": "Search",
  "itemt2": "{val1} (No category)",
  "optionalRangeOnly": "Optional range limited to applications under the current secondary product",
  "belongingToLevel3": "Belonging to level 3 application",
  "pleaseChooseFi2": "Please select {val1}",
  "pleaseEnterFi2": "Please enter {val1}",
  "transferredToPar2": "Transferred to {val1}",
  "currentProduct": "Current Product",
  "batchModificationReverse": "Batch Modify Feedback Type",
  "allApplications": "All Applications",
  "personalCenter": "Personal Center",
  "intMine": "{val1}",
  "intMine2": "{val1}",
  "pleaseProvideFeedback4": "Please enter feedback content",
  "viewMore": "View More",
  "theLatestFourQuestions": "Latest four questions",
  "waitingForMyApprovalAuthority": "Waiting for my approval authority",
  "recentlyFollowed": "Recently followed feedback in the last 7 days",
  "inRecentYearsItIsExpectedToHappenAgain": "Pending reply in the last year",
  "recentlyReplied": "Replied in the last 7 days",
  "followUpInRecentDays": "Issues followed up in the last 30 days",
  "processedInRecentDays": "Issues processed in the last 7 days"
}