export default {
  mathf: '{val1}天',
  mathf2: '{val1}小时',
  alarm: '{val1}分钟',
  month: '月',
  pastTimes: '过去',
  thisMonth: '本月',
  thisWeek: '本周',
  inThePastMonth: '近3个月',
  inThePastMonth2: '近1个月',
  inRecentWeeks: '近2周',
  inRecentWeeks2: '近1周',
  today: '今天',
  yyyyYear: 'YYYY年MM月',
};
