import I18N from '@/locales/I18N';
import { isTestEnv } from '@/util/dev-tool';

const MICROAPP_CONTAINER_ID = 'dcl-micro-app';
const LS_LAST_PRODUCT_KEY = 'aisee-last-product';

// import-map-overrides 工具包地址
const IMPORT_MAP_OVERRIDES =
  'https://mfdclcommon-75970.gzc.vod.tencent-cloud.com/import-map-overrides-cn-by-extronwang.js';
const IWIKI_LINK =
  'https://iwiki.woa.com/pages/viewpage.action?pageId=754659426';
const AISEE_ORIGIN = 'http://aisee.oa.com';
const TESTONE = 'http://testone.woa.com/product-manager#/product/my/base';

const GUIDANCE_DOC = {
  link: 'https://iwiki.woa.com/p/4012163356',
  text: I18N.constant.guidanceDocument,
};

// 小助手
const WX_HELPER = {
  text: I18N.constant.contactTheAssistant,
  // 服务号id需要联系 “内部客服助手” 获取
  link: 'wxwork://message?uin=8444251120322059',
};

const LOG_GUIDE = {
  link: 'https://iwiki.woa.com/p/4013119866',
  text: I18N.constant.logAnalysisIsTheMost,
};

const PRODUCT_TEXT = {
  text: I18N.constant.productRegistration,
};

const USER_SELECT_PRODUCT = 'userSelectProduct';

// 注册的反馈产品 Id 和 publicKey
const FPRODUCT_ID = {
  formal: '086aa5c72f',
  test: '97ccaf7c69',
};

const PUBLIC_KEY = {
  formal:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAh4ym67hwp0lrobkU7tRPmPZPiKuAQIj9Huiro9BtNxGX9YOBbqbrcNrlFF90eeXgBxXy9SS5aIOp8eRUDRf5YLWQyzkHqOrXn1H1FlpIrfsFZysbIvNATCMJXFyVCJJ20wTtdldGImhK0q7NQQQ7HsJ7GscrcCcEkEhPFiCIWIDfR/P1rdtNjHG1ZkFaU8Ad8t45q+7OZw1XkJD9EjeMMWsmxcD+995EYn7cUu0KyYg6U52S3WbxLC3hicn3dxUVlYrkrGEb1ii44UJV0Udz10Xp9Rhp/MAUSWjDa+J6qrTTJT5j77uRJ0KKQU3hak+ckLQF5OXiw6crwzS2/kXh8wIDAQAB',
  test: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn5oddkJ5MfxhNGAkrL0O/tj02hdSYtf9dBdYWmQQ6Kn6xi7Hem4piYf9hdVL2CzJ+P+s0wthmLlHJ3NmnmbQpDVohz6Ioz1idhITI3py1Vx8QRCzG8JrBJyDCjNPX+uQ0iFQXFL+479XQx+/I+VDLxFzPMIRDmNRxcDLgcXc0Ewk04AP1+a4baC/876+TWrD5/RgRaU1a7BKJgksc6nMs/0bPuJcaQkvPegmakoAy/rWFhyn/IfFW4PXOvZlbV2wVVtlVElLOG2r9I1kpXIGdEhw0F1qbdDvMwztX1ZvtN67xRnHGhfNTbGm5nb5qihlIYfs2szjlX8WciVa+JYqHwIDAQAB',
};

const FEEDBACK_ISSUE_URL = {
  formal: 'https://h5.dcl.qq.com',
  test: 'https://h5dcl.testsite.woa.com',
};

const getReportIssueInfo = () => {
  if (isTestEnv()) {
    return {
      url: FEEDBACK_ISSUE_URL.test,
      pKey: PUBLIC_KEY.test,
      appId: FPRODUCT_ID.test,
    };
  }
  return {
    url: FEEDBACK_ISSUE_URL.formal,
    pKey: PUBLIC_KEY.formal,
    appId: FPRODUCT_ID.formal,
  };
};

export {
  AISEE_ORIGIN as aiseeOrigin,
  getReportIssueInfo,
  GUIDANCE_DOC as guidanceDoc,
  IMPORT_MAP_OVERRIDES,
  IWIKI_LINK,
  LOG_GUIDE as logguide,
  LS_LAST_PRODUCT_KEY,
  MICROAPP_CONTAINER_ID,
  PRODUCT_TEXT as ptext,
  TESTONE as testOne,
  USER_SELECT_PRODUCT as usproduct,
  WX_HELPER as wxhelper,
};
