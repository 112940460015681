/**
 * dcl 平台使用的到域名配置
 * 内网指：oa.com 或 woa.com 域
 * 外网指：qq.com 域
 */

// 平台: 内网域名
export const DCL_INTRANET = {
  development: 'http://test.aisee.woa.com',
  production: 'http://aisee.woa.com',
  productionHttps: 'https://aisee.woa.com',
};

// 平台: 外网域名
export const DCL_EXTRANET = {
  development: 'http://dclouter.testsite.woa.com',
  production: 'https://aisee.qq.com',
};
