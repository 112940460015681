export default {
  rejected: '已驳回',
  alreadyPassed: '已通过',
  pendingReview: '待审核',
  operationsPersonnel: '运营人员',
  developers: '开发人员',
  administrators: '管理员',
  pleaseChooseToApply: '请选择申请角色',
  applyForARole: '申请角色',
  pleaseChooseToReview: '请选择审核状态',
  auditStatus: '审核状态',
  pleaseEnterTheProduct: '请输入产品ID',
  productId: '产品ID',
  totalTota: '共 {val1} 条',
  confirmToRejectThe: '确定驳回该申请吗?',
  confirmApprovalForThis: '确定批准该申请吗?',
  reject: '驳回',
  approval: '批准',
  applicationRemarks: '申请备注',
  reviewer: '审核人',
  reviewTime: '审核时间',
  applicationTime: '申请时间',
  applicant: '申请人',
  applicationNumber: '申请编号',
  updateTheAuditReport: '更新审核状态失败! 错误原因: {val1}',
  updateTheAuditReport2: '更新审核状态成功!',
  secondaryProducts: '二级产品',
  firstLevelProduct: '一级产品',
  other: '其他',
  digitalAdministration: '数字行政',
  musicGroup: '音乐集团',
  sLine: 'S线',
  intelligentHardware: '智能硬件',
  carApplications: '车载应用',
  tvEnd: 'TV端',
  pcClient: 'PC客户端',
  miniProgram: '小程序',
  officialAccount: '公众号',
  actionAdventure: '动作冒险',
  utilities: '实用工具',
  lifestyleAndEntertainment: '生活娱乐',
  shoppingFashion: '购物时尚',
  beautifyPhotography: '拍摄美化',
  childParentChild: '儿童亲子',
  audiovisual: '影音视听',
  travelMap: '出行地图',
  readingAndLearning: '阅读学习',
  mobilePhoneBeautification: '手机美化',
  systemInput: '系统输入',
  chatCommunication: '聊天通讯',
  officeBusiness: '办公商务',
  recreationEntertainment: '休闲娱乐',
  puzzleGame: '益智游戏',
  networkGame: '网络游戏',
  sportsRacing: '体育竞速',
  businessStrategy: '经营策略',
  cosplay: '角色扮演',
  flyingShooting: '飞行射击',
  clickToOldVersion: '点击至旧版DCL平台更新设置',
  copyKey: '复制密钥',
  secretKey: '密钥',
  experienceTheApp: '体验APPID',
  feedbackPid: '反馈PID',
  applicationId: '应用ID',
  applicationForm: '应用形态',
  pleaseSelectAnApplication: '请选择应用形态',
  maximumNumberOfUploads: '最多上传1张图片, 尺寸120*120',
  applicationIcon: '应用图标',
  belongingBg: '所属BG',
  pleaseSelectYourAffiliation: '请选择所属BG',
  applicationDescription: '应用描述',
  applicationType: '应用类型',
  pleaseEnterTheApplication: '请输入应用类型',
  copyProductPublic: '复制产品公钥',
  applyPublicKey: '应用公钥用于反馈数据上报平台时身份校验',
  applyName: '应用名称',
  pleaseEnterTheApplication2: '请输入应用名称',
  registrationTime: '注册时间',
  saveFailed: '保存失败',
  savedSuccessfully: '保存成功',
  tapdConfiguration: 'tapd配置',
  forDetailsPleaseReferTo: '详情请参考',
  referenceDocuments: '参考文档',
  forDetailsPleaseReferTo2: '详情请参考:',
  oncal: 'oncall配置',
  experienceSettings: '体验设置',
  doYouWantToDeleteThis: '是否删除该oncall配置?',
  deleteConfiguration: '删除配置',
  webho: 'webhook链接',
  oncal2: 'Oncall运营业务名称',
  webho2: 'Webhook 地址',
  pleaseEnterWe: '请输入 webhook 地址',
  pleaseEnterOn: '请输入Oncall运营业务名称',
  oncal3: 'Oncall配置',
  infoLost: '{val1}失败: err:{val2}',
  infoBecomes: '{val1}成功',
  modify: '修改',
  doYouWantToDeleteThis2: '是否删除该tapd配置?',
  tapdEmpty: 'TAPD空间',
  tapdEmpty2: 'Tapd空间ID',
  pleaseEnterTa: '请输入Tapd空间ID',
  infoLost2: '{val1}失败: err:{val2}',
  infoBecomes2: '{val1}成功',
  doYouWantToDelete: '是否要删除：',
  deleteMember: '删除成员',
  totalTota2: '共 {val1} 项',
  inviteNewMembers: '邀请新成员',
  allMembers: '全部成员',
  role: '角色',
  updateNoteMissing: '更新备注失败',
  updateTheRemarksTo: '更新备注成功',
  updateNicknameLost: '更新昵称失败: {val1}',
  updateNicknameTo: '更新昵称成功',
  modifyMemberCorner: '修改成员角色类型: {val1} 失败',
  modifyMemberCorner2: '修改成员角色类型: {val1} 成功',
  deleteMemberD: '删除成员: {val1} 失败',
  deleteMemberD2: '删除成员: {val1} 成功',
  businessMembers: '业务成员',
  operatingMembers: '运营成员',
  developmentMembers: '开发成员',
  allCharacters: '全部角色',
  aMember: '个成员',
  addTogether: '共添加',
  searchForMembers: '搜索成员昵称，如果没有搜索到对应人员，按下回车键新增',
  memberNickname: '成员昵称',
  pleaseChooseAnInvitation: '请选择邀请的产品成员',
  addProductAs: '添加产品成员失败,请重新尝试',
  remarksInformation: '备注信息',
  invitationTime: '邀请时间',
  businessMembersCan:
    '业务成员”可访问除【成员管理】模块外的所有功能菜单，不可修改智能分类',
};
