import experience from './chidren-pages/experience';
import experiment from './chidren-pages/experiment';
import insight from './chidren-pages/insight';
import operate from './chidren-pages/operate';
import system from './chidren-pages/system';
import root from './root';

export default [
  ...root,
  ...operate,
  ...system,
  ...insight,
  ...experiment,
  ...experience,
];
