import 'virtual:svg-icons-register';
import 'react-photo-view/dist/react-photo-view.css';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { AliveScope } from 'react-activation';
import * as ReactDOM from 'react-dom/client';

import App from './App';
import theme from './theme';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3} />
    <CssBaseline />
    <AliveScope>
      <App />
    </AliveScope>
  </ThemeProvider>
);
